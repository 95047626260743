import Language from '../language';

export class Asset_Translation {
  assetId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.assetId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
