import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';
import { AuthSchema } from 'models/auth/authSchema';
import { apiGetAuthSchemasForFilter } from 'services/Api/authService';

export class AuthSchemaCache extends EntityCache<AuthSchema> implements ICache<AuthSchema, AuthSchemaCache> {
  private isLoading: boolean = false;

  private authschemasPromise: Promise<AuthSchema[]> | undefined;

  constructor() {
    super();
    this.getId = (schema) => schema.authSchemaId;
    this.getEmptyItem = () => AuthSchema.getEmptyAuthSchema();
  }

  async getItems(refresh: boolean = false): Promise<AuthSchema[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.authschemasPromise = apiGetAuthSchemasForFilter(undefined, accessToken);
          this.items = await this.authschemasPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.authschemasPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.authschemasPromise) {
      return await this.authschemasPromise;
    } else {
      return this.items;
    }
  }

  getItemsForMember(userId: string): AuthSchema[] {
    return this.items.filter((n) => n.hasLine(userId));
  }

  clone(): AuthSchemaCache {
    const authschemaCache: AuthSchemaCache = new AuthSchemaCache();
    authschemaCache.items = this.items?.map((g) => g.clone());

    return authschemaCache;
  }
}
