import Language from './language';

export default class RiskState_Translation {
  state: string;

  lang?: Language;

  constructor() {
    this.state = '';
  }

  clone = (): RiskState_Translation => {
    const output = new RiskState_Translation();
    output.state = this.state;
    output.lang = this.lang?.clone();

    return output;
  };
}
