import { StringValue_Translation } from './stringValue_Translation';

export enum StringValueTypes {
  Classification = 1,
  KPIChoice = 2
}

let newStringValueId = -1;

export default class StringValue {
  stringValueId: number;

  stringValueKey?: number;

  stringTypeId: number;

  stringTypeEntityId?: number;

  sortOrder?: number;

  trans: StringValue_Translation[];

  transIdx: number;

  value: string;

  description?: string;

  constructor() {
    this.stringValueId = newStringValueId;
    this.stringTypeId = 0;
    this.trans = [];
    this.value = '';
    this.transIdx = -1;
    newStringValueId--;
  }

  isEqual(test: StringValue): boolean {
    if (test.stringTypeId !== this.stringTypeId) return false;
    if (test.stringValueKey !== this.stringValueKey) return false;
    if (test.stringTypeEntityId !== this.stringTypeEntityId) return false;
    if (test.sortOrder !== this.sortOrder) return false;
    if (test.description !== this.description) return false;
    if (test.value !== this.value) return false;

    return true;
  }

  clone(): StringValue {
    const output = new StringValue();
    output.stringValueKey = this.stringValueKey;
    output.stringValueId = this.stringValueId;
    output.stringTypeId = this.stringTypeId;
    output.stringTypeEntityId = this.stringTypeEntityId
    output.sortOrder = this.sortOrder;
    output.value = this.value;
    output.description = this.description;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];

    return output;
  }
}
