import Mexp from 'math-expression-evaluator';

export const expression_eval = (exp: string,): number => {
  try {
    const mexp = new Mexp();
    const lexed = mexp.lex(exp, mexp.tokens);
    const postfixed = mexp.toPostfix(lexed);
    const result = mexp.postfixEval(postfixed, {});

    if (isNaN(result)) {
      return 0; //div by 0
    } else {
      return result;
    }
  } catch (err) {
    return NaN;
  }
};
