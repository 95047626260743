import { IAppContext } from 'App/AppContext';
import User from 'models/user';
import { Role } from './role';
import Group from 'models/group';
import { newGuid } from 'utils/guid';

export enum AuthSchemaLineOperation
{
    Update = 1,
    Delete = 2,
}

export class AuthSchemaLine {
  authSchemaId: number;

  authSchemaLineId: number;

  userId?: string;

  groupId?: string;

  roleId?: string;

  opUpdate: boolean;

  opDelete: boolean;

  id: string; //internal Id

  constructor() {
    this.authSchemaId = 0;
    this.authSchemaLineId = 0;
    this.opUpdate = false;
    this.opDelete = false;
    this.id = newGuid();
  }

  hasMember(): boolean {
    return this.userId !== undefined || this.roleId !== undefined || this.groupId !== undefined;
  }

  getMember(appContext: IAppContext): User | Role | Group | undefined {
    if (this.userId) return appContext.globalDataCache.users.get(this.userId);
    if (this.groupId) return appContext.globalDataCache.groups.get(this.groupId);
    if (this.roleId) return appContext.globalDataCache.roles.get(this.roleId);

    return undefined;
  }

  setMember(item: User | Role | Group | undefined) {
    if (item instanceof User) {
      this.userId = item.id;
      this.groupId = undefined;
      this.roleId = undefined;
    } else if (item instanceof Group) {
      this.groupId = item.id;
      this.userId = undefined;
      this.roleId = undefined;
    } else if (item instanceof Role) {
      this.roleId = item.roleId;
      this.userId = undefined;
      this.groupId = undefined;
    } else {
      this.userId = undefined;
      this.groupId = undefined;
      this.roleId = undefined;
    }
  }

  clone(): AuthSchemaLine {
    const output = new AuthSchemaLine();
    output.id = this.id;
    output.authSchemaId = this.authSchemaId;
    output.authSchemaLineId = this.authSchemaLineId;
    output.userId = this.userId;
    output.groupId = this.groupId;
    output.roleId = this.roleId;
    output.opUpdate = this.opUpdate;
    output.opDelete = this.opDelete;

    return output;
  }

  isEqual(item: AuthSchemaLine): boolean {
    if (item.authSchemaId !== this.authSchemaId) return false;
    if (item.authSchemaLineId !== this.authSchemaLineId) return false;
    if (item.userId !== this.userId) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.roleId !== this.roleId) return false;
    if (item.opUpdate !== this.opUpdate) return false;
    if (item.opDelete !== this.opDelete) return false;

    return true;
  }
}
