import { IAppContext } from 'App/AppContext';
import Theme_Translation from './theme_Translation';
import Task from './tasks/task';
import Joi from 'joi';
import { getLocalizedMessageOptions } from '../services/Localization/joiValidation';
import { isObjectEqual } from 'utils/object';
import { TFunction } from 'i18next';
import { PDCAState } from './pdca';
import Entity from './entity';
import ISOControl from './isoControl';
import { getPDCAStateText } from 'globalFunctions';
import { newGuidNil } from 'utils/guid';
import { IOwner } from './owner';

export default class Theme implements IOwner {
  tenantId: string;

  themeId: number;

  code: string;

  parentThemeId?: number;

  state: PDCAState;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  commentTrailId: number;

  auditTrailId: number;

  customNormIds?: number[];

  isoControlIds?: number[];

  isoNormIds?: number[];

  trans?: Theme_Translation[];

  tasks: Task[];

  monitoringParent?: Entity;

  tagIds: number[];

  isoControls?: ISOControl[];

  inheritTenantId?: string;

  inheritThemeId?: number;

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  constructor() {
    this.tenantId = newGuidNil();
    this.themeId = 0;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.tasks = [];
    this.tagIds = [];
  }

  static isChildOf = (controls: Theme[], parent: Theme, control: Theme | undefined): boolean => {
    let current: Theme | undefined = control;
    let max: number = 0;

    while (current !== undefined && current.parentThemeId !== undefined && max < 100) {
      if (current.parentThemeId === parent.themeId) {
        return true;
      }

      // eslint-disable-next-line no-loop-func
      current = controls.find((c) => c.themeId === current?.parentThemeId);
      max++;
    }

    return false;
  };

  static getControlStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  isEqual(item: Theme) {
    if (item.themeId !== this.themeId) return false;
    if (item.parentThemeId !== this.parentThemeId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.background !== this.background) return false;
    if (item.implementation !== this.implementation) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.isoNormIds, this.isoNormIds)) return false;
    if (!isObjectEqual(item.customNormIds, this.customNormIds)) return false;
    if (!isObjectEqual(item.isoControlIds, this.isoControlIds)) return false;
    if (!isObjectEqual(item.trans, this.trans)) return false;
    if (!isObjectEqual(item.tagIds, this.tagIds)) return false;
    if (item.tenantId !== this.tenantId) return false;

    return true;
  }

  clone(): Theme {
    const newItem = new Theme();

    newItem.themeId = this.themeId;
    newItem.parentThemeId = this.parentThemeId;
    newItem.name = this.name;
    newItem.description = this.description;
    newItem.background = this.background;
    newItem.implementation = this.implementation;
    newItem.code = this.code;
    newItem.state = this.state;
    newItem.groupId = this.groupId;
    newItem.ownerId = this.ownerId;
    newItem.commentTrailId = this.commentTrailId;
    newItem.auditTrailId = this.auditTrailId;
    newItem.transIdx = this.transIdx;
    newItem.trans = this.trans ? [...this.trans] : undefined;
    newItem.isoNormIds = this.isoNormIds;
    newItem.customNormIds = this.customNormIds;
    newItem.monitoringParent = this.monitoringParent?.clone();
    newItem.isoControls = this.isoControls ? [...this.isoControls] : undefined;
    newItem.tagIds = [...this.tagIds];
    newItem.inheritTenantId = this.inheritTenantId;
    newItem.inheritThemeId = this.inheritThemeId;
    newItem.tenantId = this.tenantId;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  getAutSchemaIds(appContext: IAppContext): (number | undefined)[] {
    const customNorms = appContext.globalDataCache.norms.getItemsForId(this.customNormIds ?? []);
    const isoNorms = appContext.globalDataCache.norms.getItemsForISONormId(this.isoNormIds ?? []);
    const ids = [...new Set([...customNorms, ...isoNorms].map((n) => n.authSchemaId))];

    return ids;
  }
}

export class ThemeTaskStats {
  themeId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.themeId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}
