import Language from './language';

export default class AuditTrailAction_Translation {
  auditTrailActionId: number;

  languageId: number;

  name: string;

  lang?: Language;

  constructor() {
    this.auditTrailActionId = 0;

    this.languageId = 0;

    this.name = '';
  }
}
