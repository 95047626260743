import Joi from 'joi';
import { TFunction } from 'i18next';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { areDifferent } from 'utils/array';
import Control from './control';
import GlobalDataCache from './globalDataCache/globalDataCache';
import Group from './group';
import ResourceLink from './resourceLink';
import Risk_Translation from './risk_Translation';
import User from './user';
import { IOwner } from './owner';

export enum RiskMethod {
  Mitigate = 0,
  Avoid = 1,
  Transfer = 2,
  Accept = 3,
}

export default class Risk implements IOwner {
  riskId: number;

  authSchemaId?: number;

  riskStateId: number;

  code: string;

  commentTrailId: number;

  auditTrailId: number;

  groupId?: string;

  group?: Group;

  ownerId?: string;

  ownerRoleId?: string;

  owner?: User;

  sortOrder: number;

  chanceStart: number;

  chanceCurrent: number;

  chanceGoal: number;

  impactStart: number;

  impactCurrent: number;

  impactGoal: number;

  analysisTaskid?: number;

  method: RiskMethod;

  latestChangeDateTime?: Date;

  tagIds?: number[];

  normIds?: number[];

  trans: Risk_Translation[];

  controls?: Control[];

  resourceLinks: ResourceLink[];

  classificationGroupIds: number[];

  assetIds: number[];
  
  // field below are copied from the trans array for the current user language

  transIdx: number;

  name: string;

  asset: string;

  description: string;

  analysis: string;

  strategy: string;

  constructor() {
    this.riskId = -1;
    this.riskStateId = -1;
    this.commentTrailId = -1;
    this.auditTrailId = -1;
    this.sortOrder = -1;
    this.chanceStart = 0;
    this.chanceCurrent = 0;
    this.chanceGoal = 0;
    this.impactStart = 0;
    this.impactCurrent = 0;
    this.impactGoal = 0;
    this.trans = [];
    this.name = '';
    this.asset = '';
    this.description = '';
    this.analysis = '';
    this.strategy = '';
    this.code = '';
    this.method = RiskMethod.Mitigate;
    this.transIdx = -1;
    this.resourceLinks = [];
    this.classificationGroupIds = [];
    this.assetIds = [];
  }

  static getRiskMethodText = (method: RiskMethod, t: TFunction<string[]>): string => {
    switch (method) {
      case RiskMethod.Avoid:
        return t('risk:Method.Avoid');
      case RiskMethod.Mitigate:
        return t('risk:Method.Mitigate');
      case RiskMethod.Transfer:
        return t('risk:Method.Transfer');
      case RiskMethod.Accept:
        return t('risk:Method.Accept');
    }
  };

  static getRiskMethodFromText = (method: string | undefined, t: TFunction<string[]>): RiskMethod => {
    if (!method) return RiskMethod.Mitigate;
    switch (method.toLowerCase()) {
      case t('risk:Method.Avoid').toLowerCase():
        return RiskMethod.Avoid;
      case t('risk:Method.Mitigate').toLowerCase():
        return RiskMethod.Mitigate;
      case t('risk:Method.Transfer').toLowerCase():
        return RiskMethod.Transfer;
      case t('risk:Method.Accept').toLowerCase():
        return RiskMethod.Accept;
      default:
        return RiskMethod.Mitigate;
    }
  };

  getStartScore(): number {
    return this.chanceStart * this.impactStart;
  }

  getCurrentScore(): number {
    return this.chanceCurrent * this.impactCurrent;
  }

  getGoalScore(): number {
    return this.chanceGoal * this.impactGoal;
  }

  clone(): Risk {
    const newItem = new Risk();
    newItem.riskId = this.riskId;
    newItem.riskStateId = this.riskStateId;
    newItem.code = this.code;
    newItem.commentTrailId = this.commentTrailId;
    newItem.auditTrailId = this.auditTrailId;
    newItem.groupId = this.groupId;
    newItem.ownerId = this.ownerId;
    newItem.sortOrder = this.sortOrder;
    newItem.chanceStart = this.chanceStart;
    newItem.chanceCurrent = this.chanceCurrent;
    newItem.chanceGoal = this.chanceGoal;
    newItem.impactStart = this.impactStart;
    newItem.impactCurrent = this.impactCurrent;
    newItem.impactGoal = this.impactGoal;
    newItem.analysisTaskid = this.analysisTaskid;
    newItem.trans = this.trans.map((trans) => trans.clone());
    newItem.transIdx = this.transIdx;
    newItem.name = this.name;
    newItem.asset = this.asset;
    newItem.description = this.description;
    newItem.group = this.group?.clone();
    newItem.analysis = this.analysis;
    newItem.method = this.method;
    newItem.strategy = this.strategy;
    newItem.controls = this.controls?.map((_control) => _control.clone());
    newItem.tagIds = this.tagIds ? [...this.tagIds] : undefined;
    newItem.classificationGroupIds = [...this.classificationGroupIds];
    newItem.assetIds = [...this.assetIds];
    newItem.resourceLinks = this.resourceLinks ? [...this.resourceLinks] : [];
    newItem.authSchemaId = this.authSchemaId;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  static getEmptyRisk(code: string, cache: GlobalDataCache): Risk {
    const output = new Risk();
    output.code = code;
    output.riskStateId = cache.riskStates.items[0].riskStateId;
    let trans = new Risk_Translation();
    output.trans.push(trans);
    output.transIdx = 0;

    return output;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  isEqual(item: Risk): boolean {
    let isEqual: boolean = true;
    if (item.riskId !== this.riskId) isEqual = false;
    if (item.riskStateId !== this.riskStateId) isEqual = false;
    if (item.code !== this.code) isEqual = false;
    if (item.commentTrailId !== this.commentTrailId) isEqual = false;
    if (item.auditTrailId !== this.auditTrailId) isEqual = false;
    if (item.groupId !== this.groupId) isEqual = false;
    if (item.ownerId !== this.ownerId) isEqual = false;
    if (item.sortOrder !== this.sortOrder) isEqual = false;
    if (item.chanceStart !== this.chanceStart) isEqual = false;
    if (item.chanceCurrent !== this.chanceCurrent) isEqual = false;
    if (item.chanceGoal !== this.chanceGoal) isEqual = false;
    if (item.impactStart !== this.impactStart) isEqual = false;
    if (item.impactCurrent !== this.impactCurrent) isEqual = false;
    if (item.impactGoal !== this.impactGoal) isEqual = false;
    if (item.analysisTaskid !== this.analysisTaskid) isEqual = false;
    if (item.transIdx !== this.transIdx) isEqual = false;
    if (item.name !== this.name) isEqual = false;
    if (item.asset !== this.asset) isEqual = false;
    if (item.description !== this.description) isEqual = false;
    if (item.analysis !== this.analysis) isEqual = false;
    if (item.strategy !== this.strategy) isEqual = false;
    if (item.method !== this.method) isEqual = false;
    if (item.authSchemaId !== this.authSchemaId) isEqual = false;
    if (item.ownerRoleId !== this.ownerRoleId) return false;

    if (
      areDifferent(item.tagIds, this.tagIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      isEqual = false;
    }

    if (
      areDifferent(item.classificationGroupIds, this.classificationGroupIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      isEqual = false;
    }

    if (
      areDifferent(item.assetIds, this.assetIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      isEqual = false;
    }

    if (
      areDifferent(item.controls, this.controls, (a: Control, b: Control) => {
        return a.controlId === b.controlId;
      }) === true
    ) {
      isEqual = false;
    }

    return isEqual;
  }
}
