export class GroupMember {
  groupId: string;

  members: string[];

  constructor() {
    this.groupId = '';
    this.members = [];
  }
}
