export default class ResourceListDTO {
  listId: number;

  authSchemaId?: number;
  
  listType: number;

  systemListId?: number;

  spSiteId?: string;

  spListId?: string;

  spDriveId?: string;

  spDriveItemId?: string;

  name: string;

  webURL?: string;

  altTenantId?: string;

  altTenantName?: string;

  columnProps?: string;

  enableMetaData?: boolean;

  isVirtual?: boolean;

  constructor() {
    this.listId = -1;
    this.listType = -1;
    this.spSiteId = '';
    this.spListId = '';
    this.spDriveId = '';
    this.spDriveItemId = '';
    this.name = '';
    this.webURL = '';
  }
}
