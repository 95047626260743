import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react';
import logService from './services/Logging/logService';
import App from './App/App';
import localizationService from './services/Localization/i18n';
import SceneLoader from 'components/Loading/SceneLoader';
import Config from 'services/Config/configService';
import './index.css';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

const addInVersion: string = '3.2.5';

localizationService.init();

initializeIcons();
initializeFileTypeIcons();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function itemChanged(eventArgs: any) {
  // Update UI based on the new current item
  logService.debug('item changed');
  UpdateTaskPaneUI(Office.context.mailbox.item);
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function UpdateTaskPaneUI(item: any) {
  logService.debug('full item', item);

  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<SceneLoader />}>
        <App item={item} />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

Office.initialize = () => {
  console.log(`Start task add-in version ${addInVersion} in environment: ${Config.getAppEnv()}`);

  // Set up ItemChanged event
  Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);

  // Trigger the main render
  UpdateTaskPaneUI(Office.context.mailbox.item);
};
