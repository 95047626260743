export class TaskLinkDTO {
  linkId: number;

  taskId: number;

  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    this.linkId = -1;
    this.taskId = -1;
    this.pinned = false;
  }
}
