import Group from 'models/group';
import { apiGetGroups } from 'services/Api/groupService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';
import { apiGetGroupMembership } from 'services/Api/userService';
import { GroupMember } from 'models/groupMembers';
import { UserCache } from './userCache';
import AppError from 'utils/appError';

export class GroupCache extends EntityCache<Group> implements ICache<Group, GroupCache> {
  private isLoading: boolean = false;

  private groupsPromise: Promise<Group[]> | undefined;

  private membersPromise: Promise<GroupMember[]> | undefined;

  private members: GroupMember[] | undefined;

  public userCache: UserCache | undefined;

  constructor() {
    super();
    this.getId = (group) => group.id;
    this.getEmptyItem = (id) => Group.getEmptyGroup(id as string);
  }

  getItemsForId(ids: string[] | undefined): Group[] {
    if (!ids) return [];

    return this.items.filter((i) => ids.includes(i.id));
  }

  getMembers(groupIds: string[]): string[] {
    const total: string[] = [];

    for (let idx=0; idx<groupIds.length; idx++) {
      const group = this.itemSet.get(groupIds[idx]);
      if (group?.users) total.push(...group.users.map(u => u.id));
    }

    return [...new Set(total)];
  }

  async getItems(refresh: boolean = false): Promise<Group[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.groupsPromise = apiGetGroups(accessToken, undefined);
          this.items = await this.groupsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.groupsPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.groupsPromise) {
      return await this.groupsPromise;
    } else {
      return this.items;
    }
  }

  getCurrentUserGroups(): Group[] {
    return this.items.filter((i) => i.isCurrentUserMember === true);
  }

  async getCurrentUserMembers(refresh: boolean = false): Promise<GroupMember[] | undefined> {
    if (!this.members || this.members.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.membersPromise = apiGetGroupMembership(accessToken, undefined);
          this.members = await this.membersPromise;

          this.members.sort((a, b) => {
            const a1 = this.get(a.groupId).name;
            const b1 = this.get(b.groupId).name;
    
            return a1.localeCompare(b1);
          });

          for (let idx = 0; idx < this.members.length; idx++) {
            const member = this.members[idx];
            const currentGroup = this.itemSet.get(member.groupId);
            if (currentGroup) {
              currentGroup.isCurrentUserMember = true;
              if (this.userCache && currentGroup.users) {
                const currentGroupMemberIds = currentGroup.users.map((g) => g.id);
                const newUniqueMemberIds = [...new Set([...currentGroupMemberIds, ...member.members])];
                currentGroup.users = this.userCache.getItemsForId(newUniqueMemberIds);
              }
            }
          }
        } catch (err) {
          if (err instanceof AppError) {
            const appErr = err as AppError;
            if (appErr.debug && appErr.debug.indexOf('Code: Authorization_IdentityNotFound') >= 0) {
              //ignore. user has no rights to get Entra Id members
            }
          }
          this.appContext.setError(err);
        } finally {
          this.groupsPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.members = [];
      }
    }

    if (this.isLoading && this.membersPromise) {
      return await this.membersPromise;
    } else {
      return this.members;
    }
  }

  clone(): GroupCache {
    const groupCache: GroupCache = new GroupCache();
    groupCache.items = this.items?.map((g) => g.clone());
    groupCache.userCache = this.userCache;

    return groupCache;
  }
}
