import { TeamsChannel } from 'models/teamsChannel';
import Group from 'models/group';
import http from './httpService';
import IdListDTO from 'models/dto/IdListDTO';
import { mapFromGroups, mapFromTeamChannels, mapToGroup } from 'mappings/dataMapping';
import AppError from 'utils/appError';
import GroupDTO from 'models/dto/groupDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';

export async function apiGetGroups(accessToken: string, cache: GlobalDataCache | undefined): Promise<Group[]> {
  try {
    const ar = await http.get<GroupDTO[]>(`/groups`, http.getRequestConfig(accessToken));

    return mapFromGroups(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetAdGroups(searchText: string | undefined, accessToken: string): Promise<Group[]> {
  try {
    if (!searchText) {
      searchText = '';
    }

    const ar = await http.get<GroupDTO[]>(`/groups/ad?search=${searchText}`, http.getRequestConfig(accessToken));

    return mapFromGroups(ar.data, undefined);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddGroup(accessToken: string, group: Group): Promise<Group> {
  try {
    const groupDTO = mapToGroup(group);
    const ar = await http.post<GroupDTO>('/groups', groupDTO, http.getRequestConfig(accessToken));

    return new Group(ar.data.groupId, ar.data.name, ar.data.email);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateGroup(accessToken: string, group: Group): Promise<Group> {
  try {
    const groupDTO = mapToGroup(group);
    const ar = await http.put<GroupDTO>('/groups', groupDTO, http.getRequestConfig(accessToken));

    return new Group(ar.data.groupId, ar.data.name, ar.data.email);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveGroup(accessToken: string, groupIds: string[]): Promise<void> {
  try {
    const idListDTO: IdListDTO = new IdListDTO(groupIds);
    await http.post<IdListDTO>('/groups/delete', idListDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetChannelsForGroup(groupId: string, accessToken: string): Promise<TeamsChannel[]> {
  try {
    const ar = await http.get<TeamsChannel[]>(`/groups/${groupId}/channels`, http.getRequestConfig(accessToken));

    //handle 204 NoContent for AD groups that have no Team
    if (!ar.data) {
      return [];
    }

    return mapFromTeamChannels(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGroupSync(accessToken: string): Promise<void> {
  try {
    await http.put(`/groups/sync`, undefined, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiTestGroupSync(accessToken: string): Promise<boolean> {
  try {
    const res = await http.get<boolean>(`/groups/sync/test`, http.getRequestConfig(accessToken));

    return res.data;
  } catch (err) {
    return false;
  }
}