import { appRoles } from 'services/Auth/appRoles';
import { Role_Translation } from './role_Translation';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import User from 'models/user';
import Group from 'models/group';
import { areDifferent } from 'utils/array';
import { newGuidNil } from 'utils/guid';

export class Role {
  roleId: string;

  systemRoleId?: appRoles;

  linkId?: number;

  trans: Role_Translation[];

  users: User[];

  groups: Group[];

  // The following properties are set from the translation arrays
  transIdx: number;

  name: string;

  description?: string;

  constructor() {
    this.roleId = newGuidNil();
    this.trans = [];
    this.transIdx = -1;
    this.name = '';
    this.users = [];
    this.groups = [];
  }

  static getEmptyRole(id?: string) {
    const newRole = new Role();
    if (id) newRole.roleId = id;

    return newRole;
  }

  clone(): Role {
    const output = new Role();
    output.roleId = this.roleId;
    output.linkId = this.linkId;
    output.systemRoleId = this.systemRoleId;
    output.name = this.name;
    output.description = this.description;
    output.users = this.users.map(a => a.clone());
    output.groups = this.groups.map(a => a.clone());
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];

    return output;
  }

  isEqual(item: Role): boolean {
    if (item.roleId !== this.roleId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.linkId !== this.linkId) return false;

    if (
      areDifferent(item.users, this.users, (a: User, b: User) => {
        return a.id === b.id;
      }) === true
    ) {
      return false;
    }

    if (
      areDifferent(item.groups, this.groups, (a: Group, b: Group) => {
        return a.id === b.id;
      }) === true
    ) {
      return false;
    }

    return true;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(64).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
      },
      { abortEarly: false },
    );
  }
}
