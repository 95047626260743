export default class Language {
  languageId: number;

  code: string;

  name: string;

  constructor() {
    this.languageId = 0;
    this.code = '';
    this.name = '';
  }

  clone(): Language {
    const output = new Language();
    output.languageId = this.languageId;
    output.code = this.code;
    output.name = this.name;

    return output;
  }
}

export class Languages {
  languages: Language[];

  constructor() {
    this.languages = [];
  }

  clone(): Languages {
    const newLanguages = new Languages();
    newLanguages.languages = this.languages.map((s) => s.clone());

    return newLanguages;
  }

  isEqual(langs?: Languages): boolean {
    const array1 = langs?.languages;
    const array2 = this.languages;

    if (array1 === undefined && array2 === undefined) {
      return false;
    }
    if (array1 === undefined || array2 === undefined) {
      return true;
    }
    if (array1.length !== array2.length) {
      return false;
    }

    for (let idx = 0; idx < array1.length; idx++) {
      const lang = array1[idx];
      if (!array2.some((a) => a.languageId === lang.languageId)) return false;
    }

    for (let idx = 0; idx < array2.length; idx++) {
      const lang = array2[idx];
      if (!array1.some((a) => a.languageId === lang.languageId)) return false;
    }

    return true;
  }
}
