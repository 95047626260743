import { toApiDateTimeRequired } from 'utils/datetime';
import { IAppContext } from 'App/AppContext';
import { DBProcesLoggerLogLevel, LogDTO } from 'models/dto/logDTO';
import AppError from 'utils/appError';
import { apiRequest } from 'services/Auth/authConfig';
import { apiAddProcessLog } from 'services/Api/userService';

export const globalLog: LogDTO = new LogDTO('Global client log');

export function getLogWithDateStamp(log: string | undefined): string | undefined {
  try {
    if (!log) return undefined;
    if (!log.startsWith('[')) {
      return `[${toApiDateTimeRequired(new Date())}] ${log}`;
    } else {
      return log;
    }
  } catch {
    return log;
  }
}

function info(msg: string | undefined): void {
  if (msg) globalLog.add(getLogWithDateStamp(msg));
  console.info(msg);
}

function error(err: AppError | undefined): void {
  if (err) {
    globalLog.add(getLogWithDateStamp(JSON.stringify(err)));
    globalLog.appError = err;
    globalLog.level = DBProcesLoggerLogLevel.Error;
  }
  console.error(err);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debug(msg: string | undefined, payload?: any): void {
  if (msg) globalLog.add(getLogWithDateStamp(msg));
  if (payload) globalLog.add(getLogWithDateStamp(JSON.stringify(payload)));
  console.debug(msg, payload);
}

async function upload(appContext: IAppContext): Promise<boolean> {
  try {
    if (globalLog.messages.length === 0) return false;

    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    globalLog.level = DBProcesLoggerLogLevel.Error; //when set to error, the back-end creates a support ticket
    await apiAddProcessLog(globalLog, accessToken);
    globalLog.clear();

    return true;
  } catch (err) {
    console.debug('Error while uploading global client log to API', err);

    throw err;
  }
}

const logService = { info, debug, error, upload };

export default logService;
