import Entity from 'models/entity';

export const TagInvalidChars = [',', ':'];

export default class Tag {
  tagId: number;

  tagName: string;

  tagValue: string;

  tagColor: string;

  tagInfo?: string;

  usedCount: number;

  entity?: Entity;

  constructor() {
    this.tagId = 0;
    this.tagName = '';
    this.tagValue = '';
    this.tagColor = '';
    this.usedCount = 0;
  }

  value(): string {
    return this.tagName + ' : ' + this.tagValue;
  }

  static getEmptyTag(): Tag {
    const emptyTag = new Tag();
    emptyTag.tagName = '?';
    emptyTag.tagValue = '?';

    return emptyTag;
  }

  clone(): Tag {
    const newTag = new Tag();
    newTag.tagId = this.tagId;
    newTag.tagName = this.tagName;
    newTag.tagValue = this.tagValue;
    newTag.tagColor = this.tagColor;
    newTag.tagInfo = this.tagInfo;
    newTag.usedCount = this.usedCount;

    return newTag;
  }
}

export class TaskTag {
  tagId: number;

  taskId: number;

  constructor() {
    this.tagId = 0;
    this.taskId = 0;
  }
}

export class RiskTag {
  tagId: number;

  riskId: number;

  constructor() {
    this.tagId = 0;
    this.riskId = 0;
  }
}

export class ControlTag {
  tagId: number;

  controlId: number;

  constructor() {
    this.tagId = 0;
    this.controlId = 0;
  }
}
