import ResourceLinkChannelDTO from 'models/dto/resourceLinkChannelDTO';
import ResourceLinkDTO, { EntityLinkDTO, ResourceLinkVersionDTO } from 'models/dto/resourceLinkDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import ResourceLink, { EntityLink, ResourceLinkVersion } from 'models/resourceLink';
import ResourceLinkChannel from 'models/resourceLinkChannel';
import ResourceList from 'models/resourceList';
import AppError from 'utils/appError';
import { mapFromEntity } from './activityMapping';
import { mapFromApprovals } from './approvalMapping';
import { toApiDateTimeRequired } from 'utils/datetime';
import IdListDTO from 'models/dto/IdListDTO';

export function mapFromResourceLink(link: ResourceLinkDTO, cache: GlobalDataCache): ResourceLink {
  const output = new ResourceLink();
  output.linkId = link.linkId;
  output.linkName = link.linkName;
  output.linkURL = link.linkURL;
  output.linkURLFragment = link.linkURLFragment;
  output.usedCount = link.usedCount;
  output.entity = link.entity ? mapFromEntity(link.entity) : undefined;
  output.listId = link.listId;
  output.listItemId = link.listItemId;
  output.driveItemId = link.driveItemId;
  output.pageId = link.pageId;
  output.pinned = link.pinned;
  output.list = cache.lists.get(link.listId);
  output.resourceLinkChannels = mapFromResourceLinkChannels(link.resourceLinkChannels);
  output.approvals = mapFromApprovals(link.approvals, cache);
  output.ownerId = link.ownerId;
  output.ownerRoleId = link.ownerRoleId;
  output.normIds = link.normIds?.idList?.map((n) => Number.parseInt(n));

  return output;
}

export function mapFromResourceLinkWithLists(link: ResourceLinkDTO, lists: ResourceList[]): ResourceLink {
  const output = new ResourceLink();
  output.linkId = link.linkId;
  output.linkName = link.linkName;
  output.linkURL = link.linkURL;
  output.linkURLFragment = link.linkURLFragment;
  output.usedCount = link.usedCount;
  output.entity = link.entity ? mapFromEntity(link.entity) : undefined;
  output.listId = link.listId;
  output.listItemId = link.listItemId;
  output.driveItemId = link.driveItemId;
  output.pageId = link.pageId;
  output.pinned = link.pinned;
  output.resourceLinkChannels = mapFromResourceLinkChannels(link.resourceLinkChannels);
  output.ownerId = link.ownerId;
  output.ownerRoleId = link.ownerRoleId;
  output.normIds = link.normIds?.idList?.map((n) => Number.parseInt(n));

  const list = lists.find((l) => l.listId === link.listId);
  if (list) {
    output.list = list;
  } else {
    throw new AppError('List not found: ' + link.listId.toString());
  }

  return output;
}

export function mapFromResourceLinks(links: ResourceLinkDTO[], cache: GlobalDataCache): ResourceLink[] {
  return links.map((_link) => {
    return mapFromResourceLink(_link, cache);
  });
}

export function mapFromResourceLinksWithLists(links: ResourceLinkDTO[], lists: ResourceList[]): ResourceLink[] {
  return links.map((_link) => {
    return mapFromResourceLinkWithLists(_link, lists);
  });
}

export function mapToResourceLinks(links: ResourceLink[] | undefined, includeMetadata: boolean): ResourceLinkDTO[] | undefined {
  if (!links) return undefined;

  return links.map((_link) => {
    return mapToResourceLink(_link, includeMetadata);
  });
}

export function mapToResourceLink(link: ResourceLink, includeMetadata: boolean): ResourceLinkDTO {
  const output = new ResourceLinkDTO();
  output.linkId = link.linkId;
  output.linkName = link.linkName;
  output.linkURL = link.linkURL;
  output.linkURLFragment = link.linkURLFragment;
  output.listId = link.listId;
  output.listItemId = link.listItemId;
  output.driveItemId = link.driveItemId;
  output.pageId = link.pageId;
  output.pinned = link.pinned;
  output.ownerId = link.ownerId;
  output.ownerRoleId = link.ownerRoleId;
  output.versions = includeMetadata ? mapToResourceLinkVersions(link.versions) : [];
  output.normIds = link.normIds ? new IdListDTO(link.normIds.map((id) => id.toString())) : undefined;

  return output;
}

export function mapToResourceLinkVersions(versions: ResourceLinkVersion[]): ResourceLinkVersionDTO[] {
  return versions.map((version) => {
    return mapToResourceLinkVersion(version);
  });
}

export function mapToResourceLinkVersion(input: ResourceLinkVersion): ResourceLinkVersionDTO {
  const output = new ResourceLinkVersionDTO();
  output.id = input.id;
  output.date = toApiDateTimeRequired(input.date);
  output.version = input.version;

  return output;
}

export function mapFromEntityLinks(entityDTOs: EntityLinkDTO[] | undefined): EntityLink[] {
  if (!entityDTOs) return [];
  
  return entityDTOs.map((entity) => {
    return mapFromEntityLink(entity);
  });
}

export function mapFromEntityLink(entityDTO: EntityLinkDTO): EntityLink {
  const entity = new EntityLink();
  entity.entityId = entityDTO.entityId;
  entity.typeOfEntity = entityDTO.typeOfEntity;
  entity.entityName = entityDTO.entityName;
  entity.entityCode = entityDTO.entityCode;
  entity.pinned = entityDTO.pinned;
  entity.linkURLFragment = entityDTO.linkURLFragment;
  entity.data = entityDTO.data;

  return entity;
}

export function mapFromResourceLinkChannels(
  resourceLinkChannelDTOs: ResourceLinkChannelDTO[] | undefined,
): ResourceLinkChannel[] | undefined {
  if (!resourceLinkChannelDTOs) return undefined;

  return resourceLinkChannelDTOs.map((r) => mapFromResourceLinkChannel(r));
}

export function mapFromResourceLinkChannel(resourceLinkChannelDTO: ResourceLinkChannelDTO): ResourceLinkChannel {
  const output = new ResourceLinkChannel();
  output.linkId = resourceLinkChannelDTO.linkId;
  output.groupId = resourceLinkChannelDTO.groupId;
  output.channelId = resourceLinkChannelDTO.channelId;
  output.pinned = resourceLinkChannelDTO.pinned;

  return output;
}
