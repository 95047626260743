import { Settings } from 'models/setting';
import { UserSettings } from 'models/userSetting';
import { IAppContext } from 'App/AppContext';
import CurrentUser from '../currentuser';
import { UserCache } from './userCache';
import { GroupCache } from './groupCache';
import { ResourceListCache } from './resourceListCache';
import { RiskStateCache } from './riskStateCache';
import { TagCache } from './tagCache';
import { TaskStateCache } from './taskStateCache';
import { TaskTypeCache } from './taskTypeCache';
import { TemplateCache } from './templateCache';
import { NormCache } from './normCache';
import { RoleCache } from './roleCache';
import { AuthSchemaCache } from './authSchemaCache';

//
// Main global data cache class
//
export type cacheMissFunc = (missedCount: number) => void;

export default class GlobalDataCache {
  appContext?: IAppContext;

  users: UserCache;

  roles: RoleCache;

  groups: GroupCache;

  settings: Settings;

  userSettings: UserSettings;

  tags: TagCache;

  lists: ResourceListCache;

  riskStates: RiskStateCache;

  taskStates: TaskStateCache;

  taskTypes: TaskTypeCache;

  templates: TemplateCache;

  norms: NormCache;

  authSchemas: AuthSchemaCache;

  constructor() {
    this.users = new UserCache();
    this.roles = new RoleCache();
    this.groups = new GroupCache();
    this.settings = new Settings();
    this.userSettings = new UserSettings();
    this.tags = new TagCache();
    this.lists = new ResourceListCache();
    this.riskStates = new RiskStateCache();
    this.taskStates = new TaskStateCache();
    this.taskTypes = new TaskTypeCache();
    this.templates = new TemplateCache();
    this.norms = new NormCache();
    this.authSchemas = new AuthSchemaCache();

    this.groups.userCache = this.users;
    this.roles.userCache = this.users;
  }

  setAppContext(appContext: IAppContext) {
    this.appContext = appContext;
    this.users.appContext = this.appContext;
    this.roles.appContext = this.appContext;
    this.groups.appContext = this.appContext;
    this.tags.appContext = this.appContext;
    this.lists.appContext = this.appContext;
    this.riskStates.appContext = this.appContext;
    this.taskStates.appContext = this.appContext;
    this.userSettings.appContext = this.appContext;
    this.taskTypes.appContext = this.appContext;
    this.templates.appContext = this.appContext;
    this.norms.appContext = this.appContext;
    this.authSchemas.appContext = this.appContext;

    this.users.cacheRef = this;
    this.roles.cacheRef = this;
    this.groups.cacheRef = this;
    this.tags.cacheRef = this;
    this.lists.cacheRef = this;
    this.riskStates.cacheRef = this;
    this.taskStates.cacheRef = this;
    this.taskTypes.cacheRef = this;
    this.templates.cacheRef = this;
    this.norms.cacheRef = this;
    this.authSchemas.cacheRef = this;
  }

  setCurrentUserLanguage(user: CurrentUser) {
    this.riskStates.setCurrentUserLanguage(user);
    this.taskStates.setCurrentUserLanguage(user);
  }

  async refresh() {
    await this.users.getItems(true);
    await this.roles.getItems(true);
    await this.groups.getItems(true);
    await this.tags.getItems(true);
    await this.lists.getItems(true);
    await this.taskTypes.getItems(true);
    await this.templates.getItems(true);
    await this.norms.getItems(true);
    await this.authSchemas.getItems(true);
  }

  clone(): GlobalDataCache {
    const newGlobalDataCache = new GlobalDataCache();

    newGlobalDataCache.groups = this.groups.clone();
    newGlobalDataCache.users = this.users.clone();
    newGlobalDataCache.roles = this.roles.clone();
    newGlobalDataCache.settings = this.settings.clone();
    newGlobalDataCache.userSettings = this.userSettings.clone();
    newGlobalDataCache.tags = this.tags.clone();
    newGlobalDataCache.lists = this.lists.clone();
    newGlobalDataCache.riskStates = this.riskStates.clone();
    newGlobalDataCache.taskStates = this.taskStates.clone();
    newGlobalDataCache.taskTypes = this.taskTypes.clone();
    newGlobalDataCache.templates = this.templates.clone();
    newGlobalDataCache.norms = this.norms.clone();
    newGlobalDataCache.authSchemas = this.authSchemas.clone();

    newGlobalDataCache.appContext = this.appContext;
    newGlobalDataCache.users.appContext = this.appContext;
    newGlobalDataCache.roles.appContext = this.appContext;
    newGlobalDataCache.groups.appContext = this.appContext;
    newGlobalDataCache.tags.appContext = this.appContext;
    newGlobalDataCache.lists.appContext = this.appContext;
    newGlobalDataCache.riskStates.appContext = this.appContext;
    newGlobalDataCache.taskStates.appContext = this.appContext;
    newGlobalDataCache.userSettings.appContext = this.appContext;
    newGlobalDataCache.taskTypes.appContext = this.appContext;
    newGlobalDataCache.templates.appContext = this.appContext;
    newGlobalDataCache.norms.appContext = this.appContext;
    newGlobalDataCache.authSchemas.appContext = this.appContext;

    newGlobalDataCache.users.cacheRef = newGlobalDataCache;
    newGlobalDataCache.roles.cacheRef = newGlobalDataCache;
    newGlobalDataCache.groups.cacheRef = newGlobalDataCache;
    newGlobalDataCache.tags.cacheRef = newGlobalDataCache;
    newGlobalDataCache.lists.cacheRef = newGlobalDataCache;
    newGlobalDataCache.riskStates.cacheRef = newGlobalDataCache;
    newGlobalDataCache.taskStates.cacheRef = newGlobalDataCache;
    newGlobalDataCache.taskTypes.cacheRef = newGlobalDataCache;
    newGlobalDataCache.templates.cacheRef = newGlobalDataCache;
    newGlobalDataCache.norms.cacheRef = newGlobalDataCache;
    newGlobalDataCache.authSchemas.cacheRef = newGlobalDataCache;

    return newGlobalDataCache;
  }

  clear() {
    this.users = new UserCache();
    this.roles = new RoleCache();
    this.groups = new GroupCache();
    this.settings = new Settings();
    this.userSettings = new UserSettings();
    this.tags = new TagCache();
    this.lists = new ResourceListCache();
    this.riskStates = new RiskStateCache();
    this.taskStates = new TaskStateCache();
    this.taskTypes = new TaskTypeCache();
    this.templates = new TemplateCache();
    this.norms = new NormCache();
    this.authSchemas = new AuthSchemaCache();

    this.groups.userCache = this.users;
    this.roles.userCache = this.users;
  }
}
