import Control_Translation from './control_Translation';
import Task from './tasks/task';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { isObjectEqual } from 'utils/object';
import { TFunction } from 'i18next';
import { PDCAState } from './pdca';
import Entity from './entity';
import ISOControl from './isoControl';
import { getPDCAStateText } from 'globalFunctions';
import Dashboard from './dashboard';
import { newGuidNil } from 'utils/guid';
import { IOwner } from './owner';
import { IAppContext } from 'App/AppContext';

export enum ControlTypes {
  Theme = 0,
  Measure = 1,
}

//order of this enum sets the order in filters
export enum ApplicabilityReasons {
  Basic = 0,
  RiskAnalysis = 1,
  LawsAndRegulations = 2,
  NotApplicable = 3,
  Contract = 4,
  Interface = 5,
  Outsourced = 6,
}

export enum ImplementationStates {
  Auto = 0,
  Full = 1,
  InProgress = 2,
}

export default class Control implements IOwner {
  tenantId: string;

  controlId: number;

  code: string;

  parentControlId?: number;

  controlType?: ControlTypes;

  state: PDCAState;

  applicabilityReason: ApplicabilityReasons[];

  implementationState: ImplementationStates;

  groupId?: string;

  ownerId?: string;

  ownerRoleId?: string;

  commentTrailId: number;

  auditTrailId: number;

  customNormIds?: number[];

  isoControlIds?: number[];

  isoNormIds?: number[];

  dashboard?: Dashboard;

  inheritTenantId?: string;

  inheritControlId?: number;

  trans?: Control_Translation[];

  tasks: Task[];

  monitoringParent?: Entity;

  tagIds: number[];

  isoControls?: ISOControl[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  background?: string;

  implementation?: string;

  outOfScopeReason?: string;

  constructor() {
    this.tenantId = newGuidNil();
    this.controlId = 0;
    this.code = '';
    this.name = '';
    this.transIdx = -1;
    this.commentTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.auditTrailId = 0; //this must be 0 for new controls. -1 means the tenant general log
    this.state = PDCAState.Plan;
    this.applicabilityReason = [ApplicabilityReasons.Basic];
    this.tasks = [];
    this.tagIds = [];
    this.implementationState = ImplementationStates.Auto;
  }

  static getControlStateText = (state: PDCAState, t: TFunction<string[]>): string => {
    return getPDCAStateText(state, t);
  };

  static getImplementationStateText = (state: ImplementationStates, t: TFunction<string[]>): string => {
    switch (state) {
      case ImplementationStates.Full:
        return t('control:ImplementationState.Full');
      case ImplementationStates.InProgress:
        return t('control:ImplementationState.InProgress');
      case ImplementationStates.Auto:
        return t('control:ImplementationState.Auto');
    }
  };

  static getApplicabilityReasonText = (applicabilities: ApplicabilityReasons[], t: TFunction<string[]>): string => {
    const reasons: string[] = [];

    if (applicabilities.includes(ApplicabilityReasons.Basic)) {
      reasons.push(t('control:ApplicabilityReason.Basic'));
    }

    if (applicabilities.includes(ApplicabilityReasons.RiskAnalysis)) {
      reasons.push(t('control:ApplicabilityReason.RiskAnalysis'));
    }

    if (applicabilities.includes(ApplicabilityReasons.LawsAndRegulations)) {
      reasons.push(t('control:ApplicabilityReason.LawsAndRegulations'));
    }

    if (applicabilities.includes(ApplicabilityReasons.Contract)) {
      reasons.push(t('control:ApplicabilityReason.Contract'));
    }

    if (applicabilities.includes(ApplicabilityReasons.NotApplicable)) {
      reasons.push(t('control:ApplicabilityReason.NotApplicable'));
    }

    if (applicabilities.includes(ApplicabilityReasons.Interface)) {
      reasons.push(t('control:ApplicabilityReason.Interface'));
    }

    if (applicabilities.includes(ApplicabilityReasons.Outsourced)) {
      reasons.push(t('control:ApplicabilityReason.Outsourced'));
    }

    return reasons.join(', ');
  };

  static hasScheduledParent = (controls: Control[], control: Control): boolean => {
    return this.getScheduledParent(controls, control) !== undefined;
  };

  static isChildOf = (controls: Control[], parent: Control, control: Control | undefined): boolean => {
    let current: Control | undefined = control;
    let max: number = 0;

    while (current !== undefined && current.parentControlId !== undefined && max < 100) {
      if (current.parentControlId === parent.controlId) {
        return true;
      }

      // eslint-disable-next-line no-loop-func
      current = controls.find((c) => c.controlId === current?.parentControlId);
      max++;
    }

    return false;
  };

  static getScheduledParent = (controls: Control[], control: Control): Entity | undefined => {
    if (!control.parentControlId) return undefined;
    let parent: Control | undefined = controls.find((c) => c.controlId === control.parentControlId);
    let max: number = 0;

    while (parent !== undefined && max < 100) {
      if (parent && parent.monitoringParent) {
        return parent.monitoringParent;
      }

      // eslint-disable-next-line no-loop-func
      parent = controls.find((c) => c.controlId === parent?.parentControlId);
      max++;
    }

    return undefined;
  };

  static getControlImplementationStateText = (
    controls: Control[],
    control: Control,
    t: TFunction<string[]>,
  ): string => {
    if (control.applicabilityReason.includes(ApplicabilityReasons.NotApplicable))
      return t('translation:General.Words.NotApplicable');

    switch (control.implementationState) {
      case ImplementationStates.Full:
        return t('control:ImplementationState.Full');
      case ImplementationStates.InProgress:
        return t('control:ImplementationState.InProgress');
      case ImplementationStates.Auto:
        if (
          control.state !== PDCAState.Plan &&
          (control.monitoringParent !== undefined || this.hasScheduledParent(controls, control))
        ) {
          return t('control:ImplementationState.Full');
        } else {
          return t('control:ImplementationState.InProgress');
        }
    }
  };

  static getControlImplementationState = (
    controls: Control[],
    control: Control | undefined,
    t: TFunction<string[]>,
  ): ImplementationStates => {
    if (!control) return ImplementationStates.InProgress;

    if (control.applicabilityReason.includes(ApplicabilityReasons.NotApplicable))
      //Returning ImplementationStates.Auto as it will not affect the filtering norms
      return ImplementationStates.Auto;

    switch (control.implementationState) {
      case ImplementationStates.Full:
        return ImplementationStates.Full;
      case ImplementationStates.InProgress:
        return ImplementationStates.InProgress;
      case ImplementationStates.Auto:
        if (
          control.state !== PDCAState.Plan &&
          (control.monitoringParent !== undefined || this.hasScheduledParent(controls, control))
        ) {
          return ImplementationStates.Full;
        } else {
          return ImplementationStates.InProgress;
        }
    }
  };

  isEqual(item: Control) {
    if (item.controlId !== this.controlId) return false;
    if (item.parentControlId !== this.parentControlId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.background !== this.background) return false;
    if (item.implementation !== this.implementation) return false;
    if (item.code !== this.code) return false;
    if (item.state !== this.state) return false;
    if (item.applicabilityReason !== this.applicabilityReason) return false;
    if (item.implementationState !== this.implementationState) return false;
    if (item.outOfScopeReason !== this.outOfScopeReason) return false;
    if (item.groupId !== this.groupId) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (!isObjectEqual(item.isoNormIds, this.isoNormIds)) return false;
    if (!isObjectEqual(item.customNormIds, this.customNormIds)) return false;
    if (!isObjectEqual(item.isoControlIds, this.isoControlIds)) return false;
    if (!isObjectEqual(item.tagIds, this.tagIds)) return false;
    if (item.tenantId !== this.tenantId) return false;
    if (item.ownerRoleId !== this.ownerRoleId) return false;

    return true;
  }

  clone(): Control {
    const newItem = new Control();

    newItem.controlId = this.controlId;
    newItem.parentControlId = this.parentControlId;
    newItem.controlType = this.controlType;
    newItem.name = this.name;
    newItem.description = this.description;
    newItem.background = this.background;
    newItem.implementation = this.implementation;
    newItem.code = this.code;
    newItem.state = this.state;
    newItem.applicabilityReason = this.applicabilityReason;
    newItem.outOfScopeReason = this.outOfScopeReason;
    newItem.implementationState = this.implementationState;
    newItem.groupId = this.groupId;
    newItem.ownerId = this.ownerId;
    newItem.commentTrailId = this.commentTrailId;
    newItem.auditTrailId = this.auditTrailId;
    newItem.transIdx = this.transIdx;
    newItem.trans = this.trans ? [...this.trans] : undefined;
    newItem.tasks = [...this.tasks];
    newItem.isoNormIds = this.isoNormIds;
    newItem.isoControlIds = this.isoControlIds;
    newItem.customNormIds = this.customNormIds;
    newItem.monitoringParent = this.monitoringParent?.clone();
    newItem.isoControls = this.isoControls ? [...this.isoControls] : undefined;
    newItem.tagIds = [...this.tagIds];
    newItem.dashboard = this.dashboard?.clone();
    newItem.inheritTenantId = this.inheritTenantId;
    newItem.inheritControlId = this.inheritControlId;
    newItem.tenantId = this.tenantId;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      code: Joi.string().max(32).required().label(localizedFields['code']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name, code: this.code }, { abortEarly: false });
  }

  getAutSchemaIds(appContext: IAppContext): (number | undefined)[] {
    const customNorms = appContext.globalDataCache.norms.getItemsForId(this.customNormIds ?? []);
    const isoNorms = appContext.globalDataCache.norms.getItemsForISONormId(this.isoNormIds ?? []);
    const ids = [...new Set([...customNorms, ...isoNorms].map((n) => n.authSchemaId))];

    return ids;
  }
}

export class ControlTaskStats {
  controlId: number;

  taskTotalCount: number;

  taskCompletedCount: number;

  constructor() {
    this.controlId = 0;
    this.taskTotalCount = 0;
    this.taskCompletedCount = 0;
  }
}

export class ControlAlertStats {
  controlId: number;

  kpiId: number;

  alertsFiredCount: number;

  constructor() {
    this.controlId = 0;
    this.kpiId = 0;
    this.alertsFiredCount = 0;
  }
}

