import ActivityDTO from 'models/dto/activityDTO';
import Activity from 'models/activity';
import Entity from 'models/entity';
import EntityDTO from 'models/dto/entityDTO';
import ActivityTrail from 'models/ActivityTrail';
import ActivityTrailDTO from 'models/dto/activityTrailDTO';
import { mapFromComments } from './commentMappings';
import {
  fromApiDateTimeOptional,
  fromApiDateTimeRequired,
  toApiDateTimeOptional,
  toApiDateTimeRequired,
} from 'utils/datetime';
import { mapFromAuditTrailEntries } from './auditMapping';

export function mapFromEntity(entityDTO: EntityDTO): Entity {
  const entity = new Entity();
  entity.entityId = entityDTO.entityId;
  entity.typeOfEntity = entityDTO.typeOfEntity;
  entity.entityName = entityDTO.entityName;
  entity.entityCode = entityDTO.entityCode;
  entity.entityOrgUnitId = entityDTO.entityOrgUnitId;

  return entity;
}

export function mapToEntity(entity: Entity): EntityDTO {
  const output = new EntityDTO();
  output.entityId = entity.entityId;
  output.typeOfEntity = entity.typeOfEntity;
  output.entityName = entity.entityName;
  output.entityCode = entity.entityCode;
  output.entityOrgUnitId = entity.entityOrgUnitId;

  return output;
}

export function mapFromEntities(entitiesDTO: EntityDTO[]): Entity[] {
  return entitiesDTO.map((e) => mapFromEntity(e));
}

export function mapFromActivity(activityDTO: ActivityDTO): Activity {
  const activity = new Activity();
  activity.activityId = activityDTO.activityId;
  activity.typeOfActivity = activityDTO.typeOfActivity;
  activity.entity = mapFromEntity(activityDTO.entity);
  activity.created = fromApiDateTimeRequired(activityDTO.created);
  activity.refDateTime = fromApiDateTimeOptional(activityDTO.refDateTime);
  activity.userId = activityDTO.userId;
  activity.refUserId = activityDTO.refUserId;
  activity.userEmail = activityDTO.userEmail;
  activity.refUserEmail = activityDTO.refUserEmail;
  activity.priority = activityDTO.priority;
  activity.dismissed = fromApiDateTimeOptional(activityDTO.dismissed);
  activity.refData = activityDTO.refData;

  return activity;
}

export function mapFromActivities(activitiesDTO: ActivityDTO[]): Activity[] {
  return activitiesDTO.map((a) => mapFromActivity(a));
}

export function mapToActivity(activity: Activity): ActivityDTO {
  const activityDTO = new ActivityDTO();
  activityDTO.activityId = activity.activityId;
  activityDTO.typeOfActivity = activity.typeOfActivity;
  activityDTO.entity = mapToEntity(activity.entity);
  activityDTO.created = toApiDateTimeRequired(activity.created);
  activityDTO.refDateTime = toApiDateTimeOptional(activity.refDateTime);
  activityDTO.userId = activity.userId;
  activityDTO.refUserId = activity.refUserId;
  activityDTO.userEmail = activity.userEmail;
  activityDTO.refUserEmail = activity.refUserEmail;
  activityDTO.priority = activity.priority;
  activityDTO.dismissed = toApiDateTimeOptional(activity.dismissed);
  activityDTO.refData = activity.refData;

  return activityDTO;
}

export function mapFromActivityTrail(activityTrail: ActivityTrailDTO): ActivityTrail {
  const output = new ActivityTrail();
  output.typeOfEntity = activityTrail.typeOfEntity;
  output.commentTrailId = activityTrail.commentTrailId;
  output.auditTrailId = activityTrail.auditTrailId;
  output.entityId = activityTrail.entityId;
  output.activityCount = activityTrail.activityCount;
  output.activities = mapFromActivities(activityTrail.activities);
  output.commentTrail = mapFromComments(activityTrail.commentTrail);
  output.auditTrailEntries = mapFromAuditTrailEntries(activityTrail.auditTrailEntries);

  return output;
}
