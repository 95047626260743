import AuditTrail from 'models/auditTrail';
import AuditTrailAction from 'models/auditTrailAction';
import AuditTrailAction_Translation from 'models/auditTrailAction_Translation';
import AuditTrailEntry from 'models/auditTrailEntry';
import AuditTrailActionDTO from 'models/dto/auditTrailActionDTO';
import AuditTrailAction_TranslationDTO from 'models/dto/auditTrailAction_TranslationDTO';
import AuditTrailDTO from 'models/dto/auditTrailDTO';
import AuditTrailEntryDTO from 'models/dto/auditTrailEntryDTO';
import { fromApiDateTimeRequired } from 'utils/datetime';
import { mapFromLanguage, mapFromUser } from './dataMapping';

export function mapFromAuditTrail(auditDTO: AuditTrailDTO | undefined): AuditTrail {
  const output = new AuditTrail();
  if (!auditDTO) return output;
  output.auditTrailId = auditDTO.auditTrailId;
  output.tenantId = auditDTO.tenantId;
  output.entries = auditDTO.entries.map((auditTrailEntryDTO: AuditTrailEntryDTO) => {
    return mapFromAuditTrailEntry(auditTrailEntryDTO);
  });

  return output;
}

export function mapFromAuditTrailEntry(auditTrailEntryDTO: AuditTrailEntryDTO): AuditTrailEntry {
  const output = new AuditTrailEntry();

  output.tenantId = auditTrailEntryDTO.tenantId;
  output.auditTrailId = auditTrailEntryDTO.auditTrailId;
  output.auditTrailEntryId = auditTrailEntryDTO.auditTrailEntryId;
  output.auditTrailActionId = auditTrailEntryDTO.auditTrailActionId;
  output.auditTrailAction = mapFromAuditTrailAction(auditTrailEntryDTO.auditTrailAction);
  output.actionCode = auditTrailEntryDTO.actionCode;
  output.created = fromApiDateTimeRequired(auditTrailEntryDTO.created);
  output.createdBy = mapFromUser(auditTrailEntryDTO.createdBy, undefined);
  output.userId = auditTrailEntryDTO.userId;
  output.userName = auditTrailEntryDTO.userName;
  output.property = auditTrailEntryDTO.property;
  output.oldValue = auditTrailEntryDTO.oldValue;
  output.newValue = auditTrailEntryDTO.newValue;

  return output;
}

export function mapFromAuditTrailEntries(auditTrailEntryDTOs: AuditTrailEntryDTO[]): AuditTrailEntry[] {
  return auditTrailEntryDTOs.map((entry) => {
    return mapFromAuditTrailEntry(entry);
  });
}

export function mapFromAuditTrailActions(auditTrailActionDTOs: AuditTrailActionDTO[]): AuditTrailAction[] {
  const auditTrailActions: AuditTrailAction[] = [];

  for (let auditTrailActionDTO of auditTrailActionDTOs) {
    const auditTrailActionTranslation = mapFromAuditTrailAction(auditTrailActionDTO);
    if (auditTrailActionTranslation) {
      auditTrailActions.push(auditTrailActionTranslation);
    }
  }

  return auditTrailActions;
}

export function mapFromAuditTrailAction(
  auditTrailActionDTO: AuditTrailActionDTO | undefined,
): AuditTrailAction | undefined {
  if (!auditTrailActionDTO) {
    return undefined;
  }

  const auditTrailAction = new AuditTrailAction();
  auditTrailAction.code = auditTrailActionDTO.code;
  auditTrailAction.auditTrailActionId = auditTrailActionDTO.auditTrailActionId;
  auditTrailAction.trans = auditTrailActionDTO.trans.map(
    (auditTrailAction_TranslationDTO: AuditTrailAction_TranslationDTO) => {
      return mapFromAuditAction_Translation(auditTrailAction_TranslationDTO);
    },
  );

  if (auditTrailAction.trans && auditTrailAction.trans.length > 0) {
    auditTrailAction.transIdx = 0;
    auditTrailAction.name = auditTrailAction.trans[0].name;
  }

  return auditTrailAction;
}

export function mapFromAuditAction_Translation(
  auditTrailAction_TranslationDTO: AuditTrailAction_TranslationDTO,
): AuditTrailAction_Translation {
  const auditTrailAction_Translation = new AuditTrailAction_Translation();
  auditTrailAction_Translation.auditTrailActionId = auditTrailAction_TranslationDTO.auditTrailActionId;
  auditTrailAction_Translation.lang = mapFromLanguage(auditTrailAction_TranslationDTO.lang);
  auditTrailAction_Translation.languageId = auditTrailAction_TranslationDTO.languageId;
  auditTrailAction_Translation.name = auditTrailAction_TranslationDTO.name;

  return auditTrailAction_Translation;
}
