import { fromApiDateTimeOptional } from 'utils/datetime';
import { mapFromObjectives } from './objectiveMapping';
import { mapFromProcesses } from './processMapping';
import RiskDTO from 'models/dto/riskDTO';
import RisksDTO from 'models/dto/risksDTO';
import RiskStateDTO from 'models/dto/riskStateDTO';
import RiskState_TranslationDTO from 'models/dto/riskState_TranslationDTO';
import Risk_TranslationDTO from 'models/dto/risk_TranslationDTO';
import Risk from 'models/risk';
import Risks from 'models/risks';
import RiskState from 'models/riskState';
import RiskState_Translation from 'models/riskState_Translation';
import Risk_Translation from 'models/risk_Translation';
import { mapFromLanguage, mapFromResourceLinks, mapFromThemes, mapToLanguage } from './dataMapping';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { RiskControlTasksDTO } from 'models/dto/RiskControlTasksDTO';
import { RiskControlTasks } from 'models/riskControlTasks';
import { mapFromControlAlertStats, mapFromControls, mapFromControlTaskStats } from './controlMappings';
import { RiskContextDTO } from 'models/dto/riskContextDTO';
import { RiskContext } from 'models/riskContext';
import IdListDTO from 'models/dto/IdListDTO';

export function mapFromRisksArray(risksDto: RiskDTO[], globalDataCache: GlobalDataCache): Risk[] {
  const output = risksDto.map((_riskDTO) => mapFromRisk(_riskDTO, globalDataCache));

  return output;
}

export function mapFromRisks(risksDto: RisksDTO, globalDataCache: GlobalDataCache): Risks {
  const output = new Risks();
  output.risks = risksDto.risks ? risksDto.risks.map((_riskDTO) => mapFromRisk(_riskDTO, globalDataCache)) : [];

  return output;
}

export function mapFromRisk(risk: RiskDTO, globalDataCache: GlobalDataCache): Risk {
  const output = new Risk();
  output.code = risk.code;
  output.riskId = risk.riskId;
  output.authSchemaId = risk.authSchemaId;
  output.riskStateId = risk.riskStateId;
  output.commentTrailId = risk.commentTrailId;
  output.auditTrailId = risk.auditTrailId;
  output.groupId = risk.groupId;
  output.group = risk.groupId ? globalDataCache.groups.get(risk.groupId) : undefined;
  output.ownerId = risk.ownerId;
  output.ownerRoleId = risk.ownerRoleId;
  output.owner = risk.ownerId ? globalDataCache.users.get(risk.ownerId) : undefined;
  output.sortOrder = risk.sortOrder;
  output.chanceStart = risk.chanceStart;
  output.chanceCurrent = risk.chanceCurrent;
  output.chanceGoal = risk.chanceGoal;
  output.impactStart = risk.impactStart;
  output.impactCurrent = risk.impactCurrent;
  output.impactGoal = risk.impactGoal;
  output.analysisTaskid = risk.analysisTaskid;
  output.method = risk.method;
  output.latestChangeDateTime = fromApiDateTimeOptional(risk.latestChangeDateTime);
  output.tagIds = risk.tagIds?.idList?.map<number>((i) => Number(i));
  output.classificationGroupIds = risk.classificationGroupIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.assetIds = risk.assetIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.trans = risk.trans
    ? risk.trans.map((_risk_TranslationDTO) => mapFromRisk_Translation(_risk_TranslationDTO))
    : [];

  output.controls = risk.controls ? mapFromControls(risk.controls, globalDataCache) : undefined;
  output.resourceLinks = risk.resourceLinks ? mapFromResourceLinks(risk.resourceLinks, globalDataCache) : [];
  output.normIds = risk.normIds?.idList?.map<number>((i) => Number(i)) ?? [];

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.asset = output.trans[0].asset;
    output.description = output.trans[0].description;
    output.analysis = output.trans[0].analysis;
    output.strategy = output.trans[0].strategy;
  }

  return output;
}

export function mapFromRisk_Translation(risk_Translation: Risk_TranslationDTO): Risk_Translation {
  const output = new Risk_Translation();
  output.riskId = risk_Translation.riskId;
  output.languageId = risk_Translation.languageId;
  output.name = risk_Translation.name;
  output.asset = risk_Translation.asset || '';
  output.description = risk_Translation.description || '';
  output.analysis = risk_Translation.analysis || '';
  output.strategy = risk_Translation.strategy || '';
  output.lang = mapFromLanguage(risk_Translation.lang);

  return output;
}

export function mapFromRiskStates(riskStateDTO: RiskStateDTO[]): RiskState[] {
  return riskStateDTO.map((_stateDTO) => {
    return mapFromRiskState(_stateDTO);
  });
}

export function mapFromRiskState(riskStateDto: RiskStateDTO): RiskState {
  const output = new RiskState();
  output.riskStateId = riskStateDto.riskStateId;
  output.completed = riskStateDto.completed;
  output.sortOrder = riskStateDto.sortOrder;
  output.riskCount = riskStateDto.riskCount;
  output.trans = riskStateDto.trans.map((_riskState_TranslationDTO) =>
    mapFromRiskState_Translation(_riskState_TranslationDTO),
  );

  if (output.trans && output.trans.length > 0) {
    output.state = output.trans[0].state;
  }

  return output;
}

export function mapFromRiskState_Translation(riskState_Translation: RiskState_TranslationDTO): RiskState_Translation {
  const output = new RiskState_Translation();
  output.state = riskState_Translation.state;
  output.lang = mapFromLanguage(riskState_Translation.lang);

  return output;
}

export function mapFromRiskControlTasks(
  riskControlTaskDTO: RiskControlTasksDTO | undefined,
  globalDataCache: GlobalDataCache,
): RiskControlTasks {
  const output = new RiskControlTasks();
  if (riskControlTaskDTO) {
    output.risk = mapFromRisk(riskControlTaskDTO.risk, globalDataCache);
    output.controlTaskStats = mapFromControlTaskStats(riskControlTaskDTO.controlTaskStats);
    output.controlAlertStats = mapFromControlAlertStats(riskControlTaskDTO.controlAlertStats);
  }

  return output;
}

//mapToRisk methods
export function mapToRisks(risks: Risk[]): RiskDTO[] {
  return risks.map((risk) => mapToRisk(risk));
}

export function mapToRisk(risk: Risk): RiskDTO {
  const output = new RiskDTO();
  output.riskId = risk.riskId;
  output.authSchemaId = risk.authSchemaId;
  output.code = risk.code;
  output.riskStateId = risk.riskStateId;
  output.code = risk.code;
  output.commentTrailId = risk.commentTrailId;
  output.auditTrailId = risk.auditTrailId;
  output.groupId = risk.groupId;
  output.sortOrder = risk.sortOrder;
  output.chanceStart = risk.chanceStart;
  output.chanceCurrent = risk.chanceCurrent;
  output.chanceGoal = risk.chanceGoal;
  output.impactStart = risk.impactStart;
  output.impactCurrent = risk.impactCurrent;
  output.impactGoal = risk.impactGoal;
  output.analysisTaskid = risk.analysisTaskid;
  output.ownerId = risk.ownerId;
  output.ownerRoleId = risk.ownerRoleId;
  output.method = risk.method;
  output.classificationGroupIds = new IdListDTO(risk.classificationGroupIds.map((s) => s.toString()));
  output.assetIds = new IdListDTO(risk.assetIds.map((s) => s.toString()));
  output.trans = risk.trans.map((_risk_Translation) => mapToRisk_Translation(_risk_Translation));

  //controls and resourcelinks cannot be send to the API at this moment

  if (risk.trans && risk.transIdx >= 0) {
    output.trans[risk.transIdx].name = risk.name;
    output.trans[risk.transIdx].description = risk.description;
    output.trans[risk.transIdx].asset = risk.asset;
    output.trans[risk.transIdx].analysis = risk.analysis;
    output.trans[risk.transIdx].strategy = risk.strategy;
  }

  return output;
}

export function mapToRisk_Translation(risk_Translation: Risk_Translation): Risk_TranslationDTO {
  const output = new Risk_TranslationDTO();
  output.riskId = risk_Translation.riskId;
  output.languageId = risk_Translation.languageId;
  output.name = risk_Translation.name;
  output.asset = risk_Translation.asset;
  output.description = risk_Translation.description;
  output.analysis = risk_Translation.analysis;
  output.strategy = risk_Translation.strategy;
  output.lang = mapToLanguage(risk_Translation.lang);

  return output;
}

//risk context
export const mapFromRiskContext = (context: RiskContextDTO, globalCache: GlobalDataCache): RiskContext => {
  const output = new RiskContext();
  output.themes = mapFromThemes(context.themes, globalCache);
  output.processes = mapFromProcesses(context.processes, globalCache);
  output.objectives = mapFromObjectives(context.objectives, globalCache);

  return output;
};
