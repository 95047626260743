import { apiGetNorms } from 'services/Api/normService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';
import Norm from 'models/norm';

export class NormCache extends EntityCache<Norm> implements ICache<Norm, NormCache> {
  private isLoading: boolean = false;

  private normPromise: Promise<Norm[]> | undefined;

  constructor() {
    super();
    this.getId = (norm) => norm.normId;
    this.getEmptyItem = () => new Norm();
  }

  hasISO(id: number | undefined): boolean {
    let norm = this.items?.find((i) => i.isoNormId === id);
    if (!norm) return false;

    return true;
  }

  getISO(id: number | undefined): Norm {
    let norm = this.items?.find((i) => i.isoNormId === id);
    if (norm) {
      return norm;
    } else {
      return new Norm();
    }
  }

  override update(item: Norm) {
    //cache only contains active standard
    //so add/remove when active state changes
    const idx = this.items.findIndex((i) => i.normId === item.normId);
    if (idx >= 0) {
      if (!item.active && this.items[idx].active) {
        this.remove(item);
      } else {
        this.update(item);
      }
    } else {
      if (item.active) {
        this.add(item);
      }
    }
  }

  getItemsForISONormId(ids: number[] | undefined): Norm[] {
    if (!ids) return [];

    const result: Norm[] = [];
    ids.forEach((i) => {
      const norm = this.getISO(i);
      if (norm) {
        result.push(norm);
      }
    });

    return result;
  }

  async getItems(refresh: boolean = false): Promise<Norm[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.normPromise = apiGetNorms(undefined, true, accessToken);
          this.isLoading = true;
          this.items = await this.normPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.normPromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.normPromise) {
      return await this.normPromise;
    } else {
      return this.items;
    }
  }

  getItemsCustom(): Norm[] {
    return this.items.filter((n) => !n.isoNormId);
  }

  getItemsISO(): Norm[] {
    return this.items.filter((n) => n.isoNormId);
  }

  filterCustomIds(ids: number[] | undefined): number[] {
    return ids?.filter((id) => this.itemSet.get(id)?.isoNormId === undefined) ?? [];
  }

  filterISOIds(ids: number[] | undefined): number[] {
    return (ids?.map((id) => this.itemSet.get(id)?.isoNormId).filter((id) => id !== undefined) as number[]) ?? [];
  }

  clone(): NormCache {
    const normCache: NormCache = new NormCache();
    normCache.items = this.items?.map((u) => u.clone());

    return normCache;
  }
}
