import { mapFromResourceList, mapFromResourceLists, mapToResourceList } from 'mappings/dataMapping';
import ResourceListDTO from 'models/dto/resourceListDTO';
import ResourceList from 'models/resourceList';
import AppError from 'utils/appError';
import http from './httpService';

export async function apiGetLists(accessToken: string): Promise<ResourceList[]> {
  try {
    const ar = await http.get<ResourceListDTO[]>(`/lists`, http.getRequestConfig(accessToken));

    return mapFromResourceLists(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetResourceListName(name: string, accessToken: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/lists/name`, { valueString: name }, http.getRequestConfig(accessToken));
    const id = Number(ar.data);

    return id;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddNewList(accessToken: string, item: ResourceList): Promise<ResourceList> {
  try {
    const ar = await http.post<ResourceListDTO>(`/lists`, mapToResourceList(item), http.getRequestConfig(accessToken));

    return mapFromResourceList(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveList(accessToken: string, listId: number) {
  try {
    await http.post(`/lists/delete/${listId}`, {}, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateList(accessToken: string, item: ResourceList): Promise<ResourceList> {
  try {
    const ar = await http.put<ResourceListDTO>(`/lists`, mapToResourceList(item), http.getRequestConfig(accessToken));

    return mapFromResourceList(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
