import TaskDTO from 'models/dto/tasks/taskDTO';

export class TaskTaskDTO {
  taskIdFrom: number;

  taskIdTo: number;

  relationship: number;

  taskFrom?: TaskDTO;

  taskTo?: TaskDTO;

  constructor() {
    this.taskIdFrom = 0;
    this.taskIdTo = 0;
    this.relationship = 0;
  }
}
