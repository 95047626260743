import { TFunction } from 'i18next';
import { KPIAggregationMethods } from './kpi';
import Task from 'models/tasks/task';
import { KPIContextFilter } from './kpiDataContext';

export enum KPIAlertOperator {
  Less = 1,
  LessOrEqual = 2,
  Greater = 3,
  GreaterOrEqual = 4,
}

export enum KPIAlertPeriods {
  Week = 1,
  Month = 2,
  Quarter = 3,
  Year = 4,
}

export default class KPIAlert {
  kpiId: number;

  kpiAlertId: number;

  operator: KPIAlertOperator;

  treshold: number;

  aggregationMethod: KPIAggregationMethods;

  aggregationPeriod: KPIAlertPeriods;

  templateId: number;

  template: Task | undefined;

  filter: KPIContextFilter;

  lastAlert?: Date;

  constructor() {
    this.kpiId = 0;
    this.kpiAlertId = 0;
    this.operator = KPIAlertOperator.Greater;
    this.treshold = 5;
    this.aggregationMethod = KPIAggregationMethods.Count;
    this.aggregationPeriod = KPIAlertPeriods.Week;
    this.templateId = 0;
    this.filter = new KPIContextFilter();
  }

  clone(): KPIAlert {
    const output = new KPIAlert();
    output.kpiId = this.kpiId;
    output.kpiAlertId = this.kpiAlertId;
    output.operator = this.operator;
    output.treshold = this.treshold;
    output.aggregationMethod = this.aggregationMethod;
    output.aggregationPeriod = this.aggregationPeriod;
    output.templateId = this.templateId;
    output.template = this.template?.clone();
    output.filter = this.filter;

    return output;
  }

  getSummary(t: TFunction<string[]>): string {
    let operatorText: string = '';
    let periodText: string = '';
    let methodText: string = '';

    switch (this.aggregationMethod) {
      case KPIAggregationMethods.Sum:
        methodText = t('kpi:Alerts.MethodSummary.Sum');
        break;
      case KPIAggregationMethods.Count:
        methodText = t('kpi:Alerts.MethodSummary.Count');
        break;
      case KPIAggregationMethods.Min:
        methodText = t('kpi:Alerts.MethodSummary.Min');
        break;
      case KPIAggregationMethods.Max:
        methodText = t('kpi:Alerts.MethodSummary.Max');
        break;
      case KPIAggregationMethods.Avg:
        methodText = t('kpi:Alerts.MethodSummary.Avg');
        break;
    }

    switch (this.aggregationPeriod) {
      case KPIAlertPeriods.Week:
        periodText = t('kpi:Alerts.PeriodSummary.Week');
        break;
      case KPIAlertPeriods.Month:
        periodText = t('kpi:Alerts.PeriodSummary.Month');
        break;
      case KPIAlertPeriods.Quarter:
        periodText = t('kpi:Alerts.PeriodSummary.Quarter');
        break;
      case KPIAlertPeriods.Year:
        periodText = t('kpi:Alerts.PeriodSummary.Year');
        break;
    }

    switch (this.operator) {
      case KPIAlertOperator.Greater:
        operatorText = t('kpi:Alerts.OperatorSummary.Greater');
        break;
      case KPIAlertOperator.GreaterOrEqual:
        operatorText = t('kpi:Alerts.OperatorSummary.GreaterOrEqual');
        break;
      case KPIAlertOperator.Less:
        operatorText = t('kpi:Alerts.OperatorSummary.Less');
        break;
      case KPIAlertOperator.LessOrEqual:
        operatorText = t('kpi:Alerts.OperatorSummary.LessOrEqual');
        break;
    }

    const summary = t('kpi:Alerts.Summary', {
      method: methodText,
      period: periodText,
      operator: operatorText,
      value: this.treshold.toString(),
      event: this.template?.name ?? '...',
    });

    return summary;
  }

  static getOperatorText(operator: KPIAlertOperator, t: TFunction<string[]>) {
    switch (operator) {
      case KPIAlertOperator.Greater:
        return t('kpi:Alerts.Operators.Greater');
      case KPIAlertOperator.GreaterOrEqual:
        return t('kpi:Alerts.Operators.GreaterOrEqual');
      case KPIAlertOperator.Less:
        return t('kpi:Alerts.Operators.Less');
      case KPIAlertOperator.LessOrEqual:
        return t('kpi:Alerts.Operators.LessOrEqual');
    }
  }

  static getAggregationPeriodText(aggregationPeriod: KPIAlertPeriods, t: TFunction<string[]>) {
    switch (aggregationPeriod) {
      case KPIAlertPeriods.Week:
        return t('kpi:Alerts.AlertPeriods.Week');
      case KPIAlertPeriods.Month:
        return t('kpi:Alerts.AlertPeriods.Month');
      case KPIAlertPeriods.Quarter:
        return t('kpi:Alerts.AlertPeriods.Quarter');
      case KPIAlertPeriods.Year:
        return t('kpi:Alerts.AlertPeriods.Year');
    }
  }
}
