import { PartialTheme } from '@fluentui/react';

export const lightTheme: PartialTheme = {
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
};

export const darkTheme: PartialTheme = {
  palette: {
    themePrimary: '#74acd6',
    themeLighterAlt: '#f9fbfd',
    themeLighter: '#e6f1f8',
    themeLight: '#d1e4f3',
    themeTertiary: '#a7cbe7',
    themeSecondary: '#82b5db',
    themeDarkAlt: '#689ac1',
    themeDark: '#5882a3',
    themeDarker: '#416078',
    neutralLighterAlt: '#1b214d',
    neutralLighter: '#5c5854',
    neutralLight: '#33312f',
    neutralQuaternaryAlt: '#232c66',
    neutralQuaternary: '#2a2928',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#dedede;',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#2a2a2a',
  },
};

export const darkThemeTeams: PartialTheme = {
  palette: {
    themePrimary: '#74acd6',
    themeLighterAlt: '#f9fbfd',
    themeLighter: '#e6f1f8',
    themeLight: '#d1e4f3',
    themeTertiary: '#a7cbe7',
    themeSecondary: '#82b5db',
    themeDarkAlt: '#689ac1',
    themeDark: '#5882a3',
    themeDarker: '#416078',
    neutralLighterAlt: '#1b214d',
    neutralLighter: '#5c5854',
    neutralLight: '#33312f',
    neutralQuaternaryAlt: '#232c66',
    neutralQuaternary: '#2a2928',
    neutralTertiaryAlt: '#36449c',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#dedede;',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#2a2a2a',
  },
};

export const lightThemeTeams: PartialTheme = {
  palette: {
    themePrimary: '#6462a7',
    themeLighterAlt: '#040407',
    themeLighter: '#10101b',
    themeLight: '#1e1d32',
    themeTertiary: '#3c3b63',
    themeSecondary: '#585692',
    themeDarkAlt: '#706eaf',
    themeDark: '#8381bb',
    themeDarker: '#a09ecd',
    neutralLighterAlt: '#eeeeee',
    neutralLighter: '#eaeaea',
    neutralLight: '#e1e1e1',
    neutralQuaternaryAlt: '#d1d1d1',
    neutralQuaternary: '#c8c8c8',
    neutralTertiaryAlt: '#c0c0c0',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#f5f5f5',
  },
};

export const contrastThemeTeams: PartialTheme = {
  palette: {
    themePrimary: '#00eeff',
    themeLighterAlt: '#000a0a',
    themeLighter: '#002629',
    themeLight: '#00474d',
    themeTertiary: '#008f99',
    themeSecondary: '#00d1e0',
    themeDarkAlt: '#19f0ff',
    themeDark: '#3df2ff',
    themeDarker: '#70f5ff',
    neutralLighterAlt: '#0b0b0b',
    neutralLighter: '#151515',
    neutralLight: '#252525',
    neutralQuaternaryAlt: '#2f2f2f',
    neutralQuaternary: '#373737',
    neutralTertiaryAlt: '#595959',
    neutralTertiary: '#4d4d15',
    neutralSecondary: '#999929',
    neutralPrimaryAlt: '#e0e03d',
    neutralPrimary: '#ffff44',
    neutralDark: '#ffff72',
    black: '#ffff97',
    white: '#000000',
  },
};
