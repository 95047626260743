import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { StoreMgmtStates } from './adminTenant';
import { addDateDays, getDateTimeDiffHours } from 'utils/datetime';
import { globalTrialLengthDays } from 'globalConstants';
import Tenant from './tenant';
import { SubscriptionTypes } from 'utils/subscription';

export default class Login {
  tenantId: string; //tenant id that the back-end returns. can be an org. unit id

  userId?: string;

  lastLogin?: Date;

  tenantLicensed: boolean;

  userLicensed: boolean;

  userSystemRoles?: number[];

  userCustomRoles?: string[];

  permissions?: number[];

  userLanguageCode?: string;

  defLanguageCode: string;

  trialStart?: Date;

  globalDataCache: GlobalDataCache;

  isGuest: boolean;

  isOrgUnit: boolean;

  storeMgmtState: StoreMgmtStates;

  subscriptionType: number;

  leadSource?: string;

  partnerId?: string;

  tenants: Tenant[];

  constructor() {
    this.tenantId = '';
    this.tenantLicensed = false;
    this.userLicensed = false;
    this.defLanguageCode = '';
    this.globalDataCache = new GlobalDataCache();
    this.isGuest = false;
    this.isOrgUnit = false;
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
    this.tenants = [];
  }

  isInTrial(): boolean {
    if (!this.trialStart) return false;

    return true;
  }

  trialEnded(): boolean {
    if (!this.trialStart) return false;
    const trialEnd = this.trialEnd();
    if (!trialEnd) return false;
    const trialOverDueHours = getDateTimeDiffHours(trialEnd, new Date());

    return trialOverDueHours < 0;
  }

  trialEnd(): Date | undefined {
    if (!this.trialStart) return undefined;

    return addDateDays(this.trialStart, globalTrialLengthDays);
  }
}
