import ResourceList, { ResourceListSystemType } from 'models/resourceList';
import { apiGetLists } from 'services/Api/listService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';

export class ResourceListCache extends EntityCache<ResourceList> implements ICache<ResourceList, ResourceListCache> {
  private isLoading: boolean = false;

  private listsPromise: Promise<ResourceList[]> | undefined;

  constructor() {
    super();
    this.getId = (list) => list.listId;
    this.getEmptyItem = () => new ResourceList();
  }

  getSystem(systemType: ResourceListSystemType): ResourceList[] {
    let lists = this.items?.filter((i) => i.systemListId === systemType);
    if (lists) {
      return lists;
    } else {
      return [];
    }
  }

  async getItems(refresh: boolean = false): Promise<ResourceList[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.listsPromise = apiGetLists(accessToken);
          this.items = await this.listsPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.listsPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.listsPromise) {
      return await this.listsPromise;
    } else {
      return this.items;
    }
  }

  clone(): ResourceListCache {
    const listCache: ResourceListCache = new ResourceListCache();
    listCache.items = this.items?.map((u) => u.clone());

    return listCache;
  }
}
