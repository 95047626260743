import {
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  TooltipHost,
  Sticky,
  StickyPositionType,
  Label,
  FontIcon,
  Stack,
  Text,
  ITextStyles,
  IFontStyles,
  IDropdownOption,
  IPanelProps,
  IPanelHeaderRenderer,
} from '@fluentui/react';
import { globalStackTokensGapSmall, globalTextStylesBold, infoIcon } from './globalStyles';
import Control from 'models/control';
import Theme from 'models/theme';
import Risk from 'models/risk';
import { IAppContext } from 'App/AppContext';
import { TFunction } from 'i18next';
import Objective from 'models/objective/objective';
import { PDCAState } from 'models/pdca';
import Process from 'models/process/process';
import Task from 'models/tasks/task';
import Entity, { EntityTypes } from 'models/entity';
import { appRoles } from 'services/Auth/appRoles';
import Asset from 'models/asset/asset';
import KPI from 'models/kpi/kpi';
import Tag from 'models/tag';
import ResourceLink from 'models/resourceLink';
import CopyId from 'components/Utils/CopyId';
import StripeSubscription from 'models/Stripe/StripeSubscription';
import { apiGetNorms } from 'services/Api/normService';
import { apiRequest } from 'services/Auth/authConfig';
import { SubscriptionTypes } from 'utils/subscription';
import { Role } from 'models/auth/role';
import Norm from 'models/norm';
import TaskType from 'models/tasks/taskType';
import ResourceList from 'models/resourceList';
import { IAuthObject } from 'models/auth/authSchema';
import { sortOnNumber, sortOnString } from 'utils/sorting';

//
// Global render helpers
//
export const onRenderDetailsHeaderGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!(props)}
    </Sticky>
  );
};

export const onRenderDetailsHeaderNoPaddingTopGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }
  const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
    <TooltipHost {...tooltipHostProps} />
  );

  props.styles = { root: { paddingTop: 0 } };

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
};

export const onRenderPanelHeaderGlobal = (
  title: string,
  id: string | number,
  hasCloseButton: boolean,
): IRenderFunction<IPanelProps> => {
  return (
    props?: IPanelProps,
    defaultRender?: IPanelHeaderRenderer,
    headerTextId?: string | undefined,
  ): JSX.Element | null => {
    return (
      <Stack
        grow={hasCloseButton ? 1 : undefined}
        horizontal
        tokens={globalStackTokensGapSmall}
        verticalAlign="end"
        styles={{ root: { paddingLeft: 24 } }}
      >
        <Text variant="xLarge" styles={globalTextStylesBold}>
          {title}
        </Text>
        <CopyId id={id} />
      </Stack>
    );
  };
};

export const onRenderTextWithInfo = (
  text: string,
  info: string,
  variant?: keyof IFontStyles,
  styles?: Partial<ITextStyles>,
) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Text variant={variant} styles={styles}>
        {text}
      </Text>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

export const onRenderLabelWithInfo = (label: string, info: string) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Label>{label}</Label>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

export const onRenderGridPermissions = (
  item: IAuthObject | undefined,
  appContext: IAppContext,
  t: TFunction<string[]>,
): JSX.Element | undefined => {
  if (!item) return undefined;
  if (!item.authSchemaId) {
    return <Text>{t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
  } else {
    const schema = appContext.globalDataCache.authSchemas.get(item.authSchemaId);

    return <Text>{schema.name || t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
  }
};

//
// Treelist hierarchy helpers
//
const getId = (item: Control | Theme | Risk) => {
  if (item instanceof Control) return item.controlId;
  else if (item instanceof Theme) return item.themeId;
  else if (item instanceof Risk) return item.riskId;

  return -1;
};

const getParentId = (item: Control | Theme) => {
  if (item instanceof Control) return item.parentControlId;
  else if (item instanceof Theme) return item.parentThemeId;

  return -1;
};

export function isValidToRemoveFromList(
  itemToRemove: Control | Theme,
  itemsToRemove: (Control | Theme)[],
  allItems: (Control | Theme)[],
): boolean {
  if (getParentId(itemToRemove) === undefined) return true;
  if (itemsToRemove.find((item: Control | Theme) => getId(item) === getParentId(itemToRemove)) !== undefined)
    return false;

  const parentControl = allItems.find((item) => getId(item) === getParentId(itemToRemove));
  if (!parentControl) return true;

  return isValidToRemoveFromList(parentControl, itemsToRemove, allItems);
}

//
// Freshdesk widget API
//

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetOpen = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetClose = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('close');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetOpenTicket = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open', 'ticketForm');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetSetSubject = (subject: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    subject: subject,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const freshdeskWidgetSetContactInfo = (name: string, email: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    name: name,
    email: email,
  });
};

export const freshdeskWidgetSetTranslations = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('setLabels', {
    nl: {
      contact_form: {
        subject: 'Onderwerp',
      },
    },
  });
};

//
// PDCA state helpers
//
export const getPDCAStateTextForItem = (
  item: Control | Process | Objective | Theme | undefined,
  t: TFunction<string[]>,
): string => {
  if (item) {
    switch (item.state) {
      case PDCAState.Plan:
        return t('translation:PDCAState.Plan');
      case PDCAState.Do:
        return t('translation:PDCAState.Do');
      case PDCAState.Check:
        return t('translation:PDCAState.Check');
      case PDCAState.Act:
        return t('translation:PDCAState.Act');
    }
  }

  return '';
};

// global functions for rendering string from models
export const getPDCAStateText = (state: PDCAState, t: TFunction<string[]>): string => {
  switch (state) {
    case PDCAState.Plan:
      return t('translation:PDCAState.Plan');
    case PDCAState.Do:
      return t('translation:PDCAState.Do');
    case PDCAState.Check:
      return t('translation:PDCAState.Check');
    case PDCAState.Act:
      return t('translation:PDCAState.Act');
  }

  return '';
};

export const getPDCAStateTextList = (t: TFunction<string[]>): IDropdownOption<PDCAState>[] => {
  return [
    {
      key: PDCAState.Plan,
      text: t('translation:PDCAState.Plan'),
    },
    {
      key: PDCAState.Do,
      text: t('translation:PDCAState.Do'),
    },
    {
      key: PDCAState.Check,
      text: t('translation:PDCAState.Check'),
    },
    {
      key: PDCAState.Act,
      text: t('translation:PDCAState.Act'),
    },
  ];
};

//
// Entity helpers
//
export const getEntityName = (entity: Entity, t: TFunction<string[]>) => {
  let type: string = '';
  switch (entity.typeOfEntity) {
    case EntityTypes.Control:
      type = t('translation:General.EntityType.Control');
      break;
    case EntityTypes.Task:
      type = t('translation:General.EntityType.Task');
      break;
    case EntityTypes.Risk:
      type = t('translation:General.EntityType.Risk');
      break;
    case EntityTypes.Requirement:
      type = t('translation:General.EntityType.Requirement');
      break;
    case EntityTypes.Dashboard:
      type = t('translation:General.EntityType.Dashboard');
      break;
    case EntityTypes.KPILink:
      type = t('translation:General.EntityType.KPILink');
      break;
    case EntityTypes.Link:
      type = t('translation:General.EntityType.Link');
      break;
    case EntityTypes.List:
      type = t('translation:General.EntityType.List');
      break;
    case EntityTypes.Objective:
      type = t('translation:General.EntityType.Objective');
      break;
    case EntityTypes.Process:
      type = t('translation:General.EntityType.Process');
      break;
    case EntityTypes.Package:
      type = t('translation:General.EntityType.Package');
      break;
    case EntityTypes.Standard:
      type = t('translation:General.EntityType.Standard');
      break;
    case EntityTypes.Tag:
      type = t('translation:General.EntityType.Tag');
      break;
    case EntityTypes.Widget:
      type = t('translation:General.EntityType.Widget');
      break;
    case EntityTypes.Asset:
      type = t('translation:General.EntityType.Asset');
      break;
    case EntityTypes.Classification:
      type = t('translation:General.EntityType.Classification');
      break;
    case EntityTypes.KPI:
      type = t('translation:General.EntityType.KPI');
      break;
    case EntityTypes.TaskType:
      type = t('translation:General.EntityType.TaskType');
      break;
    default:
      break;
  }

  return type;
};

export const getEntity = (
  input:
    | Control
    | Theme
    | Risk
    | Process
    | Objective
    | Task
    | Asset
    | KPI
    | Tag
    | ResourceLink
    | Norm
    | TaskType
    | ResourceList,
  data?: string,
): Entity => {
  if (input instanceof Control) {
    return new Entity(input.controlId, EntityTypes.Control, input.name, input.code, data);
  } else if (input instanceof Theme) {
    return new Entity(input.themeId, EntityTypes.Requirement, input.name, input.code, data);
  } else if (input instanceof Risk) {
    return new Entity(input.riskId, EntityTypes.Risk, input.name, input.code, data);
  } else if (input instanceof Process) {
    return new Entity(input.processId, EntityTypes.Process, input.name, input.code, data);
  } else if (input instanceof Objective) {
    return new Entity(input.objectiveId, EntityTypes.Objective, input.name, input.code, data);
  } else if (input instanceof Task) {
    return new Entity(input.taskId, EntityTypes.Task, input.name, data);
  } else if (input instanceof Asset) {
    return new Entity(input.assetId, EntityTypes.Asset, input.name, input.code, data);
  } else if (input instanceof KPI) {
    return new Entity(input.kpiId, EntityTypes.KPI, input.name, data);
  } else if (input instanceof Tag) {
    return new Entity(input.tagId, EntityTypes.Tag, input.value(), data);
  } else if (input instanceof ResourceLink) {
    return new Entity(input.linkId, EntityTypes.Link, input.linkName, data);
  } else if (input instanceof Norm) {
    return new Entity(input.normId, EntityTypes.Standard, input.name, data);
  } else if (input instanceof TaskType) {
    return new Entity(input.taskTypeId, EntityTypes.TaskType, input.name, data);
  } else if (input instanceof ResourceList) {
    return new Entity(input.listId, EntityTypes.List, input.name, data);
  }

  return new Entity();
};

//
// Role helpers
//
export const GetRoleName = (role: Role, t: TFunction<string[]>): string => {
  if (role.systemRoleId) return GetRoleTranslation(role.systemRoleId, t);

  return role.name;
};

export const GetRoleTranslation = (role: number, t: TFunction<string[]>): string => {
  switch (role) {
    case appRoles.User:
      return t(`translation:Roles.User`);
    case appRoles.Manager:
      return t(`translation:Roles.Manager`);
    case appRoles.Admin:
      return t(`translation:Roles.Admin`);
    case appRoles.Consultant:
      return t(`translation:Roles.Consultant`);
    case appRoles.LicenseManager:
      return t(`translation:Roles.LicenseManager`);
    case appRoles.OrgAdmin:
      return t(`translation:Roles.OrgAdmin`);
    case appRoles.TenantISOAdmin:
      return t(`translation:Roles.TenantISOAdmin`);
    case appRoles.TenantGlobalAdmin:
      return t(`translation:Roles.TenantGlobalAdmin`);
  }

  return t(`translation:Roles.Empty`);
};

export const getSystemRolesDisplayString = (roles: number[], t: TFunction<string[]>): string => {
  let rolestring = '';

  if (roles) {
    roles.sort((a, b) => sortOnNumber(a, b));
    
    for (let role of roles) {
      if (rolestring.length > 0) {
        rolestring += ', ';
      }
      rolestring += GetRoleTranslation(role, t);
    }
  }

  if (rolestring.length === 0) {
    rolestring = GetRoleTranslation(appRoles.User, t);
  }

  return rolestring;
};

export const getCustomRolesDisplayString = (roles: Role[]): string => {
  return roles
    .map((r) => r.name)
    .sort((a, b) => sortOnString(a, b))
    .join(', ');
};

//
// Subscription helpers
//
export const getSubscriptionTypeText = (type: SubscriptionTypes, t: TFunction<string[]>): string => {
  switch (type) {
    case SubscriptionTypes.Basic:
      return t('adminSubscription:Basic.Title');
    case SubscriptionTypes.Business:
      return t('adminSubscription:Business.Title');
    case SubscriptionTypes.Premium:
      return t('adminSubscription:Premium.Title');
    case SubscriptionTypes.Partner:
      return t('adminSubscription:Partner.Title');
    case SubscriptionTypes.Internal:
      return t('adminSubscription:Internal.Title');
    case SubscriptionTypes.Custom:
      return t('adminSubscription:Custom.Title');
    case SubscriptionTypes.Container:
      return t('adminSubscription:Container.Title');
  }
};

export const getSubscriptionStandardCount = async (appContext: IAppContext): Promise<number> => {
  try {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const allStandards = await apiGetNorms(true, false, accessToken);

    return allStandards.length;
  } catch (err) {
    throw err;
  }
};

export const getSubscriptionMaxStandards = (appContext: IAppContext): number => {
  return StripeSubscription.getMaxStandards(appContext.user.login.subscriptionType);
};

export const hasReachedMaxStandards = (appContext: IAppContext, count: number): boolean => {
  const max = getSubscriptionMaxStandards(appContext);
  if (max >= 0) {
    return count >= max;
  } else {
    return false;
  }
};
