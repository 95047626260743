import {
  fromApiDateTimeRequired,
  weekDays1,
  toApiDateRequired,
  getWeekIndex,
  getDateTimeDiffMinute,
} from 'utils/datetime';
import { weekDays0 } from 'utils/datetime';
import { areDifferent } from 'utils/array';
import { i18nBase } from 'services/Localization/i18n';
import { WeekIndex } from 'microsoft-graph';
import AppError from 'utils/appError';
import { capitalizeFirstLetter } from 'utils/string';

export const weekly = 'weekly';
export const absoluteMonthly = 'absoluteMonthly';
export const absoluteYearly = 'absoluteYearly';
export const relativeMonthly = 'relativeMonthly';
export const relativeYearly = 'relativeYearly';
export const absoluteYearCycle = 'absoluteYearCycle';
export const relativeYearCycle = 'relativeYearCycle';

// The absolute/relative year cycles are converted to absolute/relative yearly with IsInterval = false for the API
export type repeatIntervalType =
  | typeof weekly
  | typeof absoluteMonthly
  | typeof absoluteYearly
  | typeof relativeMonthly
  | typeof relativeYearly
  | typeof absoluteYearCycle
  | typeof relativeYearCycle;

export default class RecurringPattern {
  private _startDate: Date;

  get startDate() {
    return this._startDate;
  }

  set startDate(val: Date) {
    this._startDate = val;
    this.recalcPatternForNewStartDate(val);
  }

  repeat: number;

  repeatInterval: repeatIntervalType;

  daySelect: string[];

  yearSelect: number[];

  index: WeekIndex;

  isYearInterval: boolean;

  isActive: boolean;

  constructor() {
    this._startDate = new Date();
    this.repeat = 0;
    this.repeatInterval = weekly;
    this.daySelect = [];
    this.isActive = false;
    this.yearSelect = [];
    this.isYearInterval = false;
    this.index = 'first';
  }

  recalcPatternForNewStartDate = (newStart: Date) => {
    //for a new start date, the index property may have to be recalculated
    const newWeekIndex = getWeekIndex(newStart);
    this.index = newWeekIndex;
    //for a new start date, the daySelect property have to be recalculated
    if (this.repeatInterval !== 'weekly') {
      this.daySelect = [weekDays0[newStart.getDay()]];
    }
  };

  initRecurringPatternWeek = () => {
    // initialize the Recurrence pattern to start on the current date and repeat every 1 week on the current day
    this._startDate = new Date();
    this.repeat = 1;
    this.repeatInterval = weekly;
    this.daySelect = [weekDays0[this.startDate.getDay()]];
    this.isActive = true;
  };

  initRecurringPatternMonth = (repeat: number) => {
    // initialize the Recurrence pattern to start on the current date and repeat every 1 week on the current day
    this._startDate = new Date();
    this.repeat = repeat;
    this.repeatInterval = absoluteMonthly;
    this.isActive = true;
  };

  initRecurringPatternYearCycle = () => {
    // initialize the Recurrence pattern to start on the current date and repeat every 1 week on the current day
    this._startDate = new Date();
    this.repeat = 3;
    this.repeatInterval = absoluteYearCycle;
    this.yearSelect = [1, 1, 1];
    this.isYearInterval = false;
    this.isActive = true;
  };

  initRecurringPatternYearInterval = () => {
    // initialize the Recurrence pattern to start on the current date and repeat every 1 week on the current day
    this._startDate = new Date();
    this.repeat = 3;
    this.repeatInterval = absoluteYearly;
    this.yearSelect = [];
    this.isYearInterval = true;
    this.isActive = true;
  };

  setYearSelect = (year: number, enabled: boolean) => {
    if (!this.yearSelect || this.yearSelect.length < 3) {
      this.yearSelect = [0, 0, 0];
    }
    if (year < 0 || year > 2) year = 0;
    this.yearSelect[year] = enabled === true ? 1 : 0;
  };

  setRelative = (enabled: boolean, index: WeekIndex) => {
    if (this.repeatInterval === 'weekly') throw new AppError('Cannot set relative on weekly type');
    if (enabled) {
      this.index = index;
      if (this.repeatInterval === 'absoluteMonthly') {
        this.repeatInterval = 'relativeMonthly';
      } else if (this.repeatInterval === 'absoluteYearly') {
        this.repeatInterval = 'relativeYearly';
      } else if (this.repeatInterval === 'absoluteYearCycle') {
        this.repeatInterval = 'relativeYearCycle';
      }
    } else {
      if (this.repeatInterval === 'relativeMonthly') {
        this.repeatInterval = 'absoluteMonthly';
      } else if (this.repeatInterval === 'relativeYearly') {
        this.repeatInterval = 'absoluteYearly';
      } else if (this.repeatInterval === 'relativeYearCycle') {
        this.repeatInterval = 'absoluteYearCycle';
      }
    }
  };

  isEqual(item: RecurringPattern) {
    //check date changes, ignore time changes
    const oldStartDate = new Date(item.startDate);
    oldStartDate.setHours(0, 0, 0, 0);
    const newStartDate = new Date(this.startDate);
    newStartDate.setHours(0, 0, 0, 0);
    if (getDateTimeDiffMinute(oldStartDate, newStartDate) !== 0) return false;

    if (item.repeat !== this.repeat) return false;
    if (item.repeatInterval !== this.repeatInterval) return false;
    if (item.isActive !== this.isActive) return false;
    if (item.isYearInterval !== this.isYearInterval) return false;

    if (
      areDifferent(item.daySelect, this.daySelect, (a: string, b: string) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    if (item.yearSelect.length !== this.yearSelect.length) {
      return false;
    }

    if (item.yearSelect[0] !== this.yearSelect[0]) return false;
    if (item.yearSelect[1] !== this.yearSelect[1]) return false;
    if (item.yearSelect[2] !== this.yearSelect[2]) return false;

    return true;
  }

  private getApiPatternType() {
    if (this.repeatInterval === absoluteYearCycle) {
      return absoluteYearly;
    } else if (this.repeatInterval === relativeYearCycle) {
      return relativeYearly;
    }

    return this.repeatInterval;
  }

  toJSON(): string | undefined {
    if (this.isActive === false) {
      return '';
    }

    const recurrencePattern = {
      pattern: {
        type: this.getApiPatternType(),
        interval: this.repeat,
        daysOfWeek: this.daySelect,
        index: this.index,
        years: this.yearSelect,
        isInterval:
          this.repeatInterval === absoluteYearCycle || this.repeatInterval === relativeYearCycle ? false : true,
      },
      range: {
        startDate: toApiDateRequired(this.startDate),
        type: 'noEnd',
      },
    };

    return JSON.stringify(recurrencePattern);
  }

  fromJSON(patternString: string | undefined, newStartDate?: Date) {
    // when the newStartDate is specified, it will be set as the start of the recurrence pattern
    // the start of the recurrence pattern must be synced with the start date of the task
    try {
      if (patternString && patternString !== '') {
        const patternObject = JSON.parse(patternString);
        this.repeatInterval = patternObject.pattern.type;
        this.repeat = patternObject.pattern.interval;
        this.daySelect = patternObject.pattern.daysOfWeek ?? [];
        this.yearSelect = patternObject.pattern.years ?? [];
        this.isYearInterval = patternObject.pattern.isInterval ?? false;
        this.index = patternObject.pattern.index;
        this._startDate = newStartDate ?? fromApiDateTimeRequired(patternObject.range.startDate as string);
        this.isActive = true;

        //convert yearly interval patterns to cycles based on the isYearInterval
        if (
          (this.repeatInterval === absoluteYearly || this.repeatInterval === relativeYearly) &&
          this.isYearInterval === false
        ) {
          this.repeatInterval = this.repeatInterval === absoluteYearly ? absoluteYearCycle : relativeYearCycle;
          this.repeat = 3;
          if (this.yearSelect.length !== 3) {
            this.yearSelect = [1, 1, 1];
          }
        }
      } else {
        this.isActive = false;
      }
    } catch (err) {
      this.isActive = false;
    }
  }

  clone(): RecurringPattern {
    const newRecurringPattern = new RecurringPattern();
    newRecurringPattern._startDate = new Date(this.startDate);
    newRecurringPattern.repeat = this.repeat;
    newRecurringPattern.repeatInterval = this.repeatInterval;
    newRecurringPattern.daySelect = [...this.daySelect];
    newRecurringPattern.yearSelect = [...this.yearSelect];
    newRecurringPattern.isActive = this.isActive;
    newRecurringPattern.isYearInterval = this.isYearInterval;
    newRecurringPattern.index = this.index;

    return newRecurringPattern;
  }

  isRelative(): boolean {
    return (
      this.repeatInterval === 'relativeMonthly' ||
      this.repeatInterval === 'relativeYearly' ||
      this.repeatInterval === 'relativeYearCycle'
    );
  }

  getSummary(): string {
    if (this.isActive) {
      let repeatString;
      if (this.repeat === 1) {
        if (this.repeatInterval === weekly) {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Week');
        } else if (this.repeatInterval === absoluteMonthly || this.repeatInterval === relativeMonthly) {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Month');
        } else {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Year');
        }
      } else {
        if (this.repeatInterval === weekly) {
          repeatString = `${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Weeks')}`;
        } else if (this.repeatInterval === absoluteMonthly || this.repeatInterval === relativeMonthly) {
          repeatString = `${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Months')}`;
        } else {
          repeatString = `${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Years')}`;
        }
      }

      let from = '';
      if (this.repeat === 1 && (this.repeatInterval === absoluteYearly || this.repeatInterval === relativeYearly)) {
        from = i18nBase.t('control:TabDetails.Fields.Recurring.FromSingleYear');
      } else {
        from = i18nBase.t('control:TabDetails.Fields.Recurring.From');
      }

      const wordOn = i18nBase.t('control:TabDetails.Fields.Recurring.On');
      const wordIn = i18nBase.t('translation:General.Words.In');
      let summary: string = '';

      if (this.repeatInterval === weekly) {
        const days = this.getSortedDays(this.daySelect);
        summary = `${from} ${repeatString} ${wordOn} ${this.getWeekDaysString(days)}`;
      } else {
        const occurrence = this.getOccurenceString(this.index);
        const relStr = this.getAbsRelDateString(this, occurrence, this.isRelative());
        summary = `${from} ${repeatString} ${relStr}`;
      }

      if (this.repeatInterval === absoluteYearCycle || this.repeatInterval === relativeYearCycle) {
        const years = this.getYearString(this.yearSelect);
        summary += ` ${wordIn} ${i18nBase.t('control:TabDetails.Fields.Recurring.Year')} ${years}`;
      }

      return summary;
    } else {
      return '';
    }
  }

  getShortSummary(): string {
    if (this.isActive) {
      let repeatString;
      let every = i18nBase.t('control:TabDetails.Fields.Recurring.Every');;

      if (this.repeat === 1) {
        if (this.repeatInterval === weekly) {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Week');
        } else if (this.repeatInterval === absoluteMonthly || this.repeatInterval === relativeMonthly) {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Month');
        } else {
          repeatString = i18nBase.t('control:TabDetails.Fields.Recurring.Year');
          every = i18nBase.t('control:TabDetails.Fields.Recurring.EverySingleYear');
        }
        repeatString = `${every} ${repeatString}`;
      } else {
        if (this.repeatInterval === weekly) {
          repeatString = `${every} ${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Weeks')}`;
        } else if (this.repeatInterval === absoluteMonthly || this.repeatInterval === relativeMonthly) {
          repeatString = `${every} ${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Months')}`;
        } else if (this.repeatInterval === 'absoluteYearCycle' || this.repeatInterval === 'relativeYearCycle') {
          const years = this.getYearString(this.yearSelect);
          const wordIn = capitalizeFirstLetter(i18nBase.t('translation:General.Words.In'));
          repeatString = ` ${wordIn} ${i18nBase.t('control:TabDetails.Fields.Recurring.Year')} ${years}`;
        } else {
          repeatString = `${every} ${this.repeat} ${i18nBase.t('control:TabDetails.Fields.Recurring.Years')}`;
        }
      }

      return repeatString;
    } else {
      return '';
    }
  }

  getSortedDays(days: string[]): string[] {
    const sortedDays: string[] = [];

    for (let day of weekDays1) {
      if (days.includes(day)) {
        if (day === 'Monday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Monday'));
        if (day === 'Tuesday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Tuesday'));
        if (day === 'Wednesday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Wednesday'));
        if (day === 'Thursday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Thursday'));
        if (day === 'Friday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Friday'));
        if (day === 'Saturday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Saturday'));
        if (day === 'Sunday') sortedDays.push(i18nBase.t('dateTimeComponent:WeekDaysShort.Sunday'));
      }
    }

    return sortedDays;
  }

  getYearString(years: number[]): string {
    if (!years || years.length < 3) return '';
    let str = '';
    if (years[0] === 1) str += '1, ';
    if (years[1] === 1) str += '2, ';
    if (years[2] === 1) str += '3';

    if (str.endsWith(', ')) str = str.substring(0, str.length - 2);

    return str;
  }

  getMonthStringShort(month: number): string {
    switch (month + 1) {
      case 1:
        return i18nBase.t('dateTimeComponent:MonthsShort.Jan');
      case 2:
        return i18nBase.t('dateTimeComponent:MonthsShort.Feb');
      case 3:
        return i18nBase.t('dateTimeComponent:MonthsShort.Mar');
      case 4:
        return i18nBase.t('dateTimeComponent:MonthsShort.Apr');
      case 5:
        return i18nBase.t('dateTimeComponent:MonthsShort.May');
      case 6:
        return i18nBase.t('dateTimeComponent:MonthsShort.Jun');
      case 7:
        return i18nBase.t('dateTimeComponent:MonthsShort.Jul');
      case 8:
        return i18nBase.t('dateTimeComponent:MonthsShort.Aug');
      case 9:
        return i18nBase.t('dateTimeComponent:MonthsShort.Sep');
      case 10:
        return i18nBase.t('dateTimeComponent:MonthsShort.Oct');
      case 11:
        return i18nBase.t('dateTimeComponent:MonthsShort.Nov');
      case 12:
        return i18nBase.t('dateTimeComponent:MonthsShort.Dec');
      default:
        return '';
    }
  }

  getMonthString(month: number): string {
    switch (month + 1) {
      case 1:
        return i18nBase.t('dateTimeComponent:Months.January');
      case 2:
        return i18nBase.t('dateTimeComponent:Months.February');
      case 3:
        return i18nBase.t('dateTimeComponent:Months.March');
      case 4:
        return i18nBase.t('dateTimeComponent:Months.April');
      case 5:
        return i18nBase.t('dateTimeComponent:Months.May');
      case 6:
        return i18nBase.t('dateTimeComponent:Months.June');
      case 7:
        return i18nBase.t('dateTimeComponent:Months.July');
      case 8:
        return i18nBase.t('dateTimeComponent:Months.August');
      case 9:
        return i18nBase.t('dateTimeComponent:Months.September');
      case 10:
        return i18nBase.t('dateTimeComponent:Months.October');
      case 11:
        return i18nBase.t('dateTimeComponent:Months.November');
      case 12:
        return i18nBase.t('dateTimeComponent:Months.December');
      default:
        return '';
    }
  }

  getDayString(day: number): string {
    switch (day) {
      case 0:
        return i18nBase.t('dateTimeComponent:WeekDays.Sunday');
      case 1:
        return i18nBase.t('dateTimeComponent:WeekDays.Monday');
      case 2:
        return i18nBase.t('dateTimeComponent:WeekDays.Tuesday');
      case 3:
        return i18nBase.t('dateTimeComponent:WeekDays.Wednesday');
      case 4:
        return i18nBase.t('dateTimeComponent:WeekDays.Thursday');
      case 5:
        return i18nBase.t('dateTimeComponent:WeekDays.Friday');
      case 6:
        return i18nBase.t('dateTimeComponent:WeekDays.Saturday');
      default:
        return '';
    }
  }

  getWeekDaysString(days: string[]): string {
    let dayString: string = '';

    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      dayString += day;
      if (days.length >= 2 && i === days.length - 2) {
        dayString += i18nBase.t(`control:TabDetails.Fields.Recurring.And`);
      } else if (i < days.length - 1) {
        dayString += ', ';
      }
    }

    return dayString;
  }

  getOccurenceString(weekIndex: WeekIndex): string {
    let occurenceText: string = '';
    switch (weekIndex) {
      case 'first':
        occurenceText = i18nBase.t('control:TabDetails.Fields.Recurring.Occurences.First');
        break;
      case 'second':
        occurenceText = i18nBase.t('control:TabDetails.Fields.Recurring.Occurences.Second');
        break;
      case 'third':
        occurenceText = i18nBase.t('control:TabDetails.Fields.Recurring.Occurences.Third');
        break;
      case 'fourth':
        occurenceText = i18nBase.t('control:TabDetails.Fields.Recurring.Occurences.Fourth');
        break;
      case 'last':
        occurenceText = i18nBase.t('control:TabDetails.Fields.Recurring.Occurences.Last');
        break;
    }

    return occurenceText;
  }

  getAbsRelDateString(recurring: RecurringPattern, occurenceText: string | undefined, relative: boolean): string {
    let optionText: string = '';

    if (relative) {
      if (recurring.repeatInterval === 'absoluteMonthly' || recurring.repeatInterval === 'relativeMonthly') {
        optionText = i18nBase.t('control:TabDetails.Fields.Recurring.RelOptionMonth', {
          occurence: occurenceText,
          day: this.getDayString(recurring.startDate.getDay()),
        });
      } else if (
        recurring.repeatInterval === 'relativeYearly' ||
        recurring.repeatInterval === 'absoluteYearly' ||
        recurring.repeatInterval === 'absoluteYearCycle' ||
        recurring.repeatInterval === 'relativeYearCycle'
      ) {
        optionText = i18nBase.t('control:TabDetails.Fields.Recurring.RelOptionYear', {
          occurence: occurenceText,
          day: this.getDayString(recurring.startDate.getDay()),
          month: this.getMonthString(recurring.startDate.getMonth()),
        });
      }
    } else {
      if (recurring.repeatInterval === 'absoluteMonthly' || recurring.repeatInterval === 'relativeMonthly') {
        optionText = i18nBase.t('control:TabDetails.Fields.Recurring.AbsOptionMonth', {
          day: recurring.startDate.getDate(),
        });
      } else if (
        recurring.repeatInterval === 'relativeYearly' ||
        recurring.repeatInterval === 'absoluteYearly' ||
        recurring.repeatInterval === 'absoluteYearCycle' ||
        recurring.repeatInterval === 'relativeYearCycle'
      ) {
        optionText = i18nBase.t('control:TabDetails.Fields.Recurring.AbsOptionYear', {
          day: recurring.startDate.getDate(),
          month: recurring.getMonthString(recurring.startDate.getMonth()),
        });
      }
    }

    return optionText;
  }
}
