import React, { useEffect, useState } from 'react';
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react';

interface ITimePicker {
  selectedTime: Date;
  minTime?: number | Date; //in minutes 12:05 = 12*60+ 5 -> 725, must be >=0 and < 1440 | time part of Date
  updateTime: (hours: number, mins: number) => void;
  label?: string;
  error?: string | undefined;
  disabled?: boolean;
  pickerWidth?: number;
}

const getMinuteFromDate = (date: Date | undefined): number | undefined => {
  if (!date) return undefined;

  return new Date(date).getHours() * 60 + new Date(date).getMinutes();
};

const minuteToTime = (min: number): string => {
  let hours = Math.floor(min / 60);
  let mins = min - hours * 60;

  return `${hours < 10 ? '0' + hours.toString() : hours.toString()}:${
    mins < 10 ? '0' + mins.toString() : mins.toString()
  }`;
};

const validateInput = (text: string) => {
  if (!text || text.length !== 5) return false;
  if (text[2] !== ':') return false;
  let [hours, mins] = text.split(':');
  if (Number.isNaN(+hours)) return false;
  if (Number.isNaN(+mins)) return false;
  if (+hours > 23 || +hours < 0) return false;
  if (+mins > 59 || +mins < 0) return false;

  return true;
};

const initialTimeOptions: IComboBoxOption[] = Array.from({ length: 48 }, (_, i) => {
  return {
    key: i * 30,
    text: minuteToTime(i * 30),
  };
});

const getMinTimeInMinutes = (min: number | Date | undefined): number => {
  if (!min) return 0;
  if (typeof min === 'number' && (min <= 0 || min >= 1440)) return 0;
  if (typeof min === 'number') return min;

  return min.getHours() * 60 + min.getMinutes();
};

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { width: 80 } };

export const TimePicker = (props: ITimePicker) => {
  const [timeOptions, setTimeOptions] = useState<IComboBoxOption[]>(initialTimeOptions);
  const [comboxStyles, setComboxStyles] = useState<Partial<IComboBoxStyles>>(comboBoxStyles);
  const [selectedKey, setSelectedKey] = useState<number | undefined>(undefined);

  useEffect(() => {
    const min = getMinuteFromDate(props.selectedTime);
    if (!min) return;

    const existingItem = timeOptions.find((itm) => {
      return itm.key === min;
    });

    if (existingItem) {
      setSelectedKey(existingItem.key as number);
    } else {
      //add custom time to combo as hidden
      setTimeOptions([
        ...timeOptions,
        {
          key: min,
          text: minuteToTime(min),
          hidden: min % 30 !== 0,
        },
      ]);

      setSelectedKey(min);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTime]);

  useEffect(() => {
    const calculatedWidth = props.pickerWidth ? props.pickerWidth : 100;
    const updatedBoxStyles: Partial<IComboBoxStyles> = { root: { width: calculatedWidth } };
    setComboxStyles(updatedBoxStyles);
  }, [props.pickerWidth]);

  return (
    <ComboBox
      persistMenu
      allowFreeform
      useComboBoxAsMenuWidth
      selectedKey={selectedKey}
      errorMessage={props.error}
      disabled={props.disabled}
      styles={comboxStyles}
      calloutProps={{ calloutMaxHeight: 250 }}
      options={timeOptions}
      label={props.label}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        let key: number | undefined = undefined;
        if (!option) {
          if (!value) return;

          //add : if not present
          if (value.length === 4 && !value.includes(':')) {
            value = value.substring(0, 2) + ':' + value.substring(2, 4);
          }
          if (!validateInput(value)) return;

          let [hourString, minString] = value.split(':');
          key = +hourString * 60 + +minString;
        }
        if (key === undefined && option) key = +option.key;
        if (key === undefined) return;

        if (props.minTime) {
          if (key > getMinTimeInMinutes(props.minTime)) {
            key = getMinTimeInMinutes(props.minTime);
          }
          let hours = Math.floor(key / 60);
          let mins = key - hours * 60;
          props.updateTime(hours, mins);
        } else {
          let hours = Math.floor(key / 60);
          let mins = key - hours * 60;
          props.updateTime(hours, mins);
        }
      }}
    />
  );
};

export default TimePicker;
