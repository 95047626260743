import { Suspense, lazy, useContext } from 'react';
import AppContext from './AppContext';
import { darkTheme, lightTheme } from 'globalThemes';
import { ScrollablePane, ScrollbarVisibility, Stack, ThemeProvider } from '@fluentui/react';
import ContentLoader from 'components/Loading/ContentLoader';
import SceneLoader from 'components/Loading/SceneLoader';
import { ErrorMessage } from 'components/Notification/ErrorMessage';
import { globalToastDelay } from 'globalConstants';
import { ToastContainer } from 'react-toastify';
import AppContentAreaTopLayerHost from './AppContentAreaTopLayerHost';
import OutlookSingleTask from 'scenes/Task/OutlookSingleTask';

//
// Lazy load all scenes (React module splitting)
//
const FirstRun = lazy(() => import('scenes/Enroll/FirstRun'));
const Welcome = lazy(() => import('scenes/Enroll/Welcome'));

const AppRouting = () => {
  const appContext = useContext(AppContext);

  return (
    <ThemeProvider applyTo="body" theme={appContext.useDarkMode ? darkTheme : lightTheme}>
      <AppContext.Provider value={appContext}>
        <Stack verticalFill styles={{ root: { height: '100vh' } }}>
          {/* Main App */}

          <Stack.Item>
            {/* Notification area */}
            <AppContentAreaTopLayerHost />
            <ContentLoader />
            <ErrorMessage error={appContext.error} setError={appContext.setError} />
            <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="app" />
          </Stack.Item>

          <Stack.Item grow styles={{ root: { position: 'relative' } }}>
            {/* Scene area */}
            <Suspense fallback={<SceneLoader />}>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                {!appContext.isReady() && <SceneLoader />}

                {appContext.isReady() &&
                  appContext.isAuthenticated &&
                  appContext.firstRun === false &&
                  appContext.user.login.lastLogin === undefined && (
                    <FirstRun
                      onStart={() => {
                        appContext.setState({ firstRun: true });
                      }}
                    />
                  )}

                {appContext.isReady() &&
                  appContext.isAuthenticated &&
                  !(appContext.firstRun === false && appContext.user.login.lastLogin === undefined) && (
                    <OutlookSingleTask logout={appContext.logout}></OutlookSingleTask>
                  )}
                {appContext.isReady() && !appContext.isAuthenticated && <Welcome login={appContext.login}></Welcome>}
              </ScrollablePane>
            </Suspense>
          </Stack.Item>
        </Stack>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default AppRouting;
