import IdListDTO from './IdListDTO';
import EntityDTO from './entityDTO';
import ResourceLinkChannelDTO from './resourceLinkChannelDTO';
import TaskDTO from 'models/dto/tasks/taskDTO';

export default class ResourceLinkDTO {
  linkId: number;

  listId: number;

  ownerId?: string;

  ownerRoleId?: string;

  linkName: string;

  linkURL: string;

  linkURLFragment: string;

  pinned: boolean;

  usedCount: number;

  entity?: EntityDTO;

  listItemId?: string;

  driveItemId?: string;

  pageId?: string;

  resourceLinkChannels?: ResourceLinkChannelDTO[];

  normIds?: IdListDTO;

  approvals?: TaskDTO[];

  versions: ResourceLinkVersionDTO[];

  constructor() {
    this.linkId = -1;
    this.linkName = '';
    this.linkURL = '';
    this.linkURLFragment = '';
    this.usedCount = 0;
    this.listId = 0;
    this.pinned = false;
    this.versions = [];
  }
}

export class EntityLinkDTO extends EntityDTO {
  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    super();
    this.pinned = false;
  }
}

export class ResourceLinkVersionDTO {
  id: string;

  date: string;

  version: string;

  constructor() {
    this.id = '';
    this.date = '';
    this.version = '';
  }
}

export class ResourceLinkSearchDTO {
  groupId: string;

  channelId: string;

  searchText: string;

  webURLs?: string[];

  constructor() {
    this.groupId = '';
    this.channelId = '';
    this.searchText = '';
  }
}

export class ResourceLinkConversionDataDTO {
  linkId: number;

  data: string | undefined;

  constructor() {
    this.linkId = 0;
  }
}