import { areDifferent } from 'utils/array';
import KPIDataContext from './kpiDataContext';
import KPI, { KPITypes } from './kpi';
import { newGuidNil } from 'utils/guid';
import { getDateTimeDiff, toLocaleDateShort } from 'utils/datetime';

export default class KPIData {
  kpiId: number;

  kpiDataId: number;

  created: Date;

  createdBy: string;

  createdById: string;

  modified?: Date;

  modifiedBy?: string;

  modifiedById?: string;

  resultDate?: Date;

  resultNumber?: number;

  resultText?: string;

  resultComment?: string;

  resultEvidence?: string;

  contexts: KPIDataContext[];

  kpi?: KPI;

  constructor() {
    this.kpiId = 0;
    this.kpiDataId = 0;
    this.created = new Date();
    this.createdBy = '';
    this.createdById = newGuidNil();
    this.contexts = [];
  }

  hasValue(): boolean {
    return this.getValue() !== undefined;
  }

  getValue(): string | undefined {
    if (this.kpi) {
      switch (this.kpi.type) {
        case KPITypes.Choice:
          return `${this.resultNumber} - ${this.resultText}`
        case KPITypes.Number:
        case KPITypes.SuccesError:
        case KPITypes.Expression:
          return this.resultNumber?.toFixed(this.kpi.decimalCount);
        case KPITypes.TextMultiline:
        case KPITypes.Html:
        case KPITypes.Text:
          return this.resultText;
        case KPITypes.Date:
          return this.resultDate ? toLocaleDateShort(this.resultDate) : undefined;
        default:
          return undefined;
      }
    }
  }

  clone(): KPIData {
    const output = new KPIData();
    output.kpiId = this.kpiId;
    output.kpiDataId = this.kpiDataId;
    output.created = new Date(this.created);
    output.createdBy = this.createdBy;
    output.createdById = this.createdById;
    output.modified = this.modified ? new Date(this.modified) : undefined;
    output.modifiedBy = this.modifiedBy;
    output.modifiedById = this.modifiedById;
    output.resultDate = this.resultDate ? new Date(this.resultDate) : undefined;
    output.resultNumber = this.resultNumber;
    output.resultText = this.resultText;
    output.resultComment = this.resultComment;
    output.resultEvidence = this.resultEvidence;
    output.contexts = this.contexts.map((c) => c.clone());
    output.kpi = this.kpi; //not not clone because this would cause a loopt. this must be a ref to the kpi that this data belongs to

    return output;
  }

  isEqual(item: KPIData): boolean {
    if (item.kpiId !== this.kpiId) return false;
    if (item.kpiDataId !== this.kpiDataId) return false;
    if (getDateTimeDiff(item.resultDate, this.resultDate)) return false;
    if (item.resultNumber !== this.resultNumber) return false;
    if (item.resultText !== this.resultText) return false;
    if (getDateTimeDiff(item.created, this.created)) return false;
    if (item.createdBy !== this.createdBy) return false;
    if (item.createdById !== this.createdById) return false;
    if (getDateTimeDiff(item.modified, this.modified)) return false;
    if (item.modifiedBy !== this.modifiedBy) return false;
    if (item.modifiedById !== this.modifiedById) return false;
    if (item.resultComment !== this.resultComment) return false;
    if (item.resultEvidence !== this.resultEvidence) return false;

    if (
      areDifferent(item.contexts, this.contexts, (a: KPIDataContext, b: KPIDataContext) => {
        return a.isEqual(b);
      }) === true
    ) {
      return false;
    }

    return true;
  }
}
