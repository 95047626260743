import { AuthSchema_Translation } from './authSchema_Translation';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { AuthSchemaLine, AuthSchemaLineOperation } from './authSchemaLine';
import { areDifferent } from 'utils/array';
import User from 'models/user';
import Group from 'models/group';
import { Role } from './role';
import { IAppContext } from 'App/AppContext';

export interface IAuthObject {
  authSchemaId?: number | undefined;
}

export class AuthSchema {
  authSchemaId: number;

  auditTrailId: number;

  active: boolean;

  trans: AuthSchema_Translation[];

  lines: AuthSchemaLine[];

  // The following properties are set from the translation arrays
  transIdx: number;

  name: string;

  description?: string;

  constructor() {
    this.authSchemaId = 0;
    this.auditTrailId = 0;
    this.trans = [];
    this.transIdx = -1;
    this.name = '';
    this.lines = [];
    this.active = true;
  }

  static getEmptyAuthSchema() {
    return new AuthSchema();
  }

  clone(): AuthSchema {
    const output = new AuthSchema();
    output.authSchemaId = this.authSchemaId;
    output.active = this.active;
    output.name = this.name;
    output.description = this.description;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];
    output.lines = this.lines.map((l) => l.clone());

    return output;
  }

  isEqual(item: AuthSchema): boolean {
    if (item.authSchemaId !== this.authSchemaId) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.active !== this.active) return false;

    if (
      areDifferent(item.lines, this.lines, (a: AuthSchemaLine, b: AuthSchemaLine) => {
        return a.isEqual(b);
      }) === true
    ) {
      return false;
    }

    return true;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(64).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
      },
      { abortEarly: false },
    );
  }

  hasLine(userId: string, operation?: AuthSchemaLineOperation): boolean {
    switch (operation) {
      case AuthSchemaLineOperation.Update:
        return this.lines.some((l) => l.userId === userId && l.opUpdate === true);
      case AuthSchemaLineOperation.Delete:
        return this.lines.some((l) => l.userId === userId && l.opDelete === true);
      default:
        return this.lines.some((l) => l.userId === userId);
    }
  }

  hasLines(): boolean {
    return this.lines.some((l) => l.userId || l.groupId || l.roleId);
  }

  getUsers(): string[] {
    //return the unique users in this schema
    return [...new Set(this.lines.filter((l) => l.userId).map((l) => l.userId) as string[])];
  }

  getMembers(appContext: IAppContext): (User | Group | Role)[] {
    const members: (User | Group | Role)[] = [];
    this.lines.forEach((line) => {
      if (line.userId) members.push(appContext.globalDataCache.users.get(line.userId));
      else if (line.groupId) members.push(appContext.globalDataCache.groups.get(line.groupId));
      else if (line.roleId) members.push(appContext.globalDataCache.roles.get(line.roleId));
    });

    return members;
  }
}
