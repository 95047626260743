import CurrentUser from 'models/currentuser';
import PartnerDTO from 'models/dto/partnerDTO';
import UserDTO from 'models/dto/userDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import Partner from 'models/partner';
import User from 'models/user';
import { fromApiDateTimeOptional } from 'utils/datetime';

export function mapFromUsers(userDTOs: UserDTO[], cache: GlobalDataCache | undefined): User[] {
  let userList: User[] = [];

  for (let userDTO of userDTOs) {
    const newUser = mapFromUser(userDTO, cache);
    if (newUser) userList.push(newUser);
  }

  return userList;
}

export function mapFromUser(userDTO: UserDTO | undefined, cache: GlobalDataCache | undefined): User | undefined {
  if (userDTO) {
    const newUser = new User(userDTO.userId, userDTO.email, userDTO.name);
    newUser.created = fromApiDateTimeOptional(userDTO.created);
    newUser.hasLicense = userDTO.hasLicense;
    newUser.lastLogin = fromApiDateTimeOptional(userDTO.lastLogin);
    newUser.roles = cache ? userDTO.roles?.map((id) => cache.roles.get(id)) : [];
    newUser.groups = userDTO.groups;
    newUser.userTimeZoneCode = userDTO.userTimeZoneCode;

    return newUser;
  }

  return undefined;
}

export function mapToUser(user: User): UserDTO {
  let userDTO = new UserDTO();

  userDTO.userId = user.id;
  userDTO.name = user.name;
  userDTO.email = user.email;
  userDTO.hasLicense = user.hasLicense;
  userDTO.userTimeZoneCode = user.userTimeZoneCode;

  if (user instanceof CurrentUser) {
    userDTO.userLanguageCode = (user as CurrentUser).login.userLanguageCode;
  }

  return userDTO;
}

export function mapFromPartners(partnerDTOs: PartnerDTO[]): Partner[] {
  let partnerList: Partner[] = [];

  for (let partnerDTO of partnerDTOs) {
    const newPartner = mapFromPartner(partnerDTO);
    partnerList.push(newPartner);
  }

  return partnerList;
}

export function mapFromPartner(partnerDTO: PartnerDTO): Partner {
  const newPartner = new Partner();
  newPartner.tenantId = partnerDTO.tenantId;
  newPartner.tenantName = partnerDTO.tenantName;
  newPartner.userId = partnerDTO.userId;
  newPartner.name = partnerDTO.name;
  newPartner.email = partnerDTO.email;

  return newPartner;
}
