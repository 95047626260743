import { nlErrors, enErrors, deErrors } from './joiCustomErrors';
import { i18nBase } from './i18n';
import Joi from 'joi';

const nlMessages: Joi.LanguageMessages = { ...nlErrors };
const enMessages: Joi.LanguageMessages = { ...enErrors };
const deMessages: Joi.LanguageMessages = { ...deErrors };

export const getLocalizedMessageOptions = (): Joi.ValidationOptions => {
  const langCode = i18nBase.language.substring(0, 2);
  switch (langCode) {
    case 'nl':
      return { messages: nlMessages };
    case 'de':
      return { messages: deMessages };
    default:
      return { messages: enMessages };
  }
};
