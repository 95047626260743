import TagDTO, { ThemeTagDTO } from 'models/dto/tagDTO';
import Tag from 'models/tag';
import { mapFromTag, mapFromTags, mapFromEntities } from 'mappings/dataMapping';
import http from './httpService';
import AppError from 'utils/appError';
import { TaskTagDTO } from 'models/dto/tagDTO';
import { ControlTagDTO } from 'models/dto/tagDTO';
import { RiskTagDTO } from 'models/dto/tagDTO';
import Entity from 'models/entity';
import EntityDTO from 'models/dto/entityDTO';
import { ObjectiveTagDTO } from 'models/dto/Objective/objectiveTagDTO';
import { ProcessTagDTO } from 'models/dto/Process/processTagDTO';

export async function apiGetTags(accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
export async function apiCreateTag(tag: TagDTO, accessToken: string): Promise<Tag> {
  try {
    const res = await http.post<TagDTO>(`/tags`, tag, http.getRequestConfig(accessToken));

    return mapFromTag(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
export async function apiDeleteTag(tag: Tag, accessToken: string): Promise<void> {
  try {
    await http.post(`/tags/delete/${tag.tagId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateTag(tag: TagDTO, accessToken: string): Promise<Tag> {
  try {
    const res = await http.put<TagDTO>(`/tags`, tag, http.getRequestConfig(accessToken));

    return mapFromTag(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Task
export async function apiGetTagsForTask(taskId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/task/${taskId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToTask(tag: Tag, taskId: number, accessToken: string): Promise<void> {
  try {
    const body = new TaskTagDTO();
    body.tagId = tag.tagId;
    body.taskId = taskId;
    await http.post<TagDTO>(`/tags/task?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTagFromTask(tagId: number, taskId: number, accessToken: string): Promise<void> {
  try {
    const body = new TaskTagDTO();
    body.tagId = tagId;
    body.taskId = taskId;
    await http.post<TagDTO>(`/tags/task?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Theme
export async function apiGetTagsForTheme(themeId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/theme/${themeId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTagFromTheme(tag: Tag, themeId: number, accessToken: string): Promise<void> {
  try {
    const body = new ThemeTagDTO();
    body.tagId = tag.tagId;
    body.themeId = themeId;
    await http.post(`/tags/theme?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToTheme(tag: Tag, themeId: number, accessToken: string): Promise<void> {
  try {
    const body = new ThemeTagDTO();
    body.tagId = tag.tagId;
    body.themeId = themeId;
    await http.post<TagDTO>(`/tags/theme?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTagUsage(tagId: number, accessToken: string): Promise<Entity[]> {
  try {
    const res = await http.get<EntityDTO[]>(`/tags/${tagId}/usage`, http.getRequestConfig(accessToken));

    return mapFromEntities(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Risks
export async function apiGetTagsForRisk(riskId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/risk/${riskId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToRisk(tag: Tag, riskId: number, accessToken: string): Promise<void> {
  try {
    const body = new RiskTagDTO();
    body.tagId = tag.tagId;
    body.riskId = riskId;
    await http.post<TagDTO>(`/tags/risk?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTagFromRisk(tag: Tag, riskId: number, accessToken: string): Promise<void> {
  try {
    const body = new RiskTagDTO();
    body.tagId = tag.tagId;
    body.riskId = riskId;
    await http.post<TagDTO>(`/tags/risk?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Controls

export async function apiRemoveTagFromControl(tag: Tag, controlId: number, accessToken: string): Promise<void> {
  try {
    const body = new ControlTagDTO();
    body.tagId = tag.tagId;
    body.controlId = controlId;
    await http.post(`/tags/control?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToControl(tag: Tag, controlId: number, accessToken: string): Promise<void> {
  try {
    const body = new ControlTagDTO();
    body.tagId = tag.tagId;
    body.controlId = controlId;
    await http.post<TagDTO>(`/tags/control?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetTagsForControl(controlId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/control/${controlId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//Objectives
export async function apiGetTagsForObjective(objectiveId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/objective/${objectiveId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTagFromObjective(tag: Tag, objectiveId: number, accessToken: string): Promise<void> {
  try {
    const body = new ObjectiveTagDTO(tag.tagId, objectiveId);

    await http.post(`/tags/objective?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToObjective(tag: Tag, objectiveId: number, accessToken: string): Promise<void> {
  try {
    const body = new ObjectiveTagDTO(tag.tagId, objectiveId);
    await http.post<TagDTO>(`/tags/objective?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//process
export async function apiGetTagsForProcess(processId: number, accessToken: string): Promise<Tag[]> {
  try {
    const res = await http.get<TagDTO[]>(`/tags/process/${processId}`, http.getRequestConfig(accessToken));

    return mapFromTags(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveTagFromProcess(tag: Tag, processId: number, accessToken: string): Promise<void> {
  try {
    const body = new ProcessTagDTO(tag.tagId, processId);
    await http.post(`/tags/process?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddTagToProcess(tag: Tag, processId: number, accessToken: string): Promise<void> {
  try {
    const body = new ProcessTagDTO(tag.tagId, processId);
    await http.post<TagDTO>(`/tags/process?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
