export enum PermissionTypes {
  ModuleOrg = 1,
  ModuleRequirements = 2,
  ModuleAssets = 3,
  ModuleRisks = 4,
  ModuleControls = 5,
  ModuleAnnualPlan = 6,
  ModuleTasks = 7,
  ModuleLibrary = 8,
  ModuleStandards = 9,
  ModuleAdmin = 10,
  ModulePartner = 11,
  CreateProcess = 12,
  CreateObjective = 13,
  CreateKPI = 14,
  CreateForm = 15,
  CreateAsset = 16,
  CreateStandard = 17,
  CreateStandardElement = 18,
  CreateRisk = 19,
  CreateLibraryCategory = 20,
  CreateLibraryItem = 21,
  CreateAnnualPlanTask = 22,
  CreateTask = 23,
}
