import Language from './language';

export default class Risk_Translation {
  riskId: number;

  languageId: number;

  name: string;

  asset: string;

  description: string;

  analysis: string;

  strategy: string;

  lang?: Language;

  constructor() {
    this.riskId = -1;
    this.languageId = -1;
    this.name = '';
    this.asset = '';
    this.description = '';
    this.analysis = '';
    this.strategy = '';
  }

  clone(): Risk_Translation {
    const output = new Risk_Translation();
    output.riskId = this.riskId;
    output.languageId = this.languageId;
    output.name = this.name;
    output.asset = this.asset;
    output.description = this.description;
    output.analysis = this.analysis;
    output.strategy = this.strategy;
    output.lang = this.lang ? this.lang.clone() : this.lang;

    return output;
  }
}
