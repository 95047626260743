import { useContext } from 'react';
import AppContext from 'App/AppContext';
import { ProgressIndicator, Layer } from '@fluentui/react';

const progressBarStyles = {
  root: {
    paddingTop: 0,
  },
  itemProgress: {
    paddingTop: 0,
  },
};

const ContentLoader = () => {
  const appContext = useContext(AppContext);

  if (appContext.isContentLoading) {
    return (
      <Layer hostId="layerHostContentAreaTop">
        <ProgressIndicator barHeight={3} styles={progressBarStyles}></ProgressIndicator>
      </Layer>
    );
  } else {
    return null;
  }
};

export default ContentLoader;
