import AppError from 'utils/appError';

export enum DBProcesLoggerLogLevel {
  Info = 1,
  Warning = 2,
  Error = 3,
}

export class LogDTO {
  name: string;

  messages: string[];

  level: DBProcesLoggerLogLevel;

  error?: string;

  appError?: AppError;

  constructor(name: string) {
    this.name = name;
    this.messages = [];
    this.level = DBProcesLoggerLogLevel.Info;
  }

  replaceTokens = (msg: string): string => {
    const tokenIdx = msg.indexOf('Bearer ');
    if (tokenIdx >= 0 && tokenIdx + 1700 < msg.length) {
      try {
        msg = msg.substring(0, tokenIdx) + 'Bearer ***' + msg.substring(tokenIdx + 1700);
      } catch (err) {
        msg = 'Error replacing Bearer: ' + JSON.stringify(err) + ' Msg: ' + msg;
      }
    }

    return msg;
  };

  add(message: string | undefined): void {
    if (message) {
      message = this.replaceTokens(message);
      this.messages.push(message);
    }
    //limit the log and remove at interval
    if (this.messages.length > 1000 && this.messages.length % 100 === 0) {
      this.messages.splice(0, 100);
    }
  }

  clear() {
    this.messages = [];
    this.level = DBProcesLoggerLogLevel.Info;
    this.appError = undefined;
  }
}
