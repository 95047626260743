export const nlErrors = {
  'string.empty': '{#label} mag niet leeg zijn',
  'string.max': '{#label} heeft een maximale lengte van {#limit}',
  'any.required': '{#label} is verplicht',
  'date.min': '{#label}',
  'date.max': '{#label}',
  'string.uri': '{#label} is geen geldig adres',
  'string.pattern.base': '{#label} heeft een ongeldig formaat',
  'string.alphanum': '{#label} mag alleen letters en cijfers bevatten',
  'string.email': '{#label} is geen geldig e-mailadres',
};

export const enErrors = {
  'string.empty': '{#label} cannot be empty',
  'string.max': '{#label} has a maximum length of {#limit}',
  'any.required': '{#label} is required',
  'date.min': '{#label}',
  'date.max': '{#label}',
  'string.uri': '{#label} is not a valid address',
  'string.pattern.base': '{#label} has an invalid format',
  'string.alphanum': '{#label} can only contain letters and numbers',
  'string.email': '{#label} is no valid e-mail address',
};

export const deErrors = {
  'string.empty': '{#label} darf nicht leer sein',
  'string.max': '{#label} hat eine maximale Länge von {#limit}',
  'any.required': '{#label} ist erforderlich',
  'date.min': '{#label}',
  'date.max': '{#label}',
  'string.uri': '{#label} ist keine gültige Adresse',
  'string.pattern.base': '{#label} hat ein ungültiges Format',
  'string.alphanum': '{#label} darf nur Buchstaben und Zahlen enthalten',
  'string.email': '{#label} ist keine gültige E-Mail-Adresse',
};
