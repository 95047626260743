import {
  Dialog,
  DialogType,
  DialogFooter,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  TextField,
  Text,
  IDialogContentProps,
} from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import WarningMessage from 'components/Notification/WarningMessage';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium } from 'globalStyles';
import { onRenderLabelWithInfo } from 'globalFunctions';
import { useContext, useEffect, useState } from 'react';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';

export interface IDialogConfirmDeleteBulkProps {
  totalCount: number;
  authCount: number;
}

export interface IDialogConfirmDeleteProps {
  onYes: () => void;
  onNo: () => void;
  hidden: boolean;
  title: string;
  subText: string;
  confirmText?: string;
  optionalJSX?: JSX.Element;
  featureType?: FeatureTypes;
  bulkOptions?: IDialogConfirmDeleteBulkProps;
}

const DialogConfirmDelete = (props: IDialogConfirmDeleteProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const defConfirmText = t('translation:Dialogs.ConfirmDelete.DefaultConfirmDeleteText');
  const [yesDisabled, setYesDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!props.hidden && props.bulkOptions) {
      if (props.bulkOptions.authCount === 0) {
        setYesDisabled(true);
      }
    }
  }, [props.bulkOptions, props.hidden]);

  const getConfirmText = (): string => {
    return props.confirmText ?? defConfirmText;
  };

  const [enteredConfirmText, setEnteredConfirmText] = useState<string>(
    hasUserFeature(appContext, FeatureTypes.TenantAdmin) ? getConfirmText() : '',
  );

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    showCloseButton: true,
    onDismiss: () => dismiss(),
  };

  const isValidConfirmText = (): boolean => {
    return enteredConfirmText.toLowerCase() === getConfirmText().toLowerCase();
  };

  const hasFeature = (): boolean => {
    if (!props.featureType) return true;

    if (!appContext.user.login.userLicensed || !hasUserFeature(appContext, props.featureType)) {
      return false;
    }

    return true;
  };

  const confirm = () => {
    setEnteredConfirmText('');
    props.onYes();
  };

  const dismiss = () => {
    setEnteredConfirmText('');
    props.onNo();
  };

  if (!hasFeature()) {
    return (
      <Dialog hidden={props.hidden} onDismiss={props.onNo} dialogContentProps={dialogContentProps}>
        <Stack verticalFill>
          <Stack.Item>
            <WarningMessage message={t('translation:Dialogs.ConfirmDelete.NoRole')} />
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <DefaultButton onClick={() => dismiss()} text={t('translation:Dialogs.Ok.Ok')} />
        </DialogFooter>
      </Dialog>
    );
  }

  return (
    <Dialog hidden={props.hidden} dialogContentProps={dialogContentProps}>
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <WarningMessage message={t('translation:Dialogs.ConfirmDelete.Warning')} />
        </Stack.Item>
        {props.bulkOptions && (
          <Stack.Item>
            <Text>
              {t('translation:Dialogs.ConfirmDelete.Bulk', {
                total: props.bulkOptions.totalCount,
                auth: props.bulkOptions.authCount,
              })}
            </Text>
          </Stack.Item>
        )}
        {props.optionalJSX && (
          <Stack.Item grow styles={{ root: { marginTop: 0, position: 'relative', minHeight: 100, maxHeight: '20vh' } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{props.optionalJSX}</ScrollablePane>
          </Stack.Item>
        )}
        <Stack.Item>
          <TextField
            autoFocus={!appContext.isMobileView}
            onPaste={(e) => {
              e.preventDefault();

              return false;
            }}
            value={enteredConfirmText}
            onRenderLabel={() =>
              onRenderLabelWithInfo(
                t('translation:Dialogs.ConfirmDelete.ConfirmTextLabel'),
                t('translation:Dialogs.ConfirmDelete.ConfirmTextLabelInfo', {
                  confirmText: getConfirmText(),
                }),
              )
            }
            placeholder={t('translation:Dialogs.ConfirmDelete.ConfirmTextPlaceHolder', {
              confirmText: getConfirmText(),
            })}
            onChange={(ev, newValue) => setEnteredConfirmText(newValue ?? '')}
          ></TextField>
        </Stack.Item>
        <Stack.Item>
          <Text>{props.subText}</Text>
        </Stack.Item>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          disabled={!isValidConfirmText() || yesDisabled}
          onClick={() => confirm()}
          text={t('translation:Dialogs.YesNo.Yes')}
        />
        <DefaultButton onClick={() => dismiss()} text={t('translation:Dialogs.YesNo.No')} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogConfirmDelete;
