import { IAppContext } from 'App/AppContext';
import GlobalDataCache from './globalDataCache';
import { newGuidNil } from 'utils/guid';

interface IEntityCache<T> {
  misses: number;
  items: T[];
  itemSet: Map<number | string, T>;
  appContext?: IAppContext;
  cacheRef?: GlobalDataCache;
  has(id: string | number | undefined): boolean;
  get(id: string | number | undefined): T;
  getId: (item: T) => string | number;
  getEmptyItem: (id: string | number | undefined) => unknown;
  getItemsForId(ids: (number | string)[] | undefined): T[];
  add(item: T): void;
  update(item: T): void;
  remove(item: T): void;
}

export interface ICache<T, U> extends IEntityCache<T> {
  getItems: (refresh: boolean) => Promise<T[]>;
  clone(): U;
}

export class EntityCache<T> implements IEntityCache<T> {
  private _items: T[];

  private _itemSet: Map<number | string, T>;

  misses: number;

  appContext?: IAppContext;

  cacheRef?: GlobalDataCache;

  getId: (item: T) => number | string;

  getEmptyItem: (id: string | number | undefined) => unknown;

  constructor() {
    this._itemSet = new Map<number | string, T>();
    this.misses = 0;
    this._items = [];
    this.getEmptyItem = () => {};
    this.getId = () => 0;
  }

  private addMiss() {
    this.misses++;
    if (this.appContext) {
      this.appContext.cacheMiss(this.misses);
    }
  }

  private createItemSet() {
    this.itemSet.clear();
    this._items.forEach((i) => {
      this.itemSet.set(this.getId(i), i);
    });
  }

  get itemSet() {
    return this._itemSet;
  }

  get items() {
    return this._items;
  }

  set items(val: T[]) {
    this._items = val;
    this.createItemSet();
  }

  has(id: number | string | undefined): boolean {
    const item = this.itemSet.get(id ?? 0);
    if (!item) return false;

    return true;
  }

  get(id: number | string | undefined): T {
    if ((typeof id === 'number' && id === 0) || (typeof id === 'string' && id === newGuidNil())) {
      return this.getEmptyItem(id) as T;
    }

    const item = this.itemSet.get(id ?? 0);
    if (item) {
      return item;
    } else {
      this.addMiss();

      return this.getEmptyItem(id) as T;
    }
  }

  add(item: T) {
    if (!this.items.find((i) => this.getId(i) === this.getId(item))) {
      this.items.push(item);
      this.itemSet.set(this.getId(item), item);
    }
  }

  update(item: T) {
    const idx = this.items.findIndex((i) => this.getId(i) === this.getId(item));
    if (idx >= 0) {
      this.items[idx] = item;
      this.itemSet.set(this.getId(item), item);
    }
  }

  remove(item: T) {
    const idx = this.items.findIndex((i) => this.getId(i) === this.getId(item));
    if (idx >= 0) {
      this.items.splice(idx, 1);
      this.itemSet.delete(this.getId(item));
    }
  }

  getItemsForId(ids: (number | string)[] | undefined): T[] {
    if (!ids) return [];

    const result: T[] = [];
    ids.forEach((id) => {
      const item = this.itemSet.get(id);
      if (item) {
        result.push(item);
      }
    });

    return result;
  }
}
