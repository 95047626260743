export default class KPIDataContextDTO {
  kpiId: number;

  kpiDataId: number;

  entityType: number;

  entityId: number;

  constructor() {
    this.kpiId = 0;
    this.kpiDataId = 0;
    this.entityType = 0;
    this.entityId = 0;
  }
}
