import AppContext from 'App/AppContext';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import Tag from 'models/tag';
import { useContext } from 'react';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface ITaskTagListProps {
  tags: Tag[];
  readOnly?: boolean;
  isLoading: boolean;
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
}

export const TaskTagList = (props: ITaskTagListProps) => {
  const appContext = useContext(AppContext);

  if (props.readOnly) {
    return <KeyValueTags tags={props.tags} />;
  } else {
    return (
      <KeyValueTagPicker
        selectedTags={props.tags}
        onAdd={props.addTagToTaskState}
        onRemove={props.removeTagFromTaskState}
        onCreate={props.addTagToTaskState}
        isLoading={props.isLoading}
        allowCreate={hasUserFeatureGenericManager(appContext)}
      ></KeyValueTagPicker>
    );
  }
};
