import { globalRegExGetAlphaNumericPart, globalRegExGetNumericPart, globalRegExValidateEmail } from 'globalConstants';
import LocaleCurrency from 'locale-currency';

export function startsWithNumber(str: string) {
  return /^\d/.test(str);
}

export function isValidEmail(str: string): boolean {
  const result = globalRegExValidateEmail.test(str);

  return result;
}

export function extractNumber(str: string): number {
  let ca1 = str.replace(globalRegExGetAlphaNumericPart, '');
  if (ca1.split('.').length > 2) {
    ca1 = ca1.replaceAll('.', '');
  }
  if (ca1.startsWith('.')) ca1 = ca1.substring(1);
  if (ca1.endsWith('.')) ca1 = ca1.slice(0, -1);

  return Number.parseFloat(ca1);
}

export function extractAlpha(str: string): string {
  return str.replace(globalRegExGetNumericPart, '');
}

export function truncate(str: string | undefined, maxlen: number): string {
  if (str) {
    return str.length > maxlen ? str.substring(0, maxlen) : str;
  } else {
    return '';
  }
}

export function overflow(str: string | undefined, len: number): string {
  if (str && len > 4) {
    return str.length > len ? str.substring(0, len - 3) + '...' : str;
  } else {
    return str ? str : '';
  }
}

export function overflowStart(str: string | undefined, len: number): string {
  if (str && len > 4) {
    return str.length > len ? '...' + str.substring(str.length - len - 3) : str;
  } else {
    return str ? str : '';
  }
}

export function isEmpty(str: string | undefined | null): boolean {
  if (!str) return true;
  if (str.trim() === '') return true;

  return false;
}

export function toBool(str: string | undefined): boolean {
  if (!str) {
    return false;
  }

  return str.toLowerCase() === 'true' ? true : false;
}

export const toNameInitial = (name: string): string => {
  let initials: string = '';
  if (name) {
    const nameParts = name.split(' ');
    nameParts.forEach((item: string) => {
      if (item.length > 0) {
        initials += item[0].toUpperCase();
      }
    });
  }

  return initials;
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

export const stripFileExtension = (file: string): string => {
  return file.replace(/\.[^/.]+$/, '');
};

export const bytesToSize = (bytes: number): string => {
  if (bytes === 0) return '0 Byte';
  let i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const matchWildcard = (str: string, rule: string) => {
  let escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');

  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
};

export const toCurrency = (content: number): string => {
  const locale = 'NL';
  let currency = LocaleCurrency.getCurrency(locale);
  let formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currency });

  return formatter.format(content);
};

export const toBlob = (b64Data: string | undefined, contentType: string = ''): Blob | undefined => {
  if (!b64Data) return undefined;

  const sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export const getRandomId = (): string => {
  return Math.random().toString(36).substring(7);
};

export const capitalizeFirstLetter = (text: string | undefined): string | undefined => {
  if (!text) return undefined;

  return text.slice(0, 1).toUpperCase() + text.slice(1);
};
