import { ResourceListType } from './../../models/resourceList';
import ResourceLinkDTO, {
  EntityLinkDTO,
  ResourceLinkConversionDataDTO,
  ResourceLinkSearchDTO,
} from 'models/dto/resourceLinkDTO';
import ResourceLink, { EntityLink } from 'models/resourceLink';
import {
  mapFromResourceLink,
  mapFromResourceLinks,
  mapToResourceLink,
  mapFromEntityLinks,
  mapFromActivity,
} from 'mappings/dataMapping';
import http from './httpService';
import AppError from 'utils/appError';
import { TaskLinkDTO } from 'models/dto/tasks/taskLinkDTO';
import { ControlLinkDTO } from 'models/dto/controlLinkDTO';
import { RiskLinkDTO } from 'models/dto/riskLinkDTO';
import { ThemeLinkDTO } from 'models/dto/themeLinkDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { ObjectiveLinkDTO } from 'models/dto/Objective/objectiveLinkDTO';
import IdListDTO from 'models/dto/IdListDTO';
import { TeamsChannel } from 'models/teamsChannel';
import GroupChannelsLinksDTO from 'models/dto/groupChannelsLinksDTO';
import { ProcessLinkDTO } from 'models/dto/Process/processLink';
import Tenant from 'models/tenant';
import { LinkConversionTableDTO } from 'models/dto/linkConversionDTO';
import { AssetLinkDTO } from 'models/dto/asset/assetLinkDTO';
import Activity, { ActivityType } from 'models/activity';
import ActivityDTO from 'models/dto/activityDTO';
import { mapFromApprovals } from 'mappings/approvalMapping';
import TaskDTO from 'models/dto/tasks/taskDTO';
import { Approval } from 'models/approval';

//core links
export async function apiGetLinks(
  includeApprovals: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links?includeApprovals=${includeApprovals}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksForId(
  accessToken: string,
  ids: number[],
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const idList = new IdListDTO(ids.map((i) => i.toString()));
    const res = await http.post<ResourceLinkDTO[]>(`/links/id`, idList, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in the global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksForListType(
  typeId: ResourceListType,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const ar = await http.get<ResourceLinkDTO[]>(`/links/list/type/${typeId}`, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in the global data cache

    return mapFromResourceLinks(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLink(
  linkId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink | undefined> {
  try {
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache
    const ar = await http.get<ResourceLinkDTO>(`/links/${linkId}`, http.getRequestConfig(accessToken));
    if (!ar.data) return undefined;

    return mapFromResourceLink(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCreateLink(
  link: ResourceLink,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink> {
  try {
    const resourceLinkDTO = mapToResourceLink(link, false);
    const res = await http.post<ResourceLinkDTO>(`/links`, resourceLinkDTO, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLink(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinkForName(
  listId: number,
  linkName: string,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink> {
  try {
    const ar = await http.get<ResourceLinkDTO>(`/links/list/${listId}/${linkName}`, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLink(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteLink(linkId: number, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/links/delete/${linkId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLink(
  link: ResourceLink,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink> {
  try {
    const resourceLinkDTO = mapToResourceLink(link, false);
    const res = await http.put<ResourceLinkDTO>(`/links`, resourceLinkDTO, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLink(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinkUsage(linkId: number, accessToken: string): Promise<EntityLink[]> {
  try {
    const res = await http.get<EntityLinkDTO[]>(`/links/${linkId}/usage`, http.getRequestConfig(accessToken));

    return mapFromEntityLinks(res.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksForList(
  listId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const ar = await http.get<ResourceLinkDTO[]>(`/links/list/${listId}`, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in the global data cache

    return mapFromResourceLinks(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksWithChannels(accessToken: string, cache: GlobalDataCache): Promise<ResourceLink[]> {
  try {
    const ar = await http.get<ResourceLinkDTO[]>(`/links/channels`, http.getRequestConfig(accessToken));
    await cache.lists.getItems(); //make sure lists are loaded in the global data cache

    return mapFromResourceLinks(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveGroupLinks(
  groupId: string,
  channels: TeamsChannel[],
  links: ResourceLink[],
  accessToken: string,
): Promise<void> {
  try {
    const groupChannelsLinksDTO = new GroupChannelsLinksDTO();
    groupChannelsLinksDTO.groupId = groupId;
    groupChannelsLinksDTO.linkIds = links.map((l) => l.linkId);
    groupChannelsLinksDTO.channelIds = channels.map((l) => l.channelId);

    await http.post<ResourceLinkDTO>(`/links/group/delete`, groupChannelsLinksDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddGroupLinks(
  groupId: string,
  channels: TeamsChannel[],
  links: ResourceLink[],
  accessToken: string,
): Promise<void> {
  try {
    const groupChannelsLinksDTO = new GroupChannelsLinksDTO();
    groupChannelsLinksDTO.groupId = groupId;
    groupChannelsLinksDTO.linkIds = links.map((l) => l.linkId);
    groupChannelsLinksDTO.channelIds = channels.map((l) => l.channelId);

    await http.post<number>(`/links/group`, groupChannelsLinksDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateGroupLink(
  groupId: string,
  channels: TeamsChannel[],
  linkId: number,
  pinned: boolean,
  accessToken: string,
): Promise<number> {
  try {
    const groupChannelsLinksDTO = new GroupChannelsLinksDTO();
    groupChannelsLinksDTO.groupId = groupId;
    groupChannelsLinksDTO.linkIds = [linkId];
    groupChannelsLinksDTO.channelIds = channels.map((l) => l.channelId);
    groupChannelsLinksDTO.pinned = pinned;

    const ar = await http.put<number>(`/links/group`, groupChannelsLinksDTO, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//theme
export async function apiGetLinksForTheme(
  themeId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/theme/${themeId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in the global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForTheme(
  themeId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/theme/${themeId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToTheme(
  linkId: number,
  themeId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ThemeLinkDTO();
    body.linkId = linkId;
    body.themeId = themeId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ThemeLinkDTO>(`/links/theme?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkToTheme(
  linkId: number,
  themeId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ThemeLinkDTO();
    body.linkId = linkId;
    body.themeId = themeId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ThemeLinkDTO>(`/links/theme?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromTheme(link: ResourceLink, themeId: number, accessToken: string): Promise<void> {
  try {
    const body = new ThemeLinkDTO();
    body.linkId = link.linkId;
    body.themeId = themeId;

    await http.post<ThemeLinkDTO>(`/links/theme?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//objective
export async function apiUpdateLinkToObjective(
  linkId: number,
  objectiveId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ObjectiveLinkDTO();
    body.linkId = linkId;
    body.objectiveId = objectiveId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ControlLinkDTO>(`/links/objective?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToObjective(
  linkId: number,
  objectiveId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ObjectiveLinkDTO();
    body.linkId = linkId;
    body.objectiveId = objectiveId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ObjectiveLinkDTO>(`/links/objective?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromObjective(
  link: ResourceLink,
  objectiveId: number,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ObjectiveLinkDTO();
    body.linkId = link.linkId;
    body.objectiveId = objectiveId;

    await http.post(`/links/objective?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksForObjective(
  objectiveId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/objective/${objectiveId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForObjective(
  objectiveId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/objective/${objectiveId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//process
export async function apiUpdateLinkToProcess(
  linkId: number,
  processId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ProcessLinkDTO();
    body.linkId = linkId;
    body.processId = processId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ControlLinkDTO>(`/links/process?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToProcess(
  linkId: number,
  processId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ProcessLinkDTO();
    body.linkId = linkId;
    body.processId = processId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ObjectiveLinkDTO>(`/links/process?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromProcess(
  link: ResourceLink,
  processId: number,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ProcessLinkDTO();
    body.linkId = link.linkId;
    body.processId = processId;

    await http.post(`/links/process?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinksForProcess(
  processId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/process/${processId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForProcess(
  processId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/process/${processId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//control

export async function apiGetLinksForControl(
  controlId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/control/${controlId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForControl(
  controlId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/control/${controlId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToControl(
  linkId: number,
  controlId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ControlLinkDTO();
    body.linkId = linkId;
    body.controlId = controlId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ControlLinkDTO>(`/links/control?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkToControl(
  linkId: number,
  controlId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ControlLinkDTO();
    body.linkId = linkId;
    body.controlId = controlId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ControlLinkDTO>(`/links/control?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromControl(
  link: ResourceLink,
  controlId: number,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ControlLinkDTO();
    body.linkId = link.linkId;
    body.controlId = controlId;

    await http.post(`/links/control?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//task

export async function apiGetLinksForTask(
  taskId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/task/${taskId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToTask(
  linkId: number,
  taskId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new TaskLinkDTO();
    body.linkId = linkId;
    body.taskId = taskId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<TaskLinkDTO>(`/links/task?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkToTask(
  linkId: number,
  taskId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new TaskLinkDTO();
    body.linkId = linkId;
    body.taskId = taskId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<TaskLinkDTO>(`/links/task?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromTask(link: ResourceLink, taskId: number, accessToken: string): Promise<void> {
  try {
    const body = new TaskLinkDTO();
    body.linkId = link.linkId;
    body.taskId = taskId;

    await http.post<ResourceLinkDTO>(`/links/task?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//risk

export async function apiGetLinksForRisk(
  riskId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/risk/${riskId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForRisk(
  riskId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/risk/${riskId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToRisk(
  linkId: number,
  riskId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new RiskLinkDTO();
    body.linkId = linkId;
    body.riskId = riskId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ResourceLinkDTO>(`/links/risk?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkToRisk(
  linkId: number,
  riskId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new RiskLinkDTO();
    body.linkId = linkId;
    body.riskId = riskId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ResourceLinkDTO>(`/links/risk?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromRisk(link: ResourceLink, riskId: number, accessToken: string): Promise<void> {
  try {
    const body = new RiskLinkDTO();
    body.linkId = link.linkId;
    body.riskId = riskId;

    await http.post<ResourceLinkDTO>(`/links/risk?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// channel

export async function apiGetLinksForChannel(
  body: ResourceLinkSearchDTO,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.post<ResourceLinkDTO[]>(`/links/channel/search`, body, http.getRequestConfig(accessToken));

    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// orgunit

export async function apiGetLinksForOrgUnit(
  orgUnit: Tenant,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(`/links/orgunit/${orgUnit.id}`, http.getRequestConfig(accessToken));

    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

//
// Teams
//
export async function apiPinnedGetLinksForTeams(
  body: ResourceLinkSearchDTO,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.post<ResourceLinkDTO[]>(`links/channel/pinned`, body, http.getRequestConfig(accessToken));

    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiNewContentGetLinksForTeams(
  body: ResourceLinkSearchDTO,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.post<ResourceLinkDTO[]>(
      `links/channel/newcontent`,
      body,
      http.getRequestConfig(accessToken),
    );

    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// conversion
export async function apiGetLinkConverionTables(accessToken: string): Promise<LinkConversionTableDTO[]> {
  try {
    const ar = await http.get<LinkConversionTableDTO[]>(`links/conversion`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

// assets

export async function apiGetLinksForAsset(
  assetId: number,
  showAll: boolean,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/asset/${assetId}?indirect=${showAll}`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddLinkToAsset(
  linkId: number,
  assetId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new AssetLinkDTO();
    body.linkId = linkId;
    body.assetId = assetId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ResourceLinkDTO>(`/links/asset?add=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkToAsset(
  linkId: number,
  assetId: number,
  pinned: boolean | undefined,
  linkURLFragment: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new AssetLinkDTO();
    body.linkId = linkId;
    body.assetId = assetId;
    body.pinned = pinned || false;
    body.linkURLFragment = linkURLFragment;

    await http.post<ResourceLinkDTO>(`/links/asset?update=${true}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveLinkFromAsset(link: ResourceLink, assetId: number, accessToken: string): Promise<void> {
  try {
    const body = new AssetLinkDTO();
    body.linkId = link.linkId;
    body.assetId = assetId;

    await http.post<ResourceLinkDTO>(`/links/asset?add=${false}`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiPinnedGetLinksForAsset(
  assetId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const res = await http.get<ResourceLinkDTO[]>(
      `/links/asset/${assetId}?pinned=true`,
      http.getRequestConfig(accessToken),
    );
    await cache.lists.getItems(); //make sure lists are loaded in ther global data cache

    return mapFromResourceLinks(res.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinkConversionData(link: ResourceLink, accessToken: string): Promise<string | undefined> {
  try {
    const ar = await http.get<ResourceLinkConversionDataDTO>(
      `/links/${link.linkId}/conversion`,
      http.getRequestConfig(accessToken),
    );

    return ar.data.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateLinkConversionData(
  link: ResourceLink,
  data: string | undefined,
  accessToken: string,
): Promise<void> {
  try {
    const body = new ResourceLinkConversionDataDTO();
    body.linkId = link.linkId;
    body.data = data;

    await http.put<ResourceLinkConversionDataDTO>(`/links/conversion`, body, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetLinkActivity(
  link: ResourceLink,
  type: ActivityType,
  accessToken: string,
): Promise<Activity | undefined> {
  try {
    const ar = await http.get<ActivityDTO>(`links/${link.linkId}/activity/${type}`, http.getRequestConfig(accessToken));

    if (ar.data) {
      return mapFromActivity(ar.data);
    } else {
      return undefined;
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetRecentlyReadLinks(
  count: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<ResourceLink[]> {
  try {
    const ar = await http.get<ResourceLinkDTO[]>(
      `links/recent/read?count=${count}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromResourceLinks(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export const apiGetLinkApprovals = async (
  linkId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Approval[]> => {
  try {
    const ar = await http.get<TaskDTO[]>(`/links/${linkId}/approvals`, http.getRequestConfig(accessToken));

    return mapFromApprovals(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};

export const apiGetLinkOwners = async (
  link: ResourceLink,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<string[]> => {
  try {
    if (link.ownerId) {
      //return single owner from cache
      return [cache.users.get(link.ownerId).id];
    } else {
      //get from back-end
      const ar = await http.get<IdListDTO>(`/links/${link.linkId}/owners`, http.getRequestConfig(accessToken));

      return ar.data.idList ?? [];
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
};
