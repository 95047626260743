import http from './httpService';
import AppError from 'utils/appError';
import AuthResultDTO from 'models/dto/authResultDTO';

export async function apiOutlookExchangeAccessTokenForGraphToken(accessToken: string): Promise<AuthResultDTO> {
  try {
    const ar = await http.get<AuthResultDTO>(`/outlook/graphtoken`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiOutlookTaskExchangeAccessTokenForGraphToken(accessToken: string): Promise<AuthResultDTO> {
  try {
    const ar = await http.get<AuthResultDTO>(`/outlook/graphtoken/task`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiTeamsTaskExchangeAccessTokenForGraphToken(accessToken: string): Promise<AuthResultDTO> {
  try {
    const ar = await http.get<AuthResultDTO>(`/teams/graphtoken/task`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiTeamsLibraryExchangeAccessTokenForGraphToken(accessToken: string): Promise<AuthResultDTO> {
  try {
    const ar = await http.get<AuthResultDTO>(`/teams/graphtoken/library`, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}