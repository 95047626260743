import { TeamsChannel } from 'models/teamsChannel';
import { newGuidNil } from 'utils/guid';
import User from './user';

export enum GroupSyncStatus
{
    None = 0,
    ErrorNotFound = 1,
    ErrorGetMembers = 2,
    ErrorGetUsers = 3,
    ErrorLicenses = 4,
    Pending = 8,
    Success = 9
}

export default class Group {
  id: string;

  name: string;

  email: string;

  channels: TeamsChannel[];

  users?: User[];

  syncEnabled: boolean;

  syncStatus?: number;

  syncError?: string;

  syncMemberCount?: number;

  //internal
  isCurrentUserMember?: boolean;

  constructor(_id?: string, _name?: string, _email?: string) {
    this.id = _id ? _id : '';
    this.name = _name ? _name : '';
    this.email = _email ? _email : '';
    this.channels = [];
    this.syncEnabled = false; 
  }

  static getEmptyGroup(id?: string) {
    const newGroup = new Group(newGuidNil(), '', '');
    if (id) newGroup.id = id;

    return newGroup;
  }

  clone() {
    const newGroup = new Group(this.id, this.name, this.email);
    newGroup.channels = [...this.channels];
    newGroup.users = this.users?.map(u => u.clone());
    newGroup.syncEnabled = this.syncEnabled;
    newGroup.syncStatus = this.syncStatus;
    newGroup.syncError = this.syncError;
    newGroup.syncMemberCount = this.syncMemberCount;
    newGroup.isCurrentUserMember = this.isCurrentUserMember;
    
    return newGroup;
  }
}
