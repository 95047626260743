import Joi from 'joi';
import { TaskType_Translation } from './taskType_Translation';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { TFunction } from 'i18next';
import TaskTypeFormElement from 'models/tasks/taskTypeFormElement';
import { IAppContext } from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetTaskTypeName } from 'services/Api/taskService';

export enum SystemTaskTypes {
  None = 0,
  Approval = 1,
  // RiskAssesment = 2,
  // SupplierAssesment = 3,
  // InternalAudit = 4,
  // Vulnerability = 5,
  // Incident = 6,
  // Change = 7,
  // NonConformity = 8,
}

export default class TaskType {
  taskTypeId: number;

  authSchemaId?: number;
  
  systemTaskType?: number;

  isSystem: boolean;

  trans: TaskType_Translation[];

  transIdx: number;

  name: string;

  description?: string;

  form?: TaskTypeFormElement[];

  constructor() {
    this.taskTypeId = 0;
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.isSystem = false;
  }

  static getSystemTypeText(systemTaskType: SystemTaskTypes, t: TFunction<string[]>) {
    switch (systemTaskType) {
      case SystemTaskTypes.Approval:
        return t('forms:SystemTypes.Approval');
      default:
        return '';
    }
  }

  getTransIdx(langCode: string, defLangCode: string): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskType_Translation = this.trans[i];
        if (t.lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default language
      for (let i = 0; i < this.trans.length; i++) {
        let t: TaskType_Translation = this.trans[i];
        if (t.lang?.code === defLangCode) {
          return i;
        }
      }
    }

    return -1;
  }

  clone(): TaskType {
    const output = new TaskType();
    output.taskTypeId = this.taskTypeId;
    output.systemTaskType = this.systemTaskType;
    output.isSystem = this.isSystem;
    output.description = this.description;
    output.name = this.name;
    output.transIdx = this.transIdx;
    output.trans = [...this.trans];
    output.authSchemaId = this.authSchemaId;

    return output;
  }

  isEqual(item: TaskType): boolean {
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.systemTaskType !== this.systemTaskType) return false;
    if (item.taskTypeId !== this.taskTypeId) return false;
    if (item.isSystem !== this.isSystem) return false;
    if (item.authSchemaId !== this.authSchemaId) return false;

    return true;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
      },
      { abortEarly: false },
    );
  }

  static async nameExists(orgName: string, appContext: IAppContext): Promise<number> {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    return await apiGetTaskTypeName(orgName, accessToken);
  }
}
