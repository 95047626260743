import Language from 'models/language';
import { mapFromLanguage, mapToLanguage } from './langMapping';
import KPIDTO from 'models/dto/kpi/kpiDTO';
import KPI, { KPIGraphDataConfig } from 'models/kpi/kpi';
import { KPI_TranslationDTO } from 'models/dto/kpi/kpi_TranslationDTO';
import { KPI_Translation } from 'models/kpi/kpi_Translation';
import IdListDTO from 'models/dto/IdListDTO';
import KPIData from 'models/kpi/kpiData';
import KPIDataDTO from 'models/dto/kpi/kpiDataDTO';
import {
  fromApiDateTimeOptional,
  fromApiDateTimeRequired,
  toApiDateTimeOptional,
  toApiDateTimeRequired,
} from 'utils/datetime';
import KPIDataContextDTO from 'models/dto/kpi/kpiDataContextDTO';
import KPIDataContext, {
  KPIContextFilter,
  KPIContextFilterGroup,
  KPIContextFilterItem,
} from 'models/kpi/kpiDataContext';
import KPIGraphDataDTO, {
  KPIContextFilterDTO,
  KPIContextFilterGroupDTO,
  KPIContextFilterItemDTO,
  KPIGraphDataConfigDTO,
} from 'models/dto/kpi/kpiGraphDataDTO';
import KPIGraphData, { KPIGraphDataPoint, KPIGraphDataStackedPoint } from 'models/kpi/kpiGraphData';
import KPIAlertDTO from 'models/dto/kpi/KPIAlertDTO';
import KPIAlert from 'models/kpi/KPIAlert';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import KPIContextDTO from 'models/dto/kpi/KPIContextDTO';
import KPIContext from 'models/kpi/KPIContext';
import { mapFromObjectives } from './objectiveMapping';
import { mapFromControls } from './controlMappings';
import KPIDataTransformDTO from 'models/dto/kpi/kpiDataTransformDTO';
import KPIDataTransform from 'models/kpi/kpiDataTransform';
import KPIDataTransformResponse from 'models/kpi/kpiDataTransformResponse';
import KPIDataTransformResponseDTO from 'models/dto/kpi/kpiDataTransformResponseDTO';
import { mapFromResourceLink } from './linkMapping';
import KPIDataTransformLog from 'models/kpi/kpiDataTransformLog';
import KPIDataTransformLogDTO from 'models/dto/kpi/kpiDataTransformLogDTO';
import { mapFromStringValues, mapToStringValues } from './stringValueMapping';

export function mapFromKPIs(kpiDTOs: KPIDTO[] | undefined, cache: GlobalDataCache | undefined): KPI[] {
  if (!kpiDTOs) return [];

  const output = kpiDTOs.map((item) => {
    return mapFromKPI(item, cache) as KPI;
  });

  return output;
}

export function mapFromKPI(kpiDTO: KPIDTO, cache: GlobalDataCache | undefined): KPI {
  const output = new KPI();

  output.kpiId = kpiDTO.kpiId;
  output.authSchemaId = kpiDTO.authSchemaId;
  output.auditTrailId = kpiDTO.auditTrailId;
  output.choices = mapFromStringValues(kpiDTO.choices);
  output.defValue = kpiDTO.defValue;
  output.minValue = kpiDTO.minValue;
  output.maxValue = kpiDTO.maxValue;
  output.decimalCount = kpiDTO.decimalCount;
  output.required = kpiDTO.required;
  output.attachmentMode = kpiDTO.attachmentMode;
  output.commentMode = kpiDTO.commentMode;
  output.systemKPIType = kpiDTO.systemKPIType;
  output.type = kpiDTO.type;
  output.listId = kpiDTO.listId;
  output.tagIds = kpiDTO.tagIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.trans = mapFromKPI_Translation(kpiDTO.trans);
  output.data = mapFromKPIData(kpiDTO.data, output);
  output.alerts = cache ? mapFromKPIAlerts(kpiDTO.alerts, cache) : [];
  output.graphData = kpiDTO.graphData ? mapFromKPIGraphData(kpiDTO.graphData) : undefined;
  output.hasAutomatedEvidence = kpiDTO.hasAutomatedEvidence;
  output.taskTypeIds = kpiDTO.taskTypeIds?.idList?.map<number>((i) => Number(i)) ?? [];
  output.dashboardIds = kpiDTO.dashboardIds?.idList?.map<number>((i) => Number(i)) ?? [];

  if (output.trans && output.trans.length > 0) {
    output.transIdx = 0;
    output.name = output.trans[0].name;
    output.description = output.trans[0].description;
    output.instruction = output.trans[0].instruction;
    output.expectedResult = output.trans[0].expectedResult;
  }

  return output;
}

export const mapFromKPI_Translation = (transDTOs: KPI_TranslationDTO[] | undefined): KPI_Translation[] => {
  if (!transDTOs) return [];
  const output: KPI_Translation[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new KPI_Translation();

    newObject_Translation.kpiId = transDTO.kpiId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.instruction = transDTO.instruction;
    newObject_Translation.expectedResult = transDTO.expectedResult;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapFromLanguage(transDTO.lang) ?? new Language();

    output.push(newObject_Translation);
  }

  return output;
};

export function mapToKPI(kpi: KPI): KPIDTO {
  const output = new KPIDTO();

  output.kpiId = kpi.kpiId;
  output.authSchemaId = kpi.authSchemaId;
  output.auditTrailId = kpi.auditTrailId;
  output.choices = mapToStringValues(kpi.choices);
  output.defValue = kpi.defValue;
  output.minValue = kpi.minValue;
  output.maxValue = kpi.maxValue;
  output.decimalCount = kpi.decimalCount;
  output.required = kpi.required;
  output.attachmentMode = kpi.attachmentMode;
  output.listId = kpi.listId;
  output.commentMode = kpi.commentMode;
  output.type = kpi.type;
  output.tagIds = new IdListDTO(kpi.tagIds ? kpi.tagIds.map((i) => String(i)) : []);
  output.trans = mapToKPI_Translation(kpi.trans);

  if (kpi.trans && kpi.transIdx >= 0) {
    output.trans[kpi.transIdx].name = kpi.name;
    output.trans[kpi.transIdx].description = kpi.description;
    output.trans[kpi.transIdx].instruction = kpi.instruction;
    output.trans[kpi.transIdx].expectedResult = kpi.expectedResult;
  }

  return output;
}

export const mapToKPI_Translation = (transDTOs: KPI_Translation[]): KPI_TranslationDTO[] => {
  const output: KPI_TranslationDTO[] = [];

  for (let transDTO of transDTOs) {
    const newObject_Translation = new KPI_TranslationDTO();

    newObject_Translation.kpiId = transDTO.kpiId;
    newObject_Translation.description = transDTO.description;
    newObject_Translation.name = transDTO.name;
    newObject_Translation.instruction = transDTO.instruction;
    newObject_Translation.expectedResult = transDTO.expectedResult;
    newObject_Translation.languageId = transDTO.languageId;
    newObject_Translation.lang = mapToLanguage(transDTO.lang);

    output.push(newObject_Translation);
  }

  return output;
};

export const mapFromKPIData = (elements: KPIDataDTO[] | undefined, kpi: KPI): KPIData[] => {
  const output: KPIData[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new KPIData();

    newElm.kpiId = elm.kpiId;
    newElm.kpiDataId = elm.kpiDataId;
    newElm.created = fromApiDateTimeRequired(elm.created);
    newElm.createdBy = elm.createdBy;
    newElm.createdById = elm.createdById;
    newElm.modified = fromApiDateTimeOptional(elm.modified);
    newElm.modifiedBy = elm.modifiedBy;
    newElm.modifiedById = elm.modifiedById;
    newElm.resultComment = elm.resultComment;
    newElm.resultEvidence = elm.resultEvidence;
    newElm.resultDate = fromApiDateTimeOptional(elm.resultDate);
    newElm.resultNumber = elm.resultNumber;
    newElm.resultText = elm.resultText;
    newElm.contexts = mapFromKPIDataContext(elm.contexts);
    newElm.kpi = kpi; //KPI data point must have a reference to the KPI

    output.push(newElm);
  }

  return output;
};

export const mapToKPIData = (elements: KPIData[] | undefined): KPIDataDTO[] => {
  const output: KPIDataDTO[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new KPIDataDTO();

    newElm.kpiId = elm.kpiId;
    newElm.kpiDataId = elm.kpiDataId;
    newElm.created = toApiDateTimeRequired(elm.created);
    newElm.createdBy = elm.createdBy;
    newElm.createdById = elm.createdById;
    newElm.modified = toApiDateTimeOptional(elm.modified);
    newElm.modifiedBy = elm.modifiedBy;
    newElm.modifiedById = elm.modifiedById;
    newElm.resultComment = elm.resultComment;
    newElm.resultEvidence = elm.resultEvidence;
    newElm.resultDate = toApiDateTimeOptional(elm.resultDate);
    newElm.resultNumber = elm.resultNumber;
    newElm.resultText = elm.resultText;
    newElm.contexts = mapToKPIDataContext(elm.contexts);

    output.push(newElm);
  }

  return output;
};

export const mapFromKPIDataContext = (elements: KPIDataContextDTO[] | undefined): KPIDataContext[] => {
  const output: KPIDataContext[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new KPIDataContext();

    newElm.kpiId = elm.kpiId;
    newElm.kpiDataId = elm.kpiDataId;
    newElm.entityType = elm.entityType;
    newElm.entityId = elm.entityId;

    output.push(newElm);
  }

  return output;
};

export const mapToKPIDataContext = (elements: KPIDataContext[] | undefined): KPIDataContextDTO[] => {
  const output: KPIDataContextDTO[] = [];
  if (!elements) return output;

  for (let elm of elements) {
    const newElm = new KPIDataContextDTO();

    newElm.kpiId = elm.kpiId;
    newElm.kpiDataId = elm.kpiDataId;
    newElm.entityType = elm.entityType;
    newElm.entityId = elm.entityId;

    output.push(newElm);
  }

  return output;
};

export const mapFromKPIGraphData = (data: KPIGraphDataDTO | undefined): KPIGraphData => {
  const output = new KPIGraphData();
  if (!data) return output;

  if (data.points) {
    for (let elm of data?.points) {
      const newElm = new KPIGraphDataPoint();

      newElm.date = fromApiDateTimeOptional(elm.date);
      newElm.value = elm.value;

      output.points.push(newElm);
    }
  }

  if (data.stackedPoints) {
    for (let elm of data?.stackedPoints) {
      const newElm = new KPIGraphDataStackedPoint();

      newElm.date = fromApiDateTimeOptional(elm.date);
      newElm.values = elm.values ?? {};

      output.stackedPoints.push(newElm);
    }
  }

  return output;
};

export const mapToKPIGraphDataConfig = (config: KPIGraphDataConfig): KPIGraphDataConfigDTO => {
  const output = new KPIGraphDataConfigDTO();

  output.kpiId = config.kpiId;
  output.aggregationInterval = config.aggregationInterval;
  output.aggregationMethod = config.aggregationMethod;
  output.periodStart = toApiDateTimeOptional(config.periodStart);
  output.periodEnd = toApiDateTimeOptional(config.periodEnd);
  output.contextFilter = mapToKPIContextFilter(config.contextFilter);
  output.chartType = config.chartType;

  return output;
};

export const mapFromKPIAlerts = (data: KPIAlertDTO[] | undefined, cache: GlobalDataCache): KPIAlert[] => {
  if (!data) return [];

  return data.map((d) => mapFromKPIAlert(d, cache));
};

export const mapFromKPIAlert = (data: KPIAlertDTO, cache: GlobalDataCache): KPIAlert => {
  const output = new KPIAlert();

  output.kpiId = data.kpiId;
  output.kpiAlertId = data.kpiAlertId;
  output.operator = data.operator;
  output.treshold = data.treshold;
  output.aggregationMethod = data.aggregationMethod;
  output.aggregationPeriod = data.aggregationPeriod;
  output.templateId = data.templateId;
  output.template = cache.templates.get(data.templateId);
  output.filter = mapFromKPIContextFilter(data.filter);
  output.lastAlert = fromApiDateTimeOptional(data.lastAlert);

  return output;
};

export const mapToKPIAlerts = (data: KPIAlert[]): KPIAlertDTO[] => {
  return data.map((d) => mapToKPIAlert(d));
};

export const mapToKPIAlert = (data: KPIAlert): KPIAlertDTO => {
  const output = new KPIAlertDTO();

  output.kpiId = data.kpiId;
  output.kpiAlertId = data.kpiAlertId;
  output.operator = data.operator;
  output.treshold = data.treshold;
  output.aggregationMethod = data.aggregationMethod;
  output.aggregationPeriod = data.aggregationPeriod;
  output.templateId = data.templateId;
  output.filter = mapToKPIContextFilter(data.filter);

  return output;
};

export const mapFromKPIContext = (kpiContextDTO: KPIContextDTO, globalDataCache: GlobalDataCache): KPIContext => {
  const output = new KPIContext();
  output.objectives = kpiContextDTO.objectives ? mapFromObjectives(kpiContextDTO.objectives, globalDataCache) : [];
  output.controls = kpiContextDTO.controls ? mapFromControls(kpiContextDTO.controls, globalDataCache) : [];

  return output;
};

export const mapFromKPIContextFilter = (filterDto: KPIContextFilterDTO | undefined): KPIContextFilter => {
  const output = new KPIContextFilter();

  if (filterDto) {
    output.group = mapFromKPIContextFilterGroup(filterDto.group) ?? new KPIContextFilterGroup();
  }

  return output;
};

export const mapFromKPIContextFilterGroup = (
  filterGroupDto: KPIContextFilterGroupDTO | undefined,
): KPIContextFilterGroup | undefined => {
  if (!filterGroupDto) return undefined;

  const output = new KPIContextFilterGroup();
  output.operator = filterGroupDto.operator;
  output.items = filterGroupDto?.items.map((i) => mapFromKPIContextFilterItem(i));

  return output;
};

export const mapFromKPIContextFilterItem = (filterItemDto: KPIContextFilterItemDTO): KPIContextFilterItem => {
  const output = new KPIContextFilterItem();
  output.group = mapFromKPIContextFilterGroup(filterItemDto.group);
  output.entityType = filterItemDto.entityType;
  output.entityDateTime = fromApiDateTimeOptional(filterItemDto.entityDateTime);
  output.entityGuid = filterItemDto.entityGuid;
  output.entityId = filterItemDto.entityId;
  output.entityText = filterItemDto.entityText;

  return output;
};

export const mapToKPIContextFilter = (filter: KPIContextFilter | undefined): KPIContextFilterDTO | undefined => {
  if (!filter) return undefined;

  const output = new KPIContextFilterDTO();
  output.group = mapToKPIContextFilterGroup(filter.group) ?? new KPIContextFilterGroupDTO();

  return output;
};

export const mapToKPIContextFilterGroup = (
  filterGroup: KPIContextFilterGroup | undefined,
): KPIContextFilterGroupDTO | undefined => {
  if (!filterGroup) return undefined;

  const output = new KPIContextFilterGroupDTO();
  output.operator = filterGroup.operator;
  output.items = filterGroup?.items.map((i) => mapToKPIContextFilterItem(i));

  return output;
};

export const mapToKPIContextFilterItem = (filterItem: KPIContextFilterItem): KPIContextFilterItemDTO => {
  const output = new KPIContextFilterItemDTO();
  output.group = mapToKPIContextFilterGroup(filterItem.group);
  output.entityType = filterItem.entityType;
  output.entityDateTime = toApiDateTimeOptional(filterItem.entityDateTime);
  output.entityGuid = filterItem.entityGuid;
  output.entityId = filterItem.entityId;
  output.entityText = filterItem.entityText;

  return output;
};

export const mapFromKPIDataTransform = (
  elm: KPIDataTransformDTO | undefined,
  cache: GlobalDataCache,
): KPIDataTransform | undefined => {
  const newElm = new KPIDataTransform();
  if (!elm) return newElm;

  newElm.kpiId = elm.kpiId;
  newElm.created = fromApiDateTimeRequired(elm.created);
  newElm.createdBy = elm.createdBy;
  newElm.createdById = elm.createdById;
  newElm.rawEvidence = elm.rawEvidence;
  newElm.arrayPath = elm.arrayPath;
  newElm.selectedPath = elm.selectedPath;
  newElm.ignoredPaths = elm.ignoredPaths;
  newElm.extractedPath = elm.extractedPath;
  newElm.linkId = elm.linkId;
  newElm.templateId = elm.templateId;
  newElm.lastEventId = elm.lastEventId;
  newElm.evidence = elm.evidence;
  newElm.evidenceComment = elm.evidenceComment;
  newElm.link = elm.link ? mapFromResourceLink(elm.link, cache) : undefined;

  if (newElm.link && newElm.link.approvals?.length === 1) {
    const approval = newElm.link.approvals[0];
    //apply the link of the transform object to the approval tasks
    //these are not included by the back-end because we already have them on the transform object
    if (approval.approvalTasks.length > 0) {
      approval.approvalTasks.forEach((t) => (t.resourceLinkIds = [newElm.link?.linkId ?? 0]));
    }
    newElm.approval = approval.getMainStatus();
  }

  if (newElm.linkId && !newElm.link) {
    //link has been deleted. in this case, the back-end does not return the link itself, only the old deleted reference to the linkId
    newElm.linkId = undefined;
  }

  return newElm;
};

export const mapToKPIDataTransform = (elm: KPIDataTransform | undefined): KPIDataTransformDTO | undefined => {
  if (!elm) return elm;
  const newElm = new KPIDataTransformDTO();

  newElm.kpiId = elm.kpiId;
  newElm.created = toApiDateTimeRequired(elm.created);
  newElm.createdBy = elm.createdBy;
  newElm.createdById = elm.createdById;
  newElm.rawEvidence = elm.rawEvidence;
  newElm.arrayPath = elm.arrayPath;
  newElm.selectedPath = elm.selectedPath;
  newElm.ignoredPaths = elm.ignoredPaths;
  newElm.extractedPath = elm.extractedPath;
  newElm.linkId = elm.linkId;
  newElm.templateId = elm.templateId;
  newElm.evidence = elm.evidence;
  newElm.evidenceComment = elm.evidenceComment;

  return newElm;
};

export const mapFromKPIDataTransformResponses = (
  elm: KPIDataTransformResponseDTO[] | undefined,
): KPIDataTransformResponse[] => {
  if (!elm) return [];

  return elm.map((e) => mapFromKPIDataTransformResponse(e));
};

export const mapFromKPIDataTransformResponse = (elm: KPIDataTransformResponseDTO): KPIDataTransformResponse => {
  if (!elm) return elm;
  const newElm = new KPIDataTransformResponse();

  newElm.accepted = elm.accepted;
  newElm.errorCode = elm.errorCode;
  newElm.errorMsg = elm.errorMsg;
  newElm.resultNumber = elm.resultNumber;
  newElm.resultText = elm.resultText;
  newElm.resultEvidence = elm.resultEvidence;

  return newElm;
};

export const mapFromKPIDataTransformLogs = (elms: KPIDataTransformLogDTO[] | undefined): KPIDataTransformLog[] => {
  if (!elms) return [];

  return elms.map((e) => mapFromKPIDataTransformLog(e));
};

export const mapFromKPIDataTransformLog = (elm: KPIDataTransformLogDTO): KPIDataTransformLog => {
  const newElm = new KPIDataTransformLog();

  newElm.kpiDataTransformLogId = elm.kpiDataTransformLogId;
  newElm.kpiId = elm.kpiId;
  newElm.kpiDataId = elm.kpiDataId;
  newElm.created = fromApiDateTimeRequired(elm.created);
  newElm.createdBy = elm.createdBy;
  newElm.createdById = elm.createdById;
  newElm.taskId = elm.taskId;
  newElm.errorCode = elm.errorCode;
  newElm.errorMsg = elm.errorMsg;
  newElm.resultComment = elm.resultComment;
  newElm.resultEvidence = elm.resultEvidence;

  return newElm;
};
