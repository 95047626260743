import KPIDataContextDTO from './kpiDataContextDTO';

export default class KPIDataDTO {
  kpiId: number;

  kpiDataId: number;

  created: string;

  createdBy: string;

  createdById: string;

  modified?: string;

  modifiedBy?: string;

  modifiedById?: string;

  resultDate?: string;

  resultNumber?: number;

  resultText?: string;

  resultComment?: string;

  resultEvidence?: string;

  contexts: KPIDataContextDTO[];

  constructor() {
    this.kpiId = 0;
    this.kpiDataId = 0;
    this.created = '';
    this.createdBy = '';
    this.createdById = '';
    this.contexts = [];
  }
}
