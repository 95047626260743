import React from 'react';
import { Stack, TextField, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { globalStackItemStylesPaddingSceneScroll, globalStackTokensGapSmall, globalTextFieldBig } from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { TaskTagList } from 'components/Tasks/TaskTagList';
import Tag from 'models/tag';
import { TaskState } from 'models/tasks/taskHelperClasses';

interface IOutlookEventTaskDetail {
  task: Task;
  orgTask: Task;
  getErrorMessage: (field: string) => string | undefined;
  onUpdate: (newTask: Task) => void;
  taskStates: TaskState[];
  isActionPending: boolean;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  createTagState: (tag: Tag) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

export const OutlookEventTaskDetail = (props: IOutlookEventTaskDetail) => {
  const appContext = React.useContext(AppContext);
  const { t } = useTranslation(['task', 'control', 'translation']);

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapSmall}>
      <Stack.Item grow styles={globalStackItemStylesPaddingSceneScroll}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Stack verticalFill tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <TextField
                underlined
                autoFocus={!appContext.isMobileView}
                value={props.task.name ? props.task.name : ''}
                styles={globalTextFieldBig}
                onGetErrorMessage={(value) => {
                  if (value === '') return '';

                  return props.getErrorMessage('name');
                }}
                placeholder={t('task:Title.Placeholder')}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let copyTask = props.task.clone();
                  copyTask.name = newValue ? newValue : '';
                  props.onUpdate(copyTask);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <TaskTagList
                tags={props.tags}
                isLoading={props.isActionPending}
                addTagToTaskState={props.addTagToTaskState}
                removeTagFromTaskState={props.removeTagFromTaskState}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                styles={{ root: { minHeight: 100, maxHeight: '100%' } }}
                resizable={true}
                label={t('task:Description.Label')}
                placeholder={t('task:Description.Placeholder')}
                multiline
                rows={5}
                value={props.task.description ? props.task.description : ''}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  let copyTask = props.task.clone();
                  copyTask.description = newValue;
                  props.onUpdate(copyTask);
                }}
              />
            </Stack.Item>
          </Stack>
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};
