import { useContext, useEffect, useState } from 'react';
import User from 'models/user';
import {
  IBasePickerSuggestionsProps,
  IInputProps,
  IPersonaProps,
  ISuggestionItemProps,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalFilterDelay, globalSuggestionsMaxRecords } from 'globalConstants';
import AppContext from 'App/AppContext';
import { sortOnString } from 'utils/sorting';

interface IPickerProps extends IPersonaProps {}

export interface IUserPickerProps {
  users: User[];
  usersToExclude?: User[];
  onSelect?: (item: User | undefined, itemType: string | undefined, removedId?: string) => void;
  selectedItemId?: string;
  disabled?: boolean;
  placeHolder?: string | undefined;
  clearAfterSelect?: boolean;
  showUnlicensedUsers?: boolean;
}

const createKey = (item: User): string => {
  return item.id;
};

const UserPicker = (props: IUserPickerProps) => {
  const { t } = useTranslation('translation');
  const appContext = useContext(AppContext);

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(undefined);
  const [users, setUsers] = useState<User[]>(props.users);

  useEffect(() => {
    if (props.users.length === 0) {
      if (props.showUnlicensedUsers) {
        setUsers(appContext.globalDataCache.users.items);
      } else {
        setUsers(appContext.globalDataCache.users.items.filter((u) => u.hasLicense));
      }
    } else {
      setUsers(props.users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users]);

  const setSelectedItem = (id: string | undefined, type: string | undefined) => {
    setSelectedItemId(id);
  };

  useEffect(() => {
    const setInitialSelectedUser = () => {
      const user = users.find((_user) => {
        return _user.id === props.selectedItemId;
      });
      if (user) {
        setSelectedItem(user.id, 'user');
      } else {
        setSelectedItem(undefined, undefined);
      }
    };

    setInitialSelectedUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, props.selectedItemId]);

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    onRenderNoResultFound: () => {
      if (selectedItemId) {
        return (
          <Stack styles={{ root: { padding: 10 } }}>
            <Text>{t('translation:General.Suggestions.AlreadySelected')}</Text>
          </Stack>
        );
      } else {
        return (
          <Stack styles={{ root: { padding: 10 } }}>
            <Text>{t('translation:General.Suggestions.NoData')}</Text>
          </Stack>
        );
      }
    },
  };

  const getPersonas = (users: User[]): IPickerProps[] => {
    const personaProps: IPickerProps[] = [];

    for (let user of users) {
      let personaProp: IPickerProps = {
        text: user.name,
        key: createKey(user),
        secondaryText: user.email,
      };
      personaProps.push(personaProp);
    }

    return personaProps.sort((a, b) => {
      return sortOnString(a.text, b.text);
    });
  };

  const getSelectedPersona = (): IPickerProps[] | undefined => {
    const personaProps: IPickerProps[] = [];

    for (let user of users) {
      if (user.id === selectedItemId) {
        let personaProp: IPickerProps = {
          text: user.name,
          key: createKey(user),
          secondaryText: user.email,
        };
        personaProps.push(personaProp);

        return personaProps;
      }
    }

    return personaProps;
  };

  const getNameFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  const filterAllUsersByText = (filterText: string): User[] => {
    return users.filter(
      (item) =>
        item.hasLicense === true &&
        (item.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 ||
          item.email.toLowerCase().indexOf(filterText.toLowerCase()) >= 0),
    );
  };

  const onSuggestion = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number,
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText && !selectedItemId) {
      let filteredUsers = filterAllUsersByText(filterText);
      filteredUsers = filteredUsers.filter((u) => !props.usersToExclude?.some((e) => e.id === u.id));
      let filteredPersonas: IPickerProps[] = getPersonas(filteredUsers);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;

      return filteredPersonas;
    } else {
      return [];
    }
  };

  const onChange = (personas: IPersonaProps[] | undefined): void => {
    if (!personas || personas.length === 0) {
      if (props.onSelect) {
        props.onSelect(undefined, selectedItemId);
      }
      setSelectedItem(undefined, undefined);

      return;
    }

    let personaModified: IPickerProps[] | undefined = [];
    personaModified = personas?.map((persona) => {
      return persona as IPickerProps;
    });
    if (!personaModified) {
      return;
    }

    const persona = personaModified[0];
    const item = users.find((_user) => {
      return createKey(_user) === persona.key;
    });
    if (item) {
      setSelectedItem(item.id, 'user');
    }
    if (props.onSelect) {
      props.onSelect(item, 'user');
    }
    if (props.clearAfterSelect) {
      setSelectedItem(undefined, undefined);
    }
  };

  const onRenderSuggestionsItem = (
    props: IPersonaProps,
    itemProps: ISuggestionItemProps<IPersonaProps>,
  ): JSX.Element => {
    const modifiedProps: IPickerProps = props as IPickerProps;

    return (
      <Stack verticalAlign="center" tokens={{ padding: '5px' }}>
        <Persona {...modifiedProps} size={PersonaSize.size40} hidePersonaDetails={false} />
      </Stack>
    );
  };

  const onEmptyResolveSuggestions = (selectedItems?: IPersonaProps[] | undefined): IPersonaProps[] => {
    const filteredPersonas: IPickerProps[] = getPersonas(users);

    return filteredPersonas.slice(0, 30);
  };

  const inputProps: IInputProps = {
    placeholder: props.placeHolder || t('translation:General.UserPicker.Placeholder'),
  };

  //
  // Main render
  //
  return (
    <NormalPeoplePicker
      itemLimit={1}
      onResolveSuggestions={onSuggestion}
      getTextFromItem={getNameFromItem}
      pickerSuggestionsProps={suggestionProps}
      selectedItems={getSelectedPersona()}
      onChange={onChange}
      resolveDelay={globalFilterDelay}
      onRenderSuggestionsItem={onRenderSuggestionsItem}
      onEmptyResolveSuggestions={onEmptyResolveSuggestions}
      disabled={props.disabled}
      styles={{ root: { height: 30 } }}
      inputProps={inputProps}
    />
  );
};
export default UserPicker;
