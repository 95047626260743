import React from 'react';
import { DefaultButton, PrimaryButton, Stack, Text, ActionButton, IButtonProps, Separator } from '@fluentui/react';
import { globalStackTokensGapExtraSmall, globalStackTokensGapSmall, templateIcon } from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { OutlookNormalTaskDetail } from './OutlookNormalTaskDetail';
import AppContext from 'App/AppContext';
import { apiGetSingleTask } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import Joi from 'joi';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AppError from 'utils/appError';
import { toDateOnly, toLocaleDateShort } from 'utils/datetime';
import { IOutlookSingleTaskTypeProps, spinnerIcon } from './OutlookSingleTask';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import OutlookOverlayLoader from 'scenes/Task/OutlookOverlayLoader';
import OutlookAttachmentError from 'scenes/Task/OutlookAttachmentError';
import UserAccount from 'outlookComponents/UserAccount';

interface IOutlookNormalTaskProps extends IOutlookSingleTaskTypeProps {
  onSaveAsNewTask: () => void;
}

const OutlookNormalTask = (props: IOutlookNormalTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent', 'outlook']);
  const appContext = React.useContext(AppContext);
  const [err, setErr] = React.useState<Joi.ValidationError | undefined>(undefined);
  const [errStart, setErrStart] = React.useState<string | undefined>(undefined);
  const [isMasterTask, setIsMasterTask] = React.useState<boolean>(false);
  const [selectedPivot, setSelectedPivot] = React.useState<string>('Checklist');
  const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);
  const [showOpenMasterDialog, setShowOpenMasterDialog] = React.useState<boolean>(false);
  const [showOpenPrevDialog, setShowOpenPrevDialog] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    if (!props.task.isSeries() && isMasterTask) {
      doCancelMasterActions();
    }

    const result = props.task.validate(_localizedFields);
    setErr(result.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, props.task]);

  React.useEffect(() => {
    if (appContext.isMobileView) {
      setSelectedPivot('TaskDetails');
    } else {
      setSelectedPivot('Checklist');
    }
  }, [appContext.isMobileView]);

  React.useEffect(() => {
    props.onChangeIsAttachment(isChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      if (fieldError) {
        return fieldError.message;
      }
    }

    let errStart: string | undefined = undefined;
    if (fieldName === 'startDateTime') {
      //check the start date range for series and instances
      if (props.rescheduleDateRange) {
        if (props.task.startDateTime !== props.orgTask.startDateTime) {
          if (
            props.rescheduleDateRange.start &&
            !props.rescheduleDateRange.end &&
            toDateOnly(props.task.startDateTime) <= toDateOnly(props.rescheduleDateRange.start)
          ) {
            errStart = t('task:Start.Errors.After', { start: toLocaleDateShort(props.rescheduleDateRange.start) });
          } else if (
            props.rescheduleDateRange.end &&
            !props.rescheduleDateRange.start &&
            toDateOnly(props.task.endDateTime) >= toDateOnly(props.rescheduleDateRange.end)
          ) {
            errStart = t('task:Start.Errors.Before', { end: toLocaleDateShort(props.rescheduleDateRange.end) });
          } else if (
            props.rescheduleDateRange.end &&
            props.rescheduleDateRange.start &&
            (toDateOnly(props.task.startDateTime) <= toDateOnly(props.rescheduleDateRange.start) ||
              toDateOnly(props.task.endDateTime) >= toDateOnly(props.rescheduleDateRange.end))
          ) {
            errStart = t('task:Start.Errors.Between', {
              start: toLocaleDateShort(props.rescheduleDateRange.start),
              end: toLocaleDateShort(props.rescheduleDateRange.end),
            });
          }
        }
      }
    }

    setErrStart(errStart);

    if (errStart) {
      return errStart;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err || errStart ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    if (isMasterTask) {
      return props.task.isEqual(props.orgTask) || hasErrors();
    } else {
      return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
    }
  };

  const onCheckBoxChange = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsChecked(!!checked);
    },
    []
  );

  const onCancel = () => {
    if (cannotSaveTask()) {
      doCancelMasterActions();
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const loadMasterTask = async () => {
    try {
      if (!props.task.taskMasterId) {
        return;
      }
      props.setIsActionPending(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(props.task.taskMasterId, false, accessToken, appContext.globalDataCache);

      if (tasks.tasks && tasks.tasks.length > 0) {
        const masterTask = tasks.tasks[0];

        setErr(undefined);
        setErrStart(undefined);
        setIsMasterTask(true);

        props.onUpdateTask(masterTask);
      } else {
        throw new AppError(`The series task with id ${props.task.taskMasterId} not does not exist`);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      props.setIsActionPending(false);
    }
  };

  const loadPrevTask = () => {
    setErr(undefined);
    setErrStart(undefined);
    doCancelMasterActions();
    props.onUpdateTask(props.orgTask);
  };

  const doCancelMasterActions = () => {
    setIsMasterTask(false);
    if (!props.task.isInstance() && selectedPivot === 'Instances') {
      setSelectedPivot('Checklist');
    }
  };

  const getTaskDetails = () => {
    return (
      <OutlookNormalTaskDetail
        task={props.task}
        orgTask={props.orgTask}
        onUpdate={(newTask: Task) => {
          props.onUpdateTask(newTask);
        }}
        taskStates={props.task.taskStates!}
        getErrorMessage={validate}
        isActionPending={props.isActionPending}
        loadRescheduleRange={props.loadRescheduleRange}
        tags={props.tags}
        addTagToTaskState={props.addTagToTaskState}
        removeTagFromTaskState={props.removeTagFromTaskState}
        onChangeCompletionDate={props.onChangeCompletionDate}
      />
    );
  };

  const footer = (
    <Stack verticalAlign="space-evenly" tokens={globalStackTokensGapSmall}>
      <Separator styles={{ root: { height: 5 } }} />
      <Stack.Item>
        <Checkbox
          disabled={!props.isAttachmentEnable}
          label={t('outlook:Task.Attachments')}
          checked={isChecked}
          onChange={onCheckBoxChange}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            split
            disabled={hasErrors() || props.isActionPending} //disable save as template
            primaryDisabled={cannotSaveTask() || props.isActionPending}
            onClick={() => {
              props.onSave(false);
              doCancelMasterActions();
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            disabled={props.isActionPending} //disable save as template
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
    </Stack>
  );

  if (props.isAttachmentError) {
    return <OutlookAttachmentError text={t('outlook:Task.AttachmentError')} taskUrl={props.taskUrl} />;
  } else
    return (
      <Stack
        verticalFill
        tokens={globalStackTokensGapExtraSmall}
        styles={{
          root: {
            padding: '5px 5px 5px 10px',
          },
        }}
      >
        {props.isLoading && <OutlookOverlayLoader text={t('outlook:Task.TaskSaving')} />}
        <Stack.Item>
          <Stack horizontal={false} verticalAlign="space-between">
            <Stack.Item>
              <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                  <Text variant="xLarge">{props.task.taskId === -1 && t('task:Title.Normal.New')}</Text>
                </Stack.Item>
                <Stack.Item>
                  <UserAccount logout={props.logout}></UserAccount>
                </Stack.Item>
              </Stack>
            </Stack.Item>

            {props.task.taskId === -1 && (
              <Stack.Item>
                <ActionButton
                  onMenuClick={(
                    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                    button?: IButtonProps
                  ) => {
                    if (props.templateTasks === undefined) props.loadTemplates();
                  }}
                  menuProps={props.getTemplates}
                  iconProps={templateIcon}
                  text={t('outlook:Task.ChooseTemplate')}
                />
                {props.templateTaskLoading && spinnerIcon}
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
        <Stack.Item grow>{getTaskDetails()}</Stack.Item>
        <Stack.Item style={{ paddingBottom: 5 }}>{footer}</Stack.Item>

        <DialogConfirmDelete
          onNo={() => {
            setShowRemoveDialog(false);
          }}
          onYes={() => {
            setShowRemoveDialog(false);
            props.onRemove();
          }}
          title={props.task.isSeries() ? t('task:RemoveSeries.DialogTitle') : t('task:Remove.DialogTitle')}
          subText={props.task.isSeries() ? t('task:RemoveSeries.DialogSubTitle') : t('task:Remove.DialogSubTitle')}
          hidden={!showRemoveDialog}
          confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
        />
        <DialogYesNo
          onNo={() => {
            setShowCancelDialog(false);
          }}
          onYes={() => {
            setShowCancelDialog(false);
            doCancelMasterActions();
            props.onCancel();
          }}
          title={t('task:Cancel.DialogTitle')}
          subText={t('task:Cancel.DialogSubTitle')}
          hidden={!showCancelDialog}
        />
        <DialogYesNo
          onNo={() => {
            setShowOpenMasterDialog(false);
          }}
          onYes={() => {
            setShowOpenMasterDialog(false);
            loadMasterTask();
          }}
          title={t('task:Cancel.DialogTitle')}
          subText={t('task:Cancel.DialogSubTitle')}
          hidden={!showOpenMasterDialog}
        />
        <DialogYesNo
          onNo={() => {
            setShowOpenPrevDialog(false);
          }}
          onYes={() => {
            setShowOpenPrevDialog(false);
            loadPrevTask();
          }}
          title={t('task:Cancel.DialogTitle')}
          subText={t('task:Cancel.DialogSubTitle')}
          hidden={!showOpenPrevDialog}
        />
      </Stack>
    );
};

export default OutlookNormalTask;
