import { IList, IListItem, ITextWebPart, IUpdateSitePage } from './SharepointInterfaces';
import { Client } from '@microsoft/microsoft-graph-client';
import AppError from 'utils/appError';
import { HorizontalSectionLayoutType, INewSitePage, ISitePage } from 'services/Graph/SharepointInterfaces';
import { globalMaxSharePointListSize } from 'globalConstants';
import logger from 'services/Logging/logService';
import { execGet } from './graphServiceCore';

//
// Sharepoint Pages
//

export const graphValidateSitePageItem = async (client: Client, siteId: string, pageId: string): Promise<boolean> => {
  try {
    const path = `sites/${siteId}/pages/${pageId}`;
    logger.debug('graphValidateSitePageItem', path);
    await client.api(path).get();

    return true;
  } catch (err) {
    logger.debug('graphValidateSitePageItem', err);

    return false;
  }
};

export const graphGetValidWebParts = (page: ISitePage): ITextWebPart[] => {
  if (page.canvasLayout?.webParts) {
    return page.canvasLayout?.webParts.filter((wp) => wp['@odata.type'] === '#microsoft.graph.textWebPart');
  } else {
    return [];
  }
};

export const graphGetCanvasValidWebParts = (page: ISitePage | undefined): ITextWebPart[] => {
  const validWebparts: ITextWebPart[] = [];
  if (!page) return validWebparts;
  
  if (page.canvasLayout && page.canvasLayout.horizontalSections) {
    for (let horIdx = 0; horIdx < page.canvasLayout.horizontalSections?.length; horIdx++) {
      const horSection = page.canvasLayout.horizontalSections[horIdx];
      for (let colIdx = 0; colIdx < horSection.columns.length; colIdx++) {
        const col = horSection.columns[colIdx];
        for (let wpIdx = 0; wpIdx < col.webparts.length; wpIdx++) {
          const wp = col.webparts[wpIdx];
          if (wp['@odata.type'] === '#microsoft.graph.textWebPart') {
            validWebparts.push(wp);
          }
        }
      }
    }

    return validWebparts;
  } else {
    return [];
  }
};

export const graphPageHasValidWebParts = (page: ISitePage): boolean => {
  if (page.canvasLayout && page.canvasLayout.horizontalSections) {
    for (let horIdx = 0; horIdx < page.canvasLayout.horizontalSections?.length; horIdx++) {
      const horSection = page.canvasLayout.horizontalSections[horIdx];
      for (let colIdx = 0; colIdx < horSection.columns.length; colIdx++) {
        const col = horSection.columns[colIdx];
        for (let wpIdx = 0; wpIdx < col.webparts.length; wpIdx++) {
          const wp = col.webparts[wpIdx];
          if (wp['@odata.type'] !== '#microsoft.graph.textWebPart') {
            return false;
          }
        }
      }
    }

    return true;
  } else {
    return false;
  }
};

export const graphGetSitePage = async (
  client: Client,
  siteId: string,
  pageId: string,
  expandWebParts: boolean,
): Promise<ISitePage> => {
  try {
    let query = `sites/${siteId}/pages/${pageId}/microsoft.graph.sitePage`;
    if (expandWebParts) query += '?expand=canvasLayout';
    logger.debug('graphGetSitePage', query);
    const page = await execGet(client.api(query));

    return page;
  } catch (err) {
    logger.debug('graphGetSitePage', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSitePageList = async (client: Client, siteId: string): Promise<IList | undefined> => {
  try {
    const lists = await client.api(`sites/${siteId}/lists`).select('id,name,system').get();
    if (lists && lists.value) {
      const pageList: IList = lists.value.find((l: IList) => l.name?.toLocaleLowerCase() === 'sitepages');
      if (pageList) {
        return pageList;
      }
    }

    return undefined;
  } catch (err) {
    logger.debug('graphGetSitePage', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetSitePageListItem = async (
  client: Client,
  siteId: string,
  listId: string,
  webUrl: string,
): Promise<IListItem | undefined> => {
  try {
    const items = await client.api(`sites/${siteId}/lists/${listId}/items`).get();
    const item: IListItem = items.value.find(
      (i: IListItem) => i.webUrl?.toLocaleLowerCase() === webUrl.toLocaleLowerCase(),
    );
    if (item) {
      return item;
    }

    return undefined;
  } catch (err) {
    logger.debug('graphGetSitePage', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphGetPagesForSite = async (
  client: Client,
  siteId: string,
  expandWebParts: boolean,
): Promise<ISitePage[]> => {
  try {
    let query = `sites/${siteId}/pages/microsoft.graph.sitePage`;
    if (expandWebParts) query += '?expand=canvasLayout';
    logger.debug('graphGetPagesForSite', query);
    let rawPages = await client.api(query).get();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rawItems: any[] = [];

    if (rawPages && rawPages.value) {
      let nextLink = rawPages['@odata.nextLink'];
      rawItems = [...rawPages.value];

      while (nextLink) {
        logger.debug('graphGetPagesForSite', nextLink);
        rawPages = await client.api(nextLink).get();
        if (!rawPages) break;
        if (rawPages.value) {
          rawItems.push(...rawPages.value);
        }
        nextLink = rawPages['@odata.nextLink'];
        if (rawItems.length > globalMaxSharePointListSize) break;
      }
    }

    const pages: ISitePage[] = [];

    for (let idx = 0; idx < rawItems.length; idx++) {
      const rawPage = rawItems[idx];
      const page: ISitePage = rawPage;
      pages.push(page);
    }

    return pages;
  } catch (err) {
    logger.debug('graphGetPagesForSite', err);
    throw AppError.fromGraphError(err);
  }
};

export const getWebParts = (page: ISitePage): ITextWebPart[] => {
  //handle old and new data model for webparts
  const newWebParts: ITextWebPart[] = [];

  if (page.canvasLayout?.webParts && page.canvasLayout?.webParts.length > 0) {
    //old style
    const oldWebParts = graphGetValidWebParts(page);
    newWebParts.push(...oldWebParts);
  } else {
    // new style
    const webParts = graphGetCanvasValidWebParts(page);
    newWebParts.push(...webParts);
  }

  return newWebParts;
};

export const graphCreateSitePage = async (client: Client, siteId: string, page: ISitePage): Promise<ISitePage> => {
  try {
    const newWebParts = getWebParts(page);

    if (!page.title) throw new AppError('Page must have a title');

    const newPage: INewSitePage = {
      '@odata.type': '#microsoft.graph.sitePage',
      name: page.name,
      title: page.title,
      pageLayout: 'article',
      canvasLayout: {
        horizontalSections: [
          {
            id: '1',
            layout: HorizontalSectionLayoutType.oneColumn,
            columns: [
              {
                id: '1',
                webparts: newWebParts,
              },
            ],
          },
        ],
      },
    };

    logger.debug('graphCreateSitePage', newPage);
    const item = await client.api(`sites/${siteId}/pages`)
      .header('Accept', 'application/json')
      .header('odata.metadata', 'none')
      .post(newPage);

    return item;
  } catch (err) {
    logger.debug('graphCreateSitePage', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphPublishSitePage = async (client: Client, siteId: string, pageId: string): Promise<void> => {
  try {
    const path = `sites/${siteId}/pages/${pageId}/microsoft.graph.sitePage/publish`;
    logger.debug('graphPublishSitePage', path);
    await client.api(path).post(null);
  } catch (err) {
    logger.debug('graphPublishSitePage', err);
    throw AppError.fromGraphError(err);
  }
};

export const graphDeleteSitePage = async (client: Client, siteId: string, pageId: string): Promise<void> => {
  try {
    const path = `sites/${siteId}/pages/${pageId}`;
    logger.debug('graphDeleteSitePage', path);
    await client.api(path).delete();
  } catch (err) {
    logger.debug('graphDeleteSitePage', err);
    const appErr = AppError.fromGraphError(err);
    if (appErr.code?.toLowerCase() === 'itemNotFound') {
      return; //oke
    } else {
      throw appErr;
    }
  }
};

export const graphUpdateSitePageCanvas = async (
  client: Client,
  siteId: string,
  pageId: string | undefined,
  page: ISitePage,
): Promise<ISitePage> => {
  try {
    if (pageId === undefined) throw new Error('graphUpdateSitePageCanvas: pageId is undefined')

    const newWebParts = getWebParts(page);

    const newPage: IUpdateSitePage = {
      '@odata.type': '#microsoft.graph.sitePage',
      canvasLayout: {
        horizontalSections: [
          {
            id: '1',
            layout: HorizontalSectionLayoutType.oneColumn,
            columns: [
              {
                id: '1',
                webparts: newWebParts,
              },
            ],
          },
        ],
      },
    };

    logger.debug('graphUpdateSitePageCanvas', newPage);
    const rawPage = await client.api(`sites/${siteId}/pages/${pageId}/microsoft.graph.sitePage`)
      .header('Accept', 'application/json')
      .header('odata.metadata', 'none')
      .patch(newPage);

    return rawPage;
  } catch (err) {
    logger.debug('graphUpdateSitePageCanvas', err);
    throw AppError.fromGraphError(err);
  }
};
