import http from './httpService';
import AppError from 'utils/appError';
import {
  mapFromKPI,
  mapFromKPIAlert,
  mapFromKPIContext,
  mapFromKPIDataTransform,
  mapFromKPIDataTransformLogs,
  mapFromKPIDataTransformResponses,
  mapFromKPIs,
  mapToKPI,
  mapToKPIAlert,
  mapToKPIData,
  mapToKPIDataTransform,
  mapToKPIGraphDataConfig,
} from 'mappings/kpiMapping';
import KPIDTO from 'models/dto/kpi/kpiDTO';
import KPI, { KPIGraphDataConfig } from 'models/kpi/kpi';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import KPIContextDTO from 'models/dto/kpi/KPIContextDTO';
import KPIContext from 'models/kpi/KPIContext';
import KPIAlert from 'models/kpi/KPIAlert';
import KPIAlertDTO from 'models/dto/kpi/KPIAlertDTO';
import IdListDTO from 'models/dto/IdListDTO';
import KPIData from 'models/kpi/kpiData';
import KPIDataTransform from 'models/kpi/kpiDataTransform';
import KPIDataTransformDTO from 'models/dto/kpi/kpiDataTransformDTO';
import KPIDataTransformResponseDTO from 'models/kpi/kpiDataTransformResponse';
import KPIDataTransformResponse from 'models/kpi/kpiDataTransformResponse';
import Task from 'models/tasks/task';
import { mapFromTask } from 'mappings/taskMapping';
import TaskDTO from 'models/dto/tasks/taskDTO';
import KPIDataTransformLog from 'models/kpi/kpiDataTransformLog';
import KPIDataTransformLogDTO from 'models/dto/kpi/kpiDataTransformLogDTO';
import EntityDTO from 'models/dto/entityDTO';
import { mapFromEntities } from 'mappings/activityMapping';
import Entity from 'models/entity';

export async function apiGetKPI(kpiId: number, accessToken: string, cache: GlobalDataCache | undefined): Promise<KPI> {
  try {
    const ar = await http.get<KPIDTO>(`/kpis/${kpiId}`, http.getRequestConfig(accessToken));

    return mapFromKPI(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIWithData(
  kpiId: number,
  config: KPIGraphDataConfig,
  accessToken: string,
  cache: GlobalDataCache | undefined,
): Promise<KPI> {
  try {
    const configDto = mapToKPIGraphDataConfig(config);
    const ar = await http.post<KPIDTO>(`/kpis/${kpiId}/data`, configDto, http.getRequestConfig(accessToken));

    return mapFromKPI(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteKPIData(kpiId: number, ids: number[], accessToken: string): Promise<void> {
  try {
    const idList = new IdListDTO(ids.map((i) => i.toString()));
    await http.post(`/kpis/${kpiId}/data/delete`, idList, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIs(accessToken: string, cache: GlobalDataCache | undefined): Promise<KPI[]> {
  try {
    const ar = await http.get<KPIDTO[]>(`/kpis`, http.getRequestConfig(accessToken));

    return mapFromKPIs(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddKPI(kpi: KPI, accessToken: string, cache: GlobalDataCache): Promise<KPI> {
  try {
    const kPIDTO = mapToKPI(kpi);
    const ar = await http.post<KPIDTO>(`/kpis`, kPIDTO, http.getRequestConfig(accessToken));

    return mapFromKPI(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveKPI(kpiId: number, accessToken: string): Promise<boolean> {
  try {
    await http.post<KPIDTO>(`/kpis/delete/${kpiId}`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateKPI(kpi: KPI, accessToken: string, cache: GlobalDataCache): Promise<KPI> {
  try {
    const kPIDTO = mapToKPI(kpi);
    const ar = await http.put<KPIDTO>(`/kpis`, kPIDTO, http.getRequestConfig(accessToken));

    return mapFromKPI(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIContext(
  kpiId: number,
  accessToken: string,
  globalDataCache: GlobalDataCache,
): Promise<KPIContext> {
  try {
    const ar = await http.get<KPIContextDTO>(`/kpis/context/${kpiId}`, http.getRequestConfig(accessToken));

    return mapFromKPIContext(ar.data, globalDataCache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIWithAlerts(
  kpiId: number,
  accessToken: string,
  cache: GlobalDataCache | undefined,
): Promise<KPI> {
  try {
    const ar = await http.get<KPIDTO>(`/kpis/${kpiId}/alerts`, http.getRequestConfig(accessToken));

    return mapFromKPI(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddKPIAlert(
  kpiAlert: KPIAlert,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<KPIAlert> {
  try {
    const KPIAlertDTO = mapToKPIAlert(kpiAlert);
    const ar = await http.post<KPIAlertDTO>(`/kpis/alerts`, KPIAlertDTO, http.getRequestConfig(accessToken));

    return mapFromKPIAlert(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveKPIAlert(kpiId: number, kpiAlertId: number, accessToken: string): Promise<boolean> {
  try {
    await http.post(`/kpis/${kpiId}/alerts/${kpiAlertId}/delete/`, null, http.getRequestConfig(accessToken));

    return true;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateKPIAlert(
  kpiAlert: KPIAlert,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<KPIAlert> {
  try {
    const KPIAlertDTO = mapToKPIAlert(kpiAlert);
    const ar = await http.put<KPIAlertDTO>(`/kpis/alerts`, KPIAlertDTO, http.getRequestConfig(accessToken));

    return mapFromKPIAlert(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateKPIData(data: KPIData, accessToken: string): Promise<void> {
  try {
    const kpiDataDto = mapToKPIData([data]);
    await http.put(`/kpis/data`, kpiDataDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIDataTransform(
  kpiId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<KPIDataTransform | undefined> {
  try {
    const ar = await http.get<KPIDataTransformDTO>(`/kpis/${kpiId}/transform`, http.getRequestConfig(accessToken));

    return mapFromKPIDataTransform(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateKPIDataTransform(
  kpiDataTransform: KPIDataTransform,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<KPIDataTransform | undefined> {
  try {
    const kpiDataTransformDto = mapToKPIDataTransform(kpiDataTransform);
    const ar = await http.put<KPIDataTransformDTO>(
      `/kpis/transform`,
      kpiDataTransformDto,
      http.getRequestConfig(accessToken),
    );

    return mapFromKPIDataTransform(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiApplyKPIDataTransform(
  kpiDataTransform: KPIDataTransform,
  accessToken: string,
): Promise<KPIDataTransformResponse[]> {
  try {
    const kpiDataTransformDto = mapToKPIDataTransform(kpiDataTransform);
    const ar = await http.post<KPIDataTransformResponseDTO[]>(
      `/kpis/data/transform`,
      kpiDataTransformDto,
      http.getRequestConfig(accessToken),
    );

    return mapFromKPIDataTransformResponses(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiTestKPINotAcceptedEvidenceEvent(
  kpiDataTransform: KPIDataTransform,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Task | undefined> {
  try {
    const kpiDataTransformDto = mapToKPIDataTransform(kpiDataTransform);
    const ar = await http.post<TaskDTO>(
      `/kpis/data/transform/test`,
      kpiDataTransformDto,
      http.getRequestConfig(accessToken),
    );

    if (ar.data) {
      return mapFromTask(ar.data, cache);
    } else {
      return undefined;
    }
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIDataTransformLogs(kpiId: number, accessToken: string): Promise<KPIDataTransformLog[]> {
  try {
    const ar = await http.get<KPIDataTransformLogDTO[]>(
      `/kpis/${kpiId}/transform/logs`,
      http.getRequestConfig(accessToken),
    );

    return mapFromKPIDataTransformLogs(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteKPITransformLogs(
  kpiId: number,
  kpiDataTransformLogIds: number[],
  accessToken: string,
): Promise<void> {
  try {
    const idListDto = new IdListDTO(kpiDataTransformLogIds.map((s) => s.toString()));
    await http.post(`/kpis/${kpiId}/transform/logs/delete`, idListDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiReprocessKPITransformLogs(
  kpiDataTransformLogIds: number[],
  accessToken: string,
): Promise<void> {
  try {
    const idListDto = new IdListDTO(kpiDataTransformLogIds.map((s) => s.toString()));
    await http.post(`/kpis/transform/logs/reprocess`, idListDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIUsage(kpiId: number, accessToken: string): Promise<Entity[]> {
  try {
    const ar = await http.get<EntityDTO[]>(`/kpis/${kpiId}/usage`, http.getRequestConfig(accessToken));

    return mapFromEntities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetKPIName(name: string, accessToken: string): Promise<number> {
  try {
    const ar = await http.post<number>(`/kpis/name`, { valueString: name }, http.getRequestConfig(accessToken));
    const id = Number(ar.data);

    return id;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
