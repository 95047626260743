import React from 'react';
import { Link, Text } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { globalStackTokensGapSmall } from 'globalStyles';
import { navigateToExternalUrl } from 'utils/url';

interface IOutlookAttachmentErrorProps {
  text: string;
  taskUrl: string;
}

const OutlookAttachmentError: React.FunctionComponent<IOutlookAttachmentErrorProps> = (
  props: IOutlookAttachmentErrorProps
) => {
  return (
    <Stack
      verticalFill
      styles={{
        root: {
          position: 'absolute',
          margin: 'auto',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 10,
          zIndex: 999,
        },
      }}
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Stack.Item>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Text variant="medium">{props.text}</Text>
            {props.taskUrl && (
              <Link
                onClick={() => {
                  navigateToExternalUrl(props.taskUrl, '', '');
                }}
              >
                {props.taskUrl}
              </Link>
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default OutlookAttachmentError;
