import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Config from '../Config/configService';

export const i18nBase = i18n;

function init() {
  const backendBasePath = Config.getAppURL();
  console.log(`i18n init backend from: ${backendBasePath}`);

  i18n
    // Enables the i18next backend
    .use(Backend)
    // Enables the hook initialization module
    .use(initReactI18next)
    // Enable automatic language detection
    .use(LanguageDetector)

    .init({
      // Allowed languages
      // Make sure the supported 2-letter languages are also in this list for when the user overrides the Microsoft account setting
      // 2-letter languages must be at the end of the array
      supportedLngs: ['nl-NL', 'en-EN', 'en-GB', 'en-US', 'de-DE', 'en', 'nl', 'de'],
      // Standard language used
      load: 'all',
      preload: ['en'],
      fallbackLng: 'en',
      debug: !Config.isProd(),
      //Detects and caches a storageKey from the language provided
      detection: {
        order: ['storageKey', 'localStorage', 'htmlTag'],
        caches: ['storageKey', 'localStorage'],
      },
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${backendBasePath}/locales/{{lng}}/{{ns}}.json`,
      },
    });
}

const localizationService = { init };

export default localizationService;
