export class KPIGraphDataPoint {
  date: Date | undefined;

  value: number | undefined;
}

export class KPIGraphDataStackedPoint {
  date: Date | undefined;

  values: { [key: string]: number };

  constructor() {
    this.values = {};
  }
}

export default class KPIGraphData {
  points: KPIGraphDataPoint[];

  stackedPoints: KPIGraphDataStackedPoint[];

  constructor() {
    this.points = [];
    this.stackedPoints = [];
  }

  clone(): KPIGraphData {
    const output = new KPIGraphData();
    output.points = [...this.points];
    output.stackedPoints = [...this.stackedPoints];

    return output;
  }
}
