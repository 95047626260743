import { ReactNode } from 'react';
import {
  Dialog,
  DialogType,
  DialogFooter,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  IDialogStyles,
} from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

export interface IDialogYesNoProps {
  onYes: () => void;
  onNo: () => void;
  hidden: boolean;
  title: string;
  subText: string;
  yesText?: string;
  noText?: string;
  disableYes?: boolean;
  dialogStyles?: Partial<IDialogStyles>;
  optionalJSX?: JSX.Element;
  optionalJSXGrow?: boolean;
  optionalJSXHeight?: string | number;
  optionalJSXWidth?: string | number;
  children?: ReactNode;
}

const DialogYesNo = (props: IDialogYesNoProps) => {
  const { t } = useTranslation(['translation']);

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    subText: props.subText,
    showCloseButton: true,
  };

  return (
    <Dialog
      hidden={props.hidden}
      onDismiss={props.onNo}
      dialogContentProps={dialogContentProps}
      styles={props.dialogStyles}
    >
      {props.optionalJSX && !props.optionalJSXGrow && (
        <Stack>
          <Stack.Item
            grow
            styles={{
              root: {
                marginTop: 0,
                position: 'relative',
                width: props.optionalJSXWidth,
                height: props.optionalJSXHeight ?? '20vh',
                maxHeight: '80vh',
              },
            }}
          >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{props.optionalJSX}</ScrollablePane>
          </Stack.Item>
        </Stack>
      )}
      {props.optionalJSX && props.optionalJSXGrow === true && (
        <Stack>
          <Stack.Item
            grow
            styles={{
              root: {
                marginTop: 0,
                maxHeight: '80vh',
              },
            }}
          >
            {props.optionalJSX}
          </Stack.Item>
        </Stack>
      )}
      <DialogFooter>
        <PrimaryButton
          disabled={props.disableYes}
          onClick={props.onYes}
          text={props.yesText ?? t('translation:Dialogs.YesNo.Yes')}
        />
        <DefaultButton onClick={props.onNo} text={props.noText ?? t('translation:Dialogs.YesNo.No')} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogYesNo;
