import CurrentUser from 'models/currentuser';
import RiskState from 'models/riskState';
import UserLanguage from 'models/userLanguage';
import { EntityCache, ICache } from './entityCache';

export class RiskStateCache extends EntityCache<RiskState> implements ICache<RiskState, RiskStateCache> {
  constructor() {
    super();
    this.getId = (state) => state.riskStateId;
    this.getEmptyItem = () => new RiskState();
  }

  private sort() {
    this.items.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  async getItems(refresh: boolean = false): Promise<RiskState[]> {
    //not implemented

    return this.items;
  }

  setCurrentUserLanguage(user: CurrentUser) {
    //set the translations for the current user
    this.items.forEach((i) => {
      if (user.language.code) {
        i.transIdx = i.getTransIdx(user.language.code, UserLanguage.getFallBack());
        if (i.transIdx === -1 && i.trans.length > 0) {
          i.transIdx = 0;
        }
        if (i.transIdx >= 0) {
          i.state = i.trans[i.transIdx].state;
        }
      }
    });
    this.sort();
  }

  clone(): RiskStateCache {
    const riskStateCache: RiskStateCache = new RiskStateCache();
    riskStateCache.items = this.items?.map((u) => u.clone());

    return riskStateCache;
  }
}
