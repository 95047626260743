import AuditTrailAction_Translation from './auditTrailAction_Translation';

export default class AuditTrailAction {
  auditTrailActionId: number;

  code: string;

  trans: AuditTrailAction_Translation[];

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  constructor() {
    this.auditTrailActionId = 0;
    this.code = '';
    this.trans = [];
    this.name = '';
    this.transIdx = 0;
  }
}
