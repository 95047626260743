import GroupDTO from 'models/dto/groupDTO';
import { GroupMembersDTO } from 'models/dto/groupMembersDTO';
import { GroupMember } from 'models/groupMembers';
import Group from 'models/group';
import { TeamsChannel } from 'models/teamsChannel';
import { TeamsChannelDTO } from 'models/dto/teamsChannelDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';

export function mapToGroups(group: Group[]): GroupDTO[] {
  return group.map((groupItem) => {
    return mapToGroup(groupItem);
  });
}

export function mapFromGroups(groupDTO: GroupDTO[], cache: GlobalDataCache | undefined): Group[] {
  return groupDTO.map((groupDTOItem) => {
    return mapFromGroup(groupDTOItem, cache) as Group;
  });
}

export function mapFromGroup(groupDTO: GroupDTO | undefined, cache: GlobalDataCache | undefined): Group | undefined {
  if (!groupDTO) {
    return undefined;
  }

  const output = new Group();
  output.id = groupDTO.groupId;
  output.name = groupDTO.name;
  output.email = groupDTO.email;
  output.syncEnabled = groupDTO.syncEnabled;
  output.syncStatus = groupDTO.syncStatus;
  output.syncError = groupDTO.syncError;
  output.syncMemberCount = groupDTO.syncMemberCount;

  if (cache && groupDTO.userIds) {
    output.users = cache.users.getItemsForId(groupDTO.userIds?.idList);
  }

  return output;
}

export function mapToGroup(group: Group): GroupDTO {
  const output = new GroupDTO();
  output.groupId = group.id;
  output.name = group.name;
  output.email = group.email;
  output.syncEnabled = group.syncEnabled;

  return output;
}

export function mapFromSingleGroupMember(member: GroupMembersDTO): GroupMember {
  const output = new GroupMember();
  output.groupId = member.groupId;
  output.members = [...member.members];

  return output;
}

export function mapFromGroupMember(member: GroupMembersDTO[]): GroupMember[] {
  return member.map((_info) => mapFromSingleGroupMember(_info));
}

export function mapFromTeamChannels(channelDTOs: TeamsChannelDTO[]): TeamsChannel[] {
  return channelDTOs.map((channel) => mapFromTeamChannel(channel));
}

export function mapFromTeamChannel(channelDTO: TeamsChannelDTO): TeamsChannel {
  const output = new TeamsChannel();
  output.channelId = channelDTO.channelId;
  output.name = channelDTO.name;

  return output;
}
