import { Approval } from 'models/approval';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { mapFromTask, mapFromTasksArray } from './taskMapping';
import TaskDTO from 'models/dto/tasks/taskDTO';

export function mapFromApprovals(approvalDTOs: TaskDTO[] | undefined, cache: GlobalDataCache): Approval[] {
  if (!approvalDTOs) return [];

  const output = approvalDTOs.map((item) => {
    return mapFromApproval(item, cache);
  });

  return output;
}

export function mapFromApproval(approvalDTO: TaskDTO, cache: GlobalDataCache): Approval {
  const output = new Approval();

  output.mainTask = mapFromTask(approvalDTO, cache);
  output.approvalTasks = mapFromTasksArray(approvalDTO.instances, cache);

  return output;
}
