import React, { createRef, forwardRef, useEffect, useState } from 'react';
import {
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
  IPickerItemProps,
  Stack,
  ValidationState,
  ISuggestionItemProps,
  IBasePicker,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalSuggestionsMaxRecords } from 'globalConstants';
import { ISingleTag, SingleTag } from 'components/Tags/SingleTag';
import { TagInvalidChars } from 'models/tag';

interface ISingleTagPickerProps {
  tags: ISingleTag[];
  selectedTags?: ISingleTag[];
  itemLimit?: number;
  disabled?: boolean;
  onRemove?: (item: ISingleTag) => void;
  onAdd?: (item: ISingleTag, isNew: boolean) => void;
  onCreate?: (item: ISingleTag) => void;
  isLoading?: boolean;
  colorForNewItems?: string;
  preventTabKey?: boolean;
  defaultValue?: string | undefined;
}

const SingleTagPicker = forwardRef((props: ISingleTagPickerProps, ref) => {
  const { t } = useTranslation(['translation', 'tag']);
  const [selectedTags, setSelectedTags] = useState<ISingleTag[] | undefined>(props.selectedTags);
  const tagPickerRef = createRef<IBasePicker<ITag>>();

  useEffect(() => {
    setSelectedTags(props.selectedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTags]);

  const getITag = (tag: ISingleTag): ITag => {
    return {
      name: tag.tagName,
      key: tag.tagId.toString(),
    };
  };

  const getITags = (tags: ISingleTag[] | undefined): ITag[] | undefined => {
    return tags?.map((t) => getITag(t));
  };

  const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
    if (filter && props.tags) {
      const filterLower = filter.toLowerCase();

      return props.tags
        .filter(
          (tag: ISingleTag) =>
            !selectedTags?.some((t) => t.tagId === tag.tagId) && tag.tagName.toLowerCase().includes(filterLower),
        )
        .sort((a, b) => {
          return a.tagName.localeCompare(b.tagName);
        })
        .map((tag: ISingleTag): ITag => {
          return getITag(tag);
        });
    }

    return [];
  };

  const suggestionProps: IBasePickerSuggestionsProps = {
    showRemoveButtons: false,
    resultsMaximumNumber: globalSuggestionsMaxRecords,
    noResultsFoundText: t('translation:General.Suggestions.NoData'),
  };

  const onRenderSuggestionsItem = (pickerProps: ITag, itemProps: ISuggestionItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps;
    if (!props.tags) return <span />;

    const tag = props.tags.find((t) => t.tagId.toString() === tagItem.key);
    if (!tag) return <span />;

    return <SingleTag tag={tag} />;
  };

  const removeSelectedItem = (tag: ISingleTag) => {
    const items = selectedTags?.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(items);
    if (props.onRemove) {
      props.onRemove(tag);
    }
  };

  const onRenderItem = (pickerProps: IPickerItemProps<ITag>): JSX.Element => {
    const tagItem = pickerProps.item;
    if (!selectedTags) return <span />;

    const tag = selectedTags.find((t) => t.tagId.toString() === tagItem.key);
    if (!tag) return <span />;

    return <SingleTag tag={tag} onRemove={removeSelectedItem} />;
  };

  const onSelectItem = (item?: ITag | undefined) => {
    if (item && item.name) {
      if (!item.key) {
        item.key = item.name;
        const newTag: ISingleTag = {
          tagId: item.key,
          tagName: item.name,
          tagColor: props.colorForNewItems || '#aaaaaa',
        };
        const newSelectedTags = selectedTags?.slice(0) || [];
        newSelectedTags.push(newTag);
        setSelectedTags(newSelectedTags);
        if (props.onAdd) {
          props.onAdd(newTag, true);
        }
      } else {
        const tag = props.tags?.find((t) => t.tagId.toString() === item.key);
        if (tag) {
          const newSelectedTags = selectedTags?.slice(0) || [];
          newSelectedTags.push(tag);
          setSelectedTags(newSelectedTags);
          if (props.onAdd) {
            props.onAdd(tag, false);
          }
        }
      }

      return item;
    }

    return null;
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (props.preventTabKey && e.key === 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <Stack onKeyDown={onKeyPress}>
      <TagPicker
        ref={ref}
        inputProps={{ defaultVisibleValue: props.defaultValue }}
        componentRef={tagPickerRef}
        itemLimit={props.itemLimit}
        onResolveSuggestions={onResolveSuggestions}
        pickerSuggestionsProps={suggestionProps}
        selectedItems={getITags(selectedTags)}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        disabled={props.disabled}
        onRenderItem={onRenderItem}
        createGenericItem={(input: string) => {
          //replace invalid characters
          TagInvalidChars.forEach(c => input = input.replaceAll(c, '-'));
          
          return { key: '', name: input.trim() } as ITag;
        }}
        onItemSelected={onSelectItem}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
        onBlur={() => tagPickerRef.current?.completeSuggestion(true)}
      />
    </Stack>
  );
});

export default SingleTagPicker;
