import { newGuidNil } from 'utils/guid';

export default class ResourceLinkChannel {
  linkId: number;

  groupId: string;

  channelId?: string;

  pinned: boolean;

  constructor() {
    this.linkId = -1;
    this.groupId = newGuidNil();
    this.pinned = false;
  }

  clone(): ResourceLinkChannel {
    const newResourceLinkChannel = new ResourceLinkChannel();
    newResourceLinkChannel.linkId = this.linkId;
    newResourceLinkChannel.groupId = this.groupId;
    newResourceLinkChannel.channelId = this.channelId;
    newResourceLinkChannel.pinned = this.pinned;

    return newResourceLinkChannel;
  }
}
