import { mapFromSettings, mapFromSettingsToUserSettings } from './settingMapping';
import { mapFromUsers } from 'mappings/userMapping';
import { mapFromGroups } from 'mappings/groupMapping';
import GlobalDataCacheDTO from 'models/dto/globalDataCacheDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { mapFromRiskStates } from './riskMapping';
import { mapFromTaskStates } from './taskMapping';
import { mapFromTaskTypes } from './taskTypeMapping';
import { mapFromAuthSchemas, mapFromRoles } from './authMapping';

export function mapFromGlobalDataCache(globalDataCacheDTO: GlobalDataCacheDTO): GlobalDataCache {
  const globalDataCache = new GlobalDataCache();

  if (globalDataCacheDTO) {
    // users in groups are not loaded due to load order
    // this is not an issue at the moment
    if (globalDataCacheDTO.groups) {
      globalDataCache.groups.items = mapFromGroups(globalDataCacheDTO.groups, globalDataCache);
    }

    //roles and groups of users are not loaded
    // this is not an issue at the moment
    if (globalDataCacheDTO.users) {
      globalDataCache.users.items = mapFromUsers(globalDataCacheDTO.users, globalDataCache);
    }

    // groups of users are loaded
    if (globalDataCacheDTO.roles) {
      globalDataCache.roles.items = mapFromRoles(globalDataCacheDTO.roles, globalDataCache);
    }

    if (globalDataCacheDTO.settings) {
      globalDataCache.settings = mapFromSettings(globalDataCacheDTO.settings);
    }

    if (globalDataCacheDTO.userSettings) {
      globalDataCache.userSettings = mapFromSettingsToUserSettings(globalDataCacheDTO.userSettings);
    }

    if (globalDataCacheDTO.riskStates) {
      globalDataCache.riskStates.items = mapFromRiskStates(globalDataCacheDTO.riskStates);
    }

    if (globalDataCacheDTO.taskStates) {
      globalDataCache.taskStates.items = mapFromTaskStates(globalDataCacheDTO.taskStates);
    }

    if (globalDataCacheDTO.taskTypes) {
      globalDataCache.taskTypes.items = mapFromTaskTypes(globalDataCacheDTO.taskTypes);
    }

    if (globalDataCacheDTO.authSchemas) {
      globalDataCache.authSchemas.items = mapFromAuthSchemas(globalDataCacheDTO.authSchemas);
    }
  }

  return globalDataCache;
}
