import AuditTrailEntry from './auditTrailEntry';

export default class AuditTrail {
  tenantId: string;

  auditTrailId: number;

  entries: AuditTrailEntry[];

  constructor() {
    this.tenantId = '';
    this.auditTrailId = 0;
    this.entries = [];
  }
}
