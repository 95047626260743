import Language from '../language';

export class TaskTypeFormElement_Translation {
  taskTypeId: number;

  fieldId: number;

  languageId: number;

  name: string;

  description?: string;

  expectedResult?: string;

  instruction?: string;

  lang?: Language;

  constructor() {
    this.taskTypeId = -1;
    this.fieldId = -1;
    this.languageId = -1;
    this.name = '';
  }
}
