import CurrentUser from 'models/currentuser';
import UserLanguage from 'models/userLanguage';
import { EntityCache, ICache } from './entityCache';
import { TaskState } from 'models/tasks/taskHelperClasses';

export class TaskStateCache extends EntityCache<TaskState> implements ICache<TaskState, TaskStateCache> {
  constructor() {
    super();
    this.getId = (state) => state.taskStateId;
    this.getEmptyItem = () => new TaskState();
  }

  private sort() {
    this.items.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  setCurrentUserLanguage(user: CurrentUser) {
    //set the translations for the current user
    this.items.forEach((i) => {
      if (user.language.code) {
        i.transIdx = i.getTransIdx(user.language.code, UserLanguage.getFallBack());
        if (i.transIdx === -1 && i.trans.length > 0) {
          i.transIdx = 0;
        }
        if (i.transIdx >= 0) {
          i.state = i.trans[i.transIdx].state;
        }
      }
    });
    this.sort();
  }

  async getItems(refresh: boolean = false): Promise<TaskState[]> {
    //not implemented

    return this.items;
  }

  clone(): TaskStateCache {
    const taskStateCache: TaskStateCache = new TaskStateCache();
    taskStateCache.items = this.items?.map((u) => u.clone());

    return taskStateCache;
  }
}
