import React from 'react';
import { Spinner, SpinnerSize, getTheme, Text, ProgressIndicator } from '@fluentui/react';
import { Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapSmall } from 'globalStyles';

interface IOverlayLoaderProps {
  text?: string;
  showProgress?: boolean;
  progressPercent?: number;
  progressLabel?: string;
}

const OutlookOverlayLoader: React.FunctionComponent<IOverlayLoaderProps> = (props: IOverlayLoaderProps) => {
  const appContext = React.useContext(AppContext);
  const theme = getTheme();

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          position: 'absolute',
          margin: 'auto',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 999,
        },
      }}
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Stack.Item
        styles={{
          root: {
            padding: 20,
            border: '2px solid',
            borderColor: appContext.useDarkMode ? theme.palette.themeDarkAlt : theme.palette.themeLighterAlt,
            background: appContext.useDarkMode ? theme.palette.themeDarker : theme.palette.themeLighter,
          },
        }}
      >
        <Stack tokens={globalStackTokensGapSmall}>
          <Spinner styles={{ root: { paddingBottom: props.text ? 5 : undefined } }} size={SpinnerSize.large}></Spinner>
          {props.text && <Text variant="medium">{props.text}</Text>}
          {props.showProgress && (
            <ProgressIndicator barHeight={6} label={props.progressLabel} percentComplete={props.progressPercent} />
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default OutlookOverlayLoader;
