import ResourceListDTO from 'models/dto/resourceListDTO';
import ResourceList from 'models/resourceList';

export function mapFromResourceList(list: ResourceListDTO): ResourceList {
  const output = new ResourceList();
  output.listId = list.listId;
  output.authSchemaId = list.authSchemaId;
  output.listType = list.listType;
  output.systemListId = list.systemListId;
  output.name = list.name;
  output.webURL = list.webURL;
  output.altTenantId = list.altTenantId;
  output.altTenantName = list.altTenantName;
  output.spListId = list.spListId;
  output.spSiteId = list.spSiteId;
  output.spDriveId = list.spDriveId;
  output.spDriveItemId = list.spDriveItemId;
  output.columnProps = list.columnProps;
  output.enableMetaData = list.enableMetaData;
  output.isVirtual = list.isVirtual;

  return output;
}

export function mapToResourceList(list: ResourceList): ResourceListDTO {
  const output = new ResourceListDTO();
  output.listId = list.listId;
  output.authSchemaId = list.authSchemaId;
  output.listType = list.listType;
  output.systemListId = list.systemListId;
  output.name = list.name;
  output.webURL = list.webURL;
  output.altTenantId = list.altTenantId;
  output.altTenantName = list.altTenantName;
  output.spListId = list.spListId;
  output.spSiteId = list.spSiteId;
  output.spDriveId = list.spDriveId;
  output.spDriveItemId = list.spDriveItemId;
  output.columnProps = list.columnProps;
  output.enableMetaData = list.enableMetaData;
  output.isVirtual = list.isVirtual;
  
  return output;
}

export function mapFromResourceLists(lists: ResourceListDTO[]): ResourceList[] {
  return lists.map((_list) => {
    return mapFromResourceList(_list);
  });
}
