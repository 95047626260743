import 'react-toastify/dist/ReactToastify.css';
import AppRouting from './AppRouting';
import AppContextProvider from './AppContextProvider';

export interface IAppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}

const App = (props: IAppProps) => {
  //
  // Main render
  //
  return (
    <AppContextProvider {...props} >
      <AppRouting></AppRouting>
    </AppContextProvider>
  );
};

export default App;