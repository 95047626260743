import { ISONormUpgradeLogDTO, ISONormUpgradeResultDTO } from 'models/dto/normDTO';
import NormDTO from 'models/dto/normDTO';
import Norm, { ISONormUpgradeLog, ISONormUpgradeResult } from 'models/norm';
import http from './httpService';
import {
  mapFromISONormUpgradeLogs,
  mapFromISONormUpgradeResult,
  mapFromNorm,
  mapFromNorms,
  mapToNorm,
} from 'mappings/dataMapping';
import AppError from 'utils/appError';
import { toBlob } from 'utils/string';
import { sortOnString } from 'utils/sorting';

export async function apiGetNorms(iso: boolean | undefined, activeOnly: boolean, accessToken: string): Promise<Norm[]> {
  try {
    let query: string = `/norms?&activeOnly=${activeOnly}`;
    if (iso !== undefined) {
      query += `&iso=${iso}`;
    }

    const ar = await http.get<NormDTO[]>(query, http.getRequestConfig(accessToken));
    const norms = mapFromNorms(ar.data);

    return norms.sort((a, b) => sortOnString(a.name, b.name));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateNorm(norm: Norm, accessToken: string): Promise<void> {
  try {
    const normDTO = mapToNorm(norm);
    await http.put<NormDTO[]>(`/norms`, normDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddNorm(norm: Norm, accessToken: string): Promise<Norm> {
  try {
    const normDTO = mapToNorm(norm);
    const ar = await http.post<NormDTO>(`/norms`, normDTO, http.getRequestConfig(accessToken));

    return mapFromNorm(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteNorm(norm: Norm, accessToken: string): Promise<void> {
  try {
    const normDTO = mapToNorm(norm);
    await http.post<NormDTO>(`/norms/delete/${norm.normId}`, normDTO, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpgradeStandard(
  norm: Norm,
  upgradeSharePoint: boolean,
  accessToken: string,
): Promise<ISONormUpgradeResult> {
  try {
    const normDTO = mapToNorm(norm);
    const ar = await http.post<ISONormUpgradeResultDTO>(
      `/norms/upgrade?sharepoint=${upgradeSharePoint}`,
      normDTO,
      http.getRequestConfig(accessToken),
    );

    return mapFromISONormUpgradeResult(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetStandardUpgradeLog(norm: Norm, accessToken: string): Promise<ISONormUpgradeLog[]> {
  try {
    const ar = await http.get<ISONormUpgradeLogDTO[]>(
      `/norms/upgrade/log/${norm.normId}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromISONormUpgradeLogs(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateStandardUpgradeLog(log: ISONormUpgradeLog, accessToken: string): Promise<boolean> {
  try {
    const ar = await http.put<boolean>(`/norms/upgrade/log`, log, http.getRequestConfig(accessToken));

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetStandardLogo(normId: number, accessToken: string): Promise<Blob | undefined> {
  try {
    const ar = await http.get(`/norms/logo/${normId}`, http.getRequestConfig(accessToken));

    return toBlob(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateStandardLogo(
  normId: number,
  logo: Blob | undefined,
  accessToken: string,
): Promise<void> {
  try {
    let formData = new FormData();
    if (logo) {
      formData.append('blob', logo);
    }

    await http.put(`/norms/logo/${normId}`, logo ? formData : undefined, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
