import Objective from 'models/objective/objective';
import { Classification_Translation } from './classification_Translation';
import { isObjectEqual } from 'utils/object';
import StringValue from 'models/stringValue/stringValue';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import { sortOnNumber } from 'utils/sorting';

export default class Classification {
  classificationId: number;

  value: number;

  color: string;

  group: StringValue;

  inherited: boolean;

  trans: Classification_Translation[];

  objectives?: Objective[];

  // The following properties are set from the translation arrays
  transIdx: number;

  name: string;

  description?: string;

  examples?: string;

  constructor() {
    this.classificationId = 0;
    this.value = 0;
    this.group = new StringValue();
    this.trans = [];
    this.name = '';
    this.transIdx = -1;
    this.color = Classification.defaultColor;
    this.inherited = false;
  }

  static defaultColor = '#73a367';

  isEqual(item: Classification) {
    if (item.classificationId !== this.classificationId) return false;
    if (item.value !== this.value) return false;
    if (item.transIdx !== this.transIdx) return false;
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.examples !== this.examples) return false;
    if (item.color !== this.color) return false;
    if (item.inherited !== this.inherited) return false;
    if (!isObjectEqual(item.group, this.group)) return false;

    return true;
  }

  clone(): Classification {
    const output = new Classification();
    output.classificationId = this.classificationId;
    output.value = this.value;
    output.transIdx = this.transIdx;
    output.name = this.name;
    output.description = this.description;
    output.examples = this.examples;
    output.color = this.color;
    output.inherited = this.inherited;
    output.group = this.group.clone();
    output.trans = [...this.trans];

    return output;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      group: Joi.string().required().label(localizedFields['group']),
      name: Joi.string().max(512).required().label(localizedFields['name']),
      value: Joi.number().required().min(-99999999).max(99999999).label(localizedFields['value']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate(
      {
        name: this.name,
        group: this.group.value || (this.group.stringValueId === 0 ? undefined : '0'),
        value: this.value,
      },
      { abortEarly: false },
    );
  }

  static getUniqueGroups(items: Classification[]): StringValue[] {
    const classificationGroups: StringValue[] = [];

    for (let idx = 0; idx < items.length; idx++) {
      const item = items[idx];
      const exists = classificationGroups.find((c) => c.stringValueId === item.group.stringValueId);
      if (!exists) {
        classificationGroups.push(item.group);
      }
    }

    return classificationGroups;
  }

  static sortOnGroup(a: Classification, b: Classification): number {
    //1. within the same group sort on value
    //2. sort groups on sort order
    if (a.group.stringValueId === b.group.stringValueId) {
      return sortOnNumber(a.value, b.value);
    } else {
      return sortOnNumber(a.group.sortOrder, b.group.sortOrder);
    }
  }
}
