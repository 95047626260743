import Task from 'models/tasks/task';
import { SystemTaskTypes } from 'models/tasks/taskType';
import { apiGetActiveTaskTemplates } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';

export class TemplateCache extends EntityCache<Task> implements ICache<Task, TemplateCache> {
  private isLoading: boolean = false;

  private templatePromise: Promise<Task[]> | undefined;

  constructor() {
    super();
    this.getId = (template) => template.taskId;
    this.getEmptyItem = () => new Task();
  }

  getItemsForSystemTaskType(systemTaskType: SystemTaskTypes): Task[] {
    const result: Task[] = this.items.filter((i) => i.systemTaskType === systemTaskType);

    return result;
  }

  async getItems(refresh: boolean = false): Promise<Task[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && this.cacheRef && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.templatePromise = apiGetActiveTaskTemplates(accessToken, this.cacheRef);
          this.isLoading = true;
          this.items = await this.templatePromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.templatePromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.templatePromise) {
      return await this.templatePromise;
    } else {
      return this.items;
    }
  }

  clone(): TemplateCache {
    const templateCache: TemplateCache = new TemplateCache();
    templateCache.items = this.items?.map((u) => u.clone());

    return templateCache;
  }
}
