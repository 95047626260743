export default class SettingDTO {
  settingName: string;

  settingValue: string | undefined;

  constructor() {
    this.settingName = '';
    this.settingValue = undefined;
  }
}
