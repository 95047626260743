import React, { Fragment } from 'react';
import { DefaultButton, PrimaryButton, Stack, Text, ActionButton, IButtonProps, Separator } from '@fluentui/react';
import { globalStackTokensGapSmall, templateIcon } from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { OutlookEventTaskDetail } from './OutlookEventTaskDetail';
import Joi from 'joi';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import { IOutlookSingleTaskTypeProps, spinnerIcon } from './OutlookSingleTask';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import OutlookAttachmentError from 'scenes/Task/OutlookAttachmentError';
import OutlookOverlayLoader from 'scenes/Task/OutlookOverlayLoader';
import UserAccount from 'outlookComponents/UserAccount';

interface IOutlookEventTaskProps extends IOutlookSingleTaskTypeProps {
  onSaveAsNewTask: () => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

const OutlookEventTask = (props: IOutlookEventTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent', 'outlook']);
  const [err, setErr] = React.useState<Joi.ValidationError | undefined>(undefined);
  const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);
  }, [t, props.task]);

  React.useEffect(() => {
    props.onChangeIsAttachment(isChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      return fieldError ? fieldError.message : undefined;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
  };

  const onCheckBoxChange = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsChecked(!!checked);
    },
    []
  );

  const footer = (
    <Stack horizontal={false} verticalAlign="space-evenly" tokens={globalStackTokensGapSmall}>
      <Separator styles={{ root: { height: 5 } }} />
      <Stack.Item>
        <Checkbox
          disabled={!props.isAttachmentEnable}
          label={t('outlook:Task.Attachments')}
          checked={isChecked}
          onChange={onCheckBoxChange}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            split
            disabled={hasErrors() || props.isActionPending} //disable save as template
            primaryDisabled={cannotSaveTask() || props.isActionPending}
            onClick={async () => {
              props.onSave(false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            disabled={props.isActionPending} //disable save as template
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
    </Stack>
  );

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const getEventTaskDetails = () => {
    return (
      <Fragment>
        <OutlookEventTaskDetail
          task={props.task}
          orgTask={props.orgTask}
          onUpdate={(newTask: Task) => {
            props.onUpdateTask(newTask);
          }}
          taskStates={props.task.taskStates!}
          getErrorMessage={validate}
          isActionPending={props.isActionPending}
          tags={props.tags}
          addTagToTaskState={props.addTagToTaskState}
          removeTagFromTaskState={props.removeTagFromTaskState}
          createTagState={props.createTagState}
          onChangeCompletionDate={props.onChangeCompletionDate}
        />
      </Fragment>
    );
  };

  if (props.isAttachmentError) {
    return <OutlookAttachmentError text={t('outlook:Task.AttachmentError')} taskUrl={props.taskUrl} />;
  } else
    return (
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={{
          root: {
            padding: '5px 5px 5px 10px',
          },
        }}
      >
        {props.isLoading && <OutlookOverlayLoader text={t('outlook:Task.TaskSaving')} />}
        <Stack.Item>
          <Stack horizontal={false} verticalAlign="space-between">
            <Stack.Item>
              <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                  <Text variant="xxLarge">{props.task.taskId === -1 && t('task:Title.Event.Series.New')}</Text>
                </Stack.Item>
                <Stack.Item>
                  <UserAccount logout={props.logout}></UserAccount>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {props.task.taskId === -1 && (
              <Stack.Item>
                <Stack horizontal>
                  <ActionButton
                    onMenuClick={(
                      ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                      button?: IButtonProps
                    ) => {
                      if (props.templateTasks === undefined) props.loadTemplates();
                    }}
                    menuProps={props.getTemplates}
                    iconProps={templateIcon}
                    text={t('outlook:Task.ChooseTemplate')}
                  />
                  {props.templateTaskLoading && spinnerIcon}
                </Stack>
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
        <Stack.Item grow>{getEventTaskDetails()}</Stack.Item>
        <Stack.Item style={{ paddingBottom: 5 }}>{footer}</Stack.Item>

        <DialogConfirmDelete
          onNo={() => {
            setShowRemoveDialog(false);
          }}
          onYes={() => {
            setShowRemoveDialog(false);
            props.onRemove();
          }}
          title={props.task.isSeries() ? t('task:Template.RemoveDialogTitle') : t('task:Remove.DialogTitle')}
          subText={props.task.isSeries() ? t('task:Template.RemoveDialogSubText') : t('task:Remove.DialogSubTitle')}
          hidden={!showRemoveDialog}
          confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
        />

        <DialogYesNo
          onNo={() => {
            setShowCancelDialog(false);
          }}
          onYes={() => {
            setShowCancelDialog(false);

            props.onCancel();
          }}
          title={t('task:Cancel.DialogTitle')}
          subText={t('task:Cancel.DialogSubTitle')}
          hidden={!showCancelDialog}
        />
      </Stack>
    );
};

export default OutlookEventTask;
