import User from 'models/user';
import { apiGetUsersForCache } from 'services/Api/userService';
import { apiRequest } from 'services/Auth/authConfig';
import { EntityCache, ICache } from './entityCache';

export class UserCache extends EntityCache<User> implements ICache<User, UserCache> {
  private isLoading: boolean = false;

  private usersPromise: Promise<User[]> | undefined;

  constructor() {
    super();
    this.getId = (user) => user.id;
    this.getEmptyItem = (id) => User.getEmptyUser(id as string);
  }

  async getItems(refresh: boolean = false): Promise<User[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          this.isLoading = true;
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.usersPromise = apiGetUsersForCache(accessToken, this.appContext.globalDataCache);
          this.items = await this.usersPromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.usersPromise = undefined;
          this.isLoading = false;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.usersPromise) {
      return await this.usersPromise;
    } else {
      return this.items;
    }
  }

  clone(): UserCache {
    const userCache: UserCache = new UserCache();
    userCache.items = this.items?.map((u) => u.clone());

    return userCache;
  }
}
