import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Persona,
  Callout,
  DirectionalHint,
  mergeStyleSets,
  Link,
  PersonaSize,
  Label,
  Separator,
  Toggle,
} from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { globalStackStylesHeight100PaddingMedium, globalStackTokensGapSmall } from 'globalStyles';
import AppContext from 'App/AppContext';
import { navigateToExternalUrl } from 'utils/url';

interface IUserDialogProps {
  isOpen: boolean;
  logout?: () => void;
  toggleDialog: () => void;
}

const UserAccountDialog: React.FunctionComponent<IUserDialogProps> = (props: IUserDialogProps) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);

  const styles = mergeStyleSets({
    callout: {
      maxWidth: 300,
    },
    button: {
      border: 0,
    },
  });

  return (
    <Callout
      className={styles.callout}
      alignTargetEdge={true}
      gapSpace={0}
      target=".calloutTargetAuthButton"
      isBeakVisible={false}
      directionalHint={DirectionalHint.bottomRightEdge}
      hidden={!props.isOpen}
      onDismiss={props.toggleDialog}
    >
      <Stack styles={globalStackStylesHeight100PaddingMedium} tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Label>{appContext.user.tenant.name}</Label>
        </Stack.Item>
        <Stack.Item>
          <Persona text={appContext.user.name} secondaryText={appContext.user.email} size={PersonaSize.size48} />
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Link onClick={() => navigateToExternalUrl(appContext.user.getMyAccountURL(), '', '')}>
              {t('translation:NavBar.UserDialog.ViewAccount')}
            </Link>
            <Link onClick={() => (props.logout ? props.logout() : undefined)}>
              {t('translation:NavBar.UserDialog.Button-SignOut')}
            </Link>
          </Stack>
        </Stack.Item>
        <Separator />
        <Stack.Item>
          <Label>{t('translation:Settings.Theme-Switch')}</Label>
          <Toggle
            checked={appContext.useDarkMode}
            onText={t('translation:General.Toggle.On')}
            offText={t('translation:General.Toggle.Off')}
            onChange={() => {
              appContext.setUseDarkMode(!appContext.useDarkMode);
            }}
          />
        </Stack.Item>
      </Stack>
    </Callout>
  );
};

interface IUserAccountProps {
  logout?: () => void;
}

const UserAccount: React.FunctionComponent<IUserAccountProps> = (props: IUserAccountProps) => {
  const appContext = useContext(AppContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <IconButton className="calloutTargetAuthButton" onClick={toggleDialog}>
        <Persona text={appContext.user.name} size={PersonaSize.size32} hidePersonaDetails={true} />
      </IconButton>
      <UserAccountDialog logout={props.logout} isOpen={isOpen} toggleDialog={toggleDialog} />
    </Fragment>
  );
};

export default UserAccount;
