import Language from '../language';

export class AuthSchema_Translation {
  authSchemaId: number;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.authSchemaId = 0;
    this.languageId = 0;
    this.name = '';
  }
}
