import { newGuidNil } from 'utils/guid';
import Language from '../language';

export class Role_Translation {
  roleId: string;

  languageId: number;

  name: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.roleId = newGuidNil();
    this.languageId = 0;
    this.name = '';
  }
}
