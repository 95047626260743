import Language from '../language';

export class StringValue_Translation {
  stringValueId: number;

  languageId: number;

  value: string;

  description?: string;

  lang?: Language;

  constructor() {
    this.stringValueId = -1;
    this.languageId = -1;
    this.value = '';
  }
}
