import { EntityTypes } from 'models/entity';
import { fromApiDateTimeOptional } from 'utils/datetime';

export default class KPIDataContext {
  kpiId: number;

  kpiDataId: number;

  entityType: number;

  entityId: number;

  constructor() {
    this.kpiId = 0;
    this.kpiDataId = 0;
    this.entityType = 0;
    this.entityId = 0;
  }

  clone(): KPIDataContext {
    const output = new KPIDataContext();
    output.kpiId = this.kpiId;
    output.kpiDataId = this.kpiDataId;
    output.entityType = this.entityType;
    output.entityId = this.entityId;

    return output;
  }

  isEqual(item: KPIDataContext): boolean {
    if (item.kpiId !== this.kpiId) return false;
    if (item.kpiDataId !== this.kpiDataId) return false;
    if (item.entityType !== this.entityType) return false;
    if (item.entityId !== this.entityId) return false;

    return true;
  }
}

//
// KPI Context filter
//
export class KPIContextFilter {
  group: KPIContextFilterGroup;

  constructor() {
    this.group = new KPIContextFilterGroup();
  }

  count(): number {
    return this.group.count();
  }

  getItems(): KPIContextFilterItem[] {
    return this.group.getItems();
  }

  getItemsForType(type: EntityTypes): KPIContextFilterItem[] {
    return this.group.getItemsForType(type);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getKPIContextFilter = (rawContextFilter: any | undefined): KPIContextFilter => {
    const output = new KPIContextFilter();

    if (rawContextFilter && rawContextFilter.group) {
      output.group = KPIContextFilter.getKPIContextFilterGroup(rawContextFilter.group) ?? new KPIContextFilterGroup();
    }

    return output;
  };

  static getKPIContextFilterGroup = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rawFilterGroup: any | undefined,
  ): KPIContextFilterGroup | undefined => {
    if (!rawFilterGroup) return undefined;

    const output = new KPIContextFilterGroup();

    if (rawFilterGroup.operator) output.operator = rawFilterGroup.operator;

    if (rawFilterGroup?.items) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output.items = rawFilterGroup?.items.map((i: any) => KPIContextFilter.getKPIContextFilterItem(i));
    }

    return output;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getKPIContextFilterItem = (rawFilterItem: any | undefined): KPIContextFilterItem => {
    const output = new KPIContextFilterItem();

    if (rawFilterItem.group) {
      output.group = KPIContextFilter.getKPIContextFilterGroup(rawFilterItem.group);
    }

    if (rawFilterItem.entityType) {
      output.entityType = rawFilterItem.entityType;
      output.entityDateTime = fromApiDateTimeOptional(rawFilterItem.entityDateTime);
      output.entityGuid = rawFilterItem.entityGuid;
      output.entityId = rawFilterItem.entityId;
      output.entityText = rawFilterItem.entityText;
    }

    return output;
  };

  static fromJSON(rawFilter: string): KPIContextFilter {
    const rawObj = JSON.parse(rawFilter);
    const newFilter = KPIContextFilter.getKPIContextFilter(rawObj);

    return newFilter;
  }
}

export enum KPIContextFilterGroupOperator {
  AND = 1,
  OR = 2,
}

export class KPIContextFilterGroup {
  items: KPIContextFilterItem[];

  operator: KPIContextFilterGroupOperator;

  constructor() {
    this.items = [];
    this.operator = KPIContextFilterGroupOperator.AND;
  }

  count(): number {
    return this.items.reduce((sum, current) => (sum += current.count()), 0);
  }

  getItems(): KPIContextFilterItem[] {
    const items: KPIContextFilterItem[] = [];

    this.items.forEach((item) => {
      items.push(...item.getItems());
    });

    return items;
  }

  getItemsForType(type: EntityTypes): KPIContextFilterItem[] {
    const itemsForType: KPIContextFilterItem[] = [];

    this.items.forEach((item) => {
      itemsForType.push(...item.getItemsForType(type));
    });

    return itemsForType;
  }
}

export class KPIContextFilterItem {
  group: KPIContextFilterGroup | undefined;

  entityType: EntityTypes;

  entityId?: number;

  entityGuid?: string;

  entityText?: string;

  entityDateTime?: Date;

  constructor() {
    this.entityType = EntityTypes.NotSet;
  }

  count(): number {
    if (this.group) {
      return this.group.count();
    } else {
      return 1;
    }
  }

  getItems(): KPIContextFilterItem[] {
    const items: KPIContextFilterItem[] = [];

    if (this.group) {
      items.push(...this.group.getItems());
    } else {
      items.push(this);
    }

    return items;
  }

  getItemsForType(type: EntityTypes): KPIContextFilterItem[] {
    const itemsForType: KPIContextFilterItem[] = [];

    if (this.entityType === type) {
      itemsForType.push(this);
    }

    if (this.group) {
      itemsForType.push(...this.group.getItemsForType(type));
    }

    return itemsForType;
  }
}
