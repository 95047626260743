import { IAppContext } from 'App/AppContext';
import { apiGetResourceListName } from 'services/Api/listService';
import { apiRequest } from 'services/Auth/authConfig';
import AppError from 'utils/appError';

export enum ResourceListType {
  DocumentLibrary = 0,
  CustomList = 1,
  WebURL = 2,
  SitePageLibrary = 3,
}

export enum ResourceListSystemType {
  EMailAttachments = 1,
  Evidence = 2,
  Assets = 3,
  Threats = 4,
  BaseEvidence = 5,
}

export default class ResourceList {
  listId: number;

  authSchemaId?: number;

  listType: ResourceListType;

  systemListId?: ResourceListSystemType;

  spSiteId?: string;

  spListId?: string;

  spDriveId?: string;

  spDriveItemId?: string;

  name: string;

  webURL?: string;

  altTenantId?: string;

  altTenantName?: string;

  columnProps?: string;

  enableMetaData?: boolean;

  isVirtual?: boolean;

  constructor() {
    this.listId = -1;
    this.listType = ResourceListType.DocumentLibrary;
    this.spSiteId = '';
    this.spListId = '';
    this.spDriveId = '';
    this.spDriveItemId = '';
    this.name = '';
    this.webURL = '';
  }

  canEnableMetaData = (): boolean => {
    return this.listType === ResourceListType.DocumentLibrary || this.listType === ResourceListType.SitePageLibrary;
  };

  isEqual(item: ResourceList): boolean {
    if (item.listId !== this.listId) return false;
    if (item.listType !== this.listType) return false;
    if (item.spSiteId !== this.spSiteId) return false;
    if (item.spDriveId !== this.spDriveId) return false;
    if (item.spListId !== this.spListId) return false;
    if (item.spDriveItemId !== this.spDriveItemId) return false;
    if (item.name !== this.name) return false;
    if (item.webURL !== this.webURL) return false;
    if (item.altTenantId !== this.altTenantId) return false;
    if (item.altTenantName !== this.altTenantName) return false;
    if (item.columnProps !== this.columnProps) return false;
    if (item.enableMetaData !== this.enableMetaData) return false;
    if (item.isVirtual !== this.isVirtual) return false;
    if (item.authSchemaId !== this.authSchemaId) return false;

    return true;
  }

  clone = (): ResourceList => {
    const output = new ResourceList();

    output.listId = this.listId;
    output.listType = this.listType;
    output.spSiteId = this.spSiteId;
    output.spDriveId = this.spDriveId;
    output.spListId = this.spListId;
    output.spDriveItemId = this.spDriveItemId;
    output.name = this.name;
    output.webURL = this.webURL;
    output.altTenantId = this.altTenantId;
    output.altTenantName = this.altTenantName;
    output.columnProps = this.columnProps;
    output.enableMetaData = this.enableMetaData;
    output.isVirtual = this.isVirtual;
    output.authSchemaId = this.authSchemaId;

    return output;
  };

  static async getUniqueName(orgName: string, appContext: IAppContext): Promise<string> {
    let suffix: number = 1;
    let newName: string = orgName;
    let listExists: number = 1;

    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    while (listExists) {
      const name = newName + ' ' + suffix.toString();
      listExists = await apiGetResourceListName(name, accessToken);
      if (suffix > 100) throw new AppError('Create new list name failed: 100 iterations reached');
      suffix++;
      newName = name;
    }

    return newName;
  }

  static async nameExists(orgName: string, appContext: IAppContext): Promise<number> {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    return await apiGetResourceListName(orgName, accessToken);
  }
}
