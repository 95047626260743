import CurrentUser from 'models/currentuser';
import TaskType, { SystemTaskTypes } from 'models/tasks/taskType';
import UserLanguage from 'models/userLanguage';
import { EntityCache, ICache } from './entityCache';
import { apiRequest } from 'services/Auth/authConfig';
import { apiGetTaskTypes } from 'services/Api/taskService';

export class TaskTypeCache extends EntityCache<TaskType> implements ICache<TaskType, TaskTypeCache> {
  private isLoading: boolean = false;

  private taskTypePromise: Promise<TaskType[]> | undefined;

  constructor() {
    super();
    this.getId = (tasktype) => tasktype.taskTypeId;
    this.getEmptyItem = () => new TaskType();
  }

  getSystem(systemType: SystemTaskTypes): TaskType | undefined {
    const taskType = this.items.find((i) => i.systemTaskType === systemType);
    if (taskType) {
      return taskType;
    } else {
      return undefined;
    }
  }

  getTypeOfSystemType(systemTaskType: SystemTaskTypes): TaskType | undefined {
    const taskType = this.items.find((i) => i.systemTaskType === systemTaskType);
    if (taskType) {
      return taskType;
    } else {
      return undefined;
    }
  }

  getSystemTaskType(taskTypeId: number | undefined): SystemTaskTypes | undefined {
    if (!taskTypeId) return undefined;
    const taskType = this.items.find((i) => i.taskTypeId === taskTypeId);
    if (taskType && taskType.systemTaskType) {
      return taskType.systemTaskType;
    } else {
      return undefined;
    }
  }

  setCurrentUserLanguage(user: CurrentUser) {
    //set the translations for the current user
    this.items.forEach((i) => {
      if (user.language.code) {
        i.transIdx = i.getTransIdx(user.language.code, UserLanguage.getFallBack());
        if (i.transIdx === -1 && i.trans.length > 0) {
          i.transIdx = 0;
        }
        if (i.transIdx >= 0) {
          i.name = i.trans[i.transIdx].name;
          i.description = i.trans[i.transIdx].description;
        }
      }
    });
  }

  async getItems(refresh: boolean = false): Promise<TaskType[]> {
    if (this.items.length === 0 || refresh) {
      if (this.appContext && !this.isLoading) {
        try {
          const accessToken = await this.appContext.getAccessToken(apiRequest.scopes);
          this.taskTypePromise = apiGetTaskTypes(accessToken);
          this.isLoading = true;
          this.items = await this.taskTypePromise;
        } catch (err) {
          this.appContext.setError(err);
        } finally {
          this.isLoading = false;
          this.taskTypePromise = undefined;
        }
      } else {
        this.items = [];
      }
    }

    if (this.isLoading && this.taskTypePromise) {
      return await this.taskTypePromise;
    } else {
      return this.items;
    }
  }

  clone(): TaskTypeCache {
    const taskStateCache: TaskTypeCache = new TaskTypeCache();
    taskStateCache.items = this.items?.map((u) => u.clone());

    return taskStateCache;
  }
}
