import React, { useState } from 'react';
import OutlookNormalTask from './OutlookNormalTask';
import OutlookEventTask from './OutlookEventTask';
import Task, { TaskTypes } from 'models/tasks/task';
import AppError from 'utils/appError';
import ResourceLink from 'models/resourceLink';
import AppContext from 'App/AppContext';
import { apiAddLinkToTask, apiCreateLink, apiGetLinksForTask } from 'services/Api/linkService';
import { apiRequest, graphSharepointLibraryRequest } from 'services/Auth/authConfig';
import { DirectionalHint, IContextualMenuProps, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import {
  apiAddTask,
  apiGetActiveTaskTemplates,
  apiRemoveTask,
  apiRemoveTaskEvent,
  apiGetTaskRescheduleRange,
  apiGetWebLinkForEvent,
  apiUpdateTaskCompleted,
} from 'services/Api/taskService';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import DateRange from 'models/dateRange';
import Tag from 'models/tag';
import { apiAddTagToTask, apiCreateTag, apiGetTagsForTask, apiRemoveTagFromTask } from 'services/Api/tagService';
import { addDateTimeDays, addDateTimeMinutes, getDateTimeDiffMinute } from 'utils/datetime';
import { getOutlookErrorMsg } from 'services/Api/apiErrors';
import { navigateToExternalUrl } from 'utils/url';
import { truncate } from 'utils/string';
import { ResourceListSystemType } from 'models/resourceList';
import { toBlob } from 'utils/string';
import { graphUploadFileToFolder } from 'services/Graph/graphServiceDrive';
import { htmlToText } from 'html-to-text';
import { toast } from 'react-toastify';
import { globalAppPortalUrl } from 'globalConstants';
import { eventIcon, globalStackTokensGapSmall, taskIcon } from 'globalStyles';
import { sortOnString } from 'utils/sorting';
import Logger from 'services/Logging/logService';

export interface IOutlookSingleTaskTypeProps {
  task: Task;
  orgTask: Task;
  onUpdateTask: (task: Task) => void; //called from the component per task type to update the task in the state here
  onUpdateOrgTask: (task: Task) => void; //called from the component per task type to update the task in the state here
  optionalLinks: ResourceLink[];
  copyFromTemplate: (task: Task) => void;
  setOptionalLinks: (links: ResourceLink[]) => void;
  templateTasks: Task[] | undefined;
  templateTaskLoading: boolean;
  getTemplates: IContextualMenuProps;
  loadTemplates: () => void;
  onSave: (schedule: boolean) => void;
  onRemove: () => void;
  onCancel: () => void;
  onCreateNewTaskTemplate: () => void;
  isActionPending: boolean;
  isLoading: boolean;
  setIsActionPending: (actionPending: boolean) => void;
  createInOutlook: () => Promise<void>;
  removeFromOutlook: () => Promise<void>;
  loadRescheduleRange: () => Promise<void>;
  rescheduleDateRange: DateRange | undefined;
  tags: Tag[];
  addTagToTaskState: (tag: Tag) => void;
  removeTagFromTaskState: (tag: Tag) => void;
  createTagState: (tag: Tag) => void;
  navigateToEventURL: (task: Task) => void;
  onChangeCompletionDate: (completionDate: Date) => void;
  onChangeIsAttachment: (isChecked: boolean) => void;
  isAttachmentError: boolean;
  taskUrl: string;
  logout?: () => void;
  isAttachmentEnable: boolean;
}

export const spinnerIcon = <Spinner size={SpinnerSize.small} />;

interface IMailAttachmentsType {
  name: string;
  blobData: Blob | undefined;
}

interface IOutlookSingleTaskProps {
  logout?: () => void;
}

const OutlookSingleTask = (props: IOutlookSingleTaskProps) => {
  const appContext = React.useContext(AppContext);
  const { t } = useTranslation(['outlook', 'translation', 'task']);

  //state for the task is managed here to allow to switch to another task type
  const [task, setTask] = React.useState<Task>(new Task());
  const [orgTask, setOrgTask] = React.useState<Task>(new Task());
  const [optionalLinks, setOptionalLinks] = React.useState<ResourceLink[]>([]); //States for saving links to the task as post save action
  const [templateTasks, setTemplateTasks] = React.useState<Task[] | undefined>(undefined);
  const [templateTaskLoading, setTemplateTaskLoading] = React.useState<boolean>(false);
  const [isActionPending, setIsActionPending] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [rescheduleDateRange, setRescheduleDateRange] = React.useState<DateRange | undefined>(undefined);
  const [taskTags, setTaskTags] = React.useState<Tag[]>([]);
  const [selectedTask, setSelectedTask] = React.useState<Task>(new Task());
  const [emailBodyDesc, setEmailBodyDesc] = React.useState<string>('');
  const [taskName, setTaskName] = React.useState<string>('');
  const [isAttachmentChecked, setIsAttachmentChecked] = React.useState<boolean>(false);
  const [mailAttachments, SetMailAttachments] = useState<IMailAttachmentsType[]>([]);
  const [isDownloadDone, SetIsDownloadDone] = useState<boolean>(false);
  const [isUploadDone, SetIsUploadDone] = useState<boolean>(false);
  const [uploadCount, SetUploadCount] = useState<number>(0);
  const [isAttachmentError, setIsAttachmentError] = useState<boolean>(false);
  const [isAttachmentEnable, setIsAttachmentEnable] = useState<boolean>(false);
  const [uploadAttachments, setUploadAttachments] = useState<boolean>(false);
  const [attachmentCount, setAttachmentCount] = React.useState<number>(0);
  const [taskUrl, setTaskUrl] = React.useState<string>('');

  const currentUserId = appContext.user.id;

  React.useEffect(() => {
    getOutlookData();
    const getList = async () => {
      await appContext.globalDataCache.lists.getItems();
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (appContext.globalDataCache.lists.items && appContext.globalDataCache.lists.items?.length > 0) {
      const myList = appContext.globalDataCache.lists.getSystem(ResourceListSystemType.EMailAttachments);

      const outlookItem = Office.context.mailbox.item;
      if (outlookItem) {
        const attachmentsToDownload = outlookItem.attachments.filter((x) => !x.isInline);

        //  along with resource list we need to make sure we have attachment also in email body to attach
        if (myList && attachmentsToDownload.length > 0) {
          setIsAttachmentEnable(true);
        } else {
          setIsAttachmentEnable(false);
        }
      }
    } else {
      setIsAttachmentEnable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.globalDataCache.lists.items]);

  React.useEffect(() => {
    addNewTask();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailBodyDesc]);

  React.useEffect(() => {
    if (selectedTask) {
      setTaskTags([]); //first clear them, otherwise previous tags could be visible shortly
      loadTags(selectedTask);
      setTask(selectedTask.clone());
      setOrgTask(selectedTask.clone());
      setRescheduleDateRange(undefined);
      setOptionalLinks([]);
      setIsActionPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFinalAttachmentsCallback = async (result: any, filename: string) => {
    // Parse string to be a url, an .eml file, a base64-encoded string, or an .icalendar file.
    switch (result.value.format) {
      case Office.MailboxEnums.AttachmentContentFormat.Base64:
        try {
          const blob = toBlob(result.value.content);
          if (blob) {
            SetMailAttachments((curAtt) => [...curAtt, { name: filename, blobData: blob }]);
          }
        } catch (err) {
          Logger.debug(`Error while getting blob for file ${filename}`, err);
          setIsAttachmentError(true);
          setUploadAttachments(false);
        }

        break;

      default:
      // Handle attachment formats that are not supported.
    }
  };

  const getEmailAttachments = async () => {
    try {
      const outlookItem = Office.context.mailbox.item;
      if (outlookItem) {
        const attachmentsToDownload = outlookItem.attachments.filter((x) => !x.isInline);
        Logger.debug('All attachments for download', attachmentsToDownload);
        if (attachmentsToDownload.length > 0) {
          setAttachmentCount(attachmentsToDownload.length);
          for (let i = 0; i < attachmentsToDownload.length; i++) {
            const attachment = attachmentsToDownload[i];
            try {
              outlookItem.getAttachmentContentAsync(attachment.id, (r) => {
                handleFinalAttachmentsCallback(r, attachment.name);
              });
            } catch (err) {
              Logger.debug(`Error while getting attachment for ${attachment.name}`, err);
              setIsAttachmentError(true);
              setUploadAttachments(false);
              break;
            }
          }
        }
      }
    } catch (err) {
      appContext.setError(err);
      setIsAttachmentError(true);
      setUploadAttachments(false);
    }
  };

  const uploadEmailAttachments = async () => {
    try {
      // get attachments from email
      setUploadAttachments(true);
      await getEmailAttachments();
    } catch (err) {
      appContext.setError(err);
      setUploadAttachments(false);
    }
    // Next will be happen in react state based on mailAttachments
  };

  const saveToDrive = async () => {
    try {
      //upload file to drive
      const graphInterface = await appContext.getGraphInterface(graphSharepointLibraryRequest.scopes);
      const lists = appContext.globalDataCache.lists.getSystem(ResourceListSystemType.EMailAttachments);
      const list = lists && lists.length > 0 ? lists[0] : undefined;

      if (list && list.listId > 0 && isAttachmentChecked && isAttachmentEnable) {
        mailAttachments.forEach(async (mailAtt) => {
          const attNewName = `${task.taskId}-${mailAtt.name}`;
          if (mailAtt.blobData && list.spDriveId && list.spDriveItemId) {
            const fileToUpload = new File([mailAtt.blobData], attNewName);
            try {
              const newDriveItem = await graphUploadFileToFolder(
                graphInterface.client,
                fileToUpload,
                list.spDriveId,
                list.spDriveItemId,
                attNewName
              );

              if (newDriveItem) {
                //now create a new library item and link it to the new task
                const link = new ResourceLink();
                link.linkName = attNewName;
                link.linkURL = newDriveItem.webUrl ?? '';
                link.list = list;
                link.listId = list.listId;
                link.driveItemId = newDriveItem.id;

                const accessToken = await appContext.getAccessToken(apiRequest.scopes);
                const newLink = await apiCreateLink(link, accessToken, appContext.globalDataCache);
                await apiAddLinkToTask(newLink.linkId, task.taskId, false, undefined, accessToken);

                SetUploadCount((currCount) => currCount + 1);
              }
            } catch (err) {
              Logger.debug(`Error while uploading attachment ${mailAtt.name}`, err);
              setIsAttachmentError(true);
              setUploadAttachments(false);
            }
          }
        });
      }
    } catch (err) {
      Logger.debug(`Error while uploading attachments`, err);
      setIsAttachmentError(true);
      setUploadAttachments(false);
    }
  };

  React.useEffect(() => {
    //check if uploading attachment is enabled
    if (!isAttachmentChecked) return;

    //In case upload done successfully
    if (isUploadDone && !isAttachmentError) {
      setIsLoading(false);
      setUploadAttachments(false);
      showNotification(t('outlook:Task.Success'), closeContainer);
    } else if (isAttachmentError) {
      //error while uploading attachments
      setIsLoading(false);
      setUploadAttachments(false);
      setIsActionPending(false);
      const taskLink = `${globalAppPortalUrl}/tasks/${task.taskId}`;
      setTaskUrl(taskLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadDone, isUploadDone, isAttachmentChecked, isAttachmentError]);

  React.useEffect(() => {
    if (attachmentCount !== 0 && mailAttachments.length !== 0) {
      const IsAttachmentsDownloaded = attachmentCount === mailAttachments.length;
      if (task.taskId !== -1 && IsAttachmentsDownloaded) {
        //when IsAttachmentsDownloaded true mean download complete
        SetIsDownloadDone(true);
        const upload = async () => {
          await saveToDrive();
        };
        upload();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailAttachments]);

  React.useEffect(() => {
    if (uploadCount !== 0 && mailAttachments.length !== 0 && uploadCount === mailAttachments.length) {
      // when we made upload successfull we set flag to true
      SetIsUploadDone(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCount]);

  const getOutlookData = async () => {
    try {
      // Get a reference to the current message
      const outlookItem = Office.context.mailbox.item;
      if (outlookItem) {
        setTaskName(outlookItem.subject ? outlookItem.subject : t('translation:Task.Name.Default'));
        const attachmentsToDownload = outlookItem.attachments.filter((x) => !x.isInline);

        if (attachmentsToDownload.length > 0) {
          //In case we don't have any attachment with email
          setIsAttachmentEnable(false);
        }

        // Get the current body of the message or appointment.
        outlookItem.body.getAsync(Office.CoercionType.Html, (bodyResult) => {
          if (bodyResult.status === Office.AsyncResultStatus.Succeeded) {
            const textFromHtml = htmlToText(bodyResult.value);
            setEmailBodyDesc(truncate(textFromHtml, 4000));
          }
        });
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const addNewTask = () => {
    const newTask = new Task();

    newTask.taskType = TaskTypes.Normal;
    newTask.taskStates = appContext.globalDataCache.taskStates.items;
    newTask.taskStateId = newTask.taskStates[0].taskStateId;
    newTask.name = taskName;
    newTask.description = emailBodyDesc;
    newTask.userId = currentUserId;
    newTask.user = appContext.user;
    newTask.ownerId = currentUserId;
    newTask.owner = appContext.user;

    setSelectedTask(newTask);
  };

  const showNotification = (msg: string, action?: () => void, isError: boolean = false) => {
    if (isError) {
      appContext.setError(msg);
    } else {
      setIsActionPending(true);
      toast(msg, { onClose: action });
    }
  };

  //
  // Tag functions
  //

  const loadTags = async (taskToLoad: Task) => {
    if (taskToLoad.taskId === -1) return;
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tags: Tag[] = await apiGetTagsForTask(taskToLoad.taskId, accessToken);
      setTaskTags(tags);

      if (tags && tags.length > 0) {
        const newTask = taskToLoad.clone();
        newTask.tagIds = tags.map((t) => t.tagId);
        setTask(newTask);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const saveTags = async (task: Task): Promise<Task> => {
    try {
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks: Promise<void>[] = [];

      let newTags = task.tagIds ? task.tagIds.slice(0) : [];

      //first create all new tags and save the newly created Id into the array
      for (let i = 0; i < taskTags.length; i++) {
        const rt = taskTags[i];
        const existingTag = newTags.find((t) => t === rt.tagId);
        if (existingTag === undefined) {
          if (rt.tagId < 0) {
            const createdTag = await createTag(task, rt, accessToken);
            if (createdTag) {
              newTags.push(createdTag.tagId);
            }
          }
        }
      }

      //create parallel tasks for adding/removing existing tags from/to the task
      taskTags.forEach((rt) => {
        const existingTag = newTags.find((dbRt) => dbRt === rt.tagId);
        if (existingTag === undefined) {
          newTags.push(rt.tagId);
          tasks.push(addTagToTask(task, rt, accessToken));
        }
      });

      newTags.forEach((rt) => {
        const existingTag = taskTags.find((dbRt) => dbRt.tagId === rt);
        if (existingTag === undefined) {
          newTags = newTags.filter((_tag) => _tag !== rt);
          tasks.push(removeTagFromTask(task, rt, accessToken));
        }
      });

      await Promise.allSettled(tasks);

      //save the modified tags to the main task model
      const newTask = task.clone();
      newTask.tagIds = newTags;

      return newTask;
    } catch (err) {
      appContext.setError(err);

      return task;
    }
  };

  const addTagToTaskState = async (item: Tag) => {
    const newTaskTags = taskTags.slice(0);
    newTaskTags.push(item);
    setTaskTags(newTaskTags);
    const newTask = task.clone();
    newTask.tagIds = newTaskTags.map((t) => t.tagId);
    setTask(newTask);
  };

  const removeTagFromTaskState = async (item: Tag) => {
    const newTaskTags = taskTags.slice(0).filter((_tag) => _tag.tagId !== item.tagId);
    setTaskTags(newTaskTags);
    const newTask = task.clone();
    newTask.tagIds = newTaskTags.map((t) => t.tagId);
    setTask(newTask);
  };

  const createTagState = async (item: Tag) => {
    const newTaskTags = taskTags.slice(0);
    newTaskTags.push(item);
    setTaskTags(newTaskTags);
    const newTask = task.clone();
    newTask.tagIds = newTaskTags.map((t) => t.tagId);
    setTask(newTask);
  };

  const addTagToTask = async (task: Task, item: Tag, accessToken: string) => {
    try {
      await apiAddTagToTask(item, task.taskId, accessToken);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const removeTagFromTask = async (task: Task, tagId: number, accessToken: string) => {
    try {
      await apiRemoveTagFromTask(tagId, task.taskId, accessToken);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const createTag = async (task: Task, item: Tag, accessToken: string): Promise<Tag | undefined> => {
    try {
      const newTag = await apiCreateTag(item, accessToken);
      item.tagId = newTag.tagId;
      appContext.globalDataCache.tags.add(newTag);
      await apiAddTagToTask(newTag, task.taskId, accessToken);

      return newTag;
    } catch (err) {
      appContext.setError(err);

      return undefined;
    }
  };

  //
  // Templates
  //
  const getTemplates = (): IContextualMenuProps => {
    //lazy load templates now from api
    const output: IContextualMenuProps = {
      items: [],
      shouldFocusOnMount: true,
      directionalHint: DirectionalHint.bottomLeftEdge,
      directionalHintFixed: true,
      calloutProps: {
        calloutMaxHeight: 200,
      },
    };

    if (templateTasks && templateTasks.length > 0) {
      output.items = templateTasks.map((_itm: Task) => {
        return {
          key: `${_itm.taskId}`,
          text: `${_itm.name}`,
          iconProps: _itm.taskType === TaskTypes.Template ? taskIcon : eventIcon,
          onClick: () => {
            copyFromTemplate(_itm);
          },
        };
      });
    } else {
      output.items.push({
        key: 'no-items',
        onRenderContent: () => {
          if (templateTaskLoading) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Spinner />
                <Text>{t('outlook:Task.TemplatesLoading')}</Text>
              </Stack>
            );
          } else {
            return <Text styles={{ root: { padding: 5 } }}>{t('outlook:Task.NoTemplateItems')}</Text>;
          }
        },
      });
    }

    return output;
  };

  const loadTemplates = async () => {
    try {
      setTemplateTaskLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let _templateTasks = await apiGetActiveTaskTemplates(accessToken, appContext.globalDataCache);
      _templateTasks = _templateTasks.filter((t) => !t.systemTaskType && !t.isCompleted());
      _templateTasks.sort((a, b) => sortOnString(a.name, b.name));

      setTemplateTasks(_templateTasks);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setTemplateTaskLoading(false);
    }
  };

  const onCreateNewTaskTemplate = async () => {
    //not implemented
  };

  const copyFromTemplate = async (template: Task) => {
    const output = task.applyTemplate(template, appContext);

    //copy document links. Load them from the template
    let accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const links = await apiGetLinksForTask(template.taskId, false, accessToken, appContext.globalDataCache);

    const newLinks: ResourceLink[] = [];

    for (const link of links) {
      newLinks.push(link);
    }

    setOptionalLinks(newLinks);

    //set new tags
    const newTaskTags = appContext.globalDataCache.tags.getItemsForId(output.tagIds);
    setTaskTags(newTaskTags);

    //update the task and switch to the new task type: template
    setTask(output);
  };

  //
  // Task functions
  //
  const loadRescheduleRange = async () => {
    try {
      setIsActionPending(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const range = await apiGetTaskRescheduleRange(task.taskId, accessToken);
      setRescheduleDateRange(range);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
    }
  };

  const closeContainer = () => {
    Office.context.ui.closeContainer();
  };

  const addOutlookTask = async (schedule: boolean = false) => {
    try {
      setIsLoading(true);
      setIsActionPending(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      let newTask = await apiAddTask(task, schedule, accessToken, appContext.globalDataCache);
      newTask.taskStates = task.taskStates;
      newTask = await saveTags(newTask);
      setTask(newTask);

      // upload attachment with Task id append with name after task saved
      // in case error share task link to user to upload it mannually
      if (isAttachmentChecked) {
        // in case we have attachments to upload
        await uploadEmailAttachments();
      } else {
        showNotification(t('outlook:Task.Success'), closeContainer);
      }
    } catch (err) {
      //set action pending to false on error, otherwise keep action buttons disabled until the container closes
      setIsActionPending(false);
      if (schedule) {
        const outlookError = getOutlookErrorMsg(err as AppError, t as unknown as TFunction<string[]>);
        if (outlookError) {
          showNotification(t('outlook:Task.Error', { error: outlookError }), undefined, true);
        }
      }
      else {
        showNotification(t('outlook:Task.Error'), undefined, true);
      }

      return task;
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async (schedule: boolean) => {
    if (task.taskId === -1) {
      await addOutlookTask();
    }
  };

  const onRemove = async () => {
    try {
      if (task.taskId !== -1) {
        setIsLoading(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        await apiRemoveTask(task, accessToken);

        //set the original task, otherwise, when the same task is opened again, the cancelled changes are still visible
        setTask(selectedTask);
        setTaskTags([]);
        setOptionalLinks([]);
        setRescheduleDateRange(undefined);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    //set the original task, otherwise, when the same task is opened again, the cancelled changes are stil visible
    setTask(selectedTask);
    setTaskTags([]);
    setOptionalLinks([]);
    setRescheduleDateRange(undefined);
    // close the pane once completed
    Office.context.ui.closeContainer();
  };

  const createInOutlook = async (): Promise<void> => {
    try {
      await onSave(true);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const removeFromOutlook = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiRemoveTaskEvent(task, accessToken);
      task.eventId = undefined;
      setTask(task);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompletionDate = async (completionDate: Date) => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const updateTask = task.clone();
      updateTask.completed = completionDate;
      await apiUpdateTaskCompleted(updateTask, accessToken);
      setTask(updateTask);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (updatedTask: Task): Promise<void> => {
    if (updatedTask.taskId !== -1 && updatedTask.taskId !== task.taskId) {
      setTaskTags([]);
      await loadTags(updatedTask);
    }
    setRescheduleDateRange(undefined);
    setTask(updatedTask);
  };

  const onSaveAsNewTask = async () => {
    const newBaseTask = task.clone();
    newBaseTask.taskId = -1; //new tasks have task Id set to -1
    newBaseTask.eventId = undefined; //new tasks are not created in Outlook yet

    if (newBaseTask.taskType === TaskTypes.Normal) {
      //new task must be loose from its parent: in other words: cannot create an instance of the same parent
      //this is because normal tasks that are an instance or part of a series that have a distinct pattern. Any exceptions would break that
      newBaseTask.taskMasterId = undefined;
    }

    newBaseTask.name = t('task:CopyOf') + newBaseTask.name;

    //the new startdate is the current date
    //apply the start time from the task being copied
    //recalculate end-time and deadline based on defaults
    const sd = newBaseTask.startDateTime;
    const cd = new Date();
    const duration = getDateTimeDiffMinute(newBaseTask.endDateTime, sd);
    newBaseTask.startDateTime = new Date(
      cd.getFullYear(),
      cd.getMonth(),
      cd.getDate(),
      sd.getHours(),
      sd.getMinutes(),
      0
    );
    newBaseTask.endDateTime = addDateTimeMinutes(newBaseTask.startDateTime, duration);
    newBaseTask.deadline = addDateTimeDays(newBaseTask.endDateTime, 14);
    if (newBaseTask.recurringPattern) {
      newBaseTask.recurringPattern.startDate = newBaseTask.startDateTime;
    }

    //set state to 'first' state when the task being copied was completed
    newBaseTask.completed = undefined;
    if (newBaseTask.taskStateId === newBaseTask.getCompletedState()) {
      newBaseTask.taskStateId = newBaseTask.getFirstState();
    }

    //copy document links. Load them from the task being copied
    let accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const links = await apiGetLinksForTask(task.taskId, false, accessToken, appContext.globalDataCache);

    const newLinks: ResourceLink[] = [];

    for (const link of links) {
      newLinks.push(link);
    }

    setOptionalLinks(newLinks);

    //update the task in state
    onUpdate(newBaseTask);
  };

  const navigateToEventURL = async (task: Task) => {
    try {
      setIsActionPending(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const webUrl = await apiGetWebLinkForEvent(task, accessToken);

      if (webUrl) {
        navigateToExternalUrl(webUrl, '', '');
      } else {
        showNotification(t('task:Outlook.OpenInOutlookError'));
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
    }
  };

  //
  // Main render
  //
  switch (task.taskType) {
    case TaskTypes.Normal:
    case TaskTypes.Monitoring:
      return (
        <OutlookNormalTask
          isAttachmentEnable={isAttachmentEnable}
          logout={props.logout}
          isAttachmentError={isAttachmentError}
          taskUrl={taskUrl}
          onChangeIsAttachment={setIsAttachmentChecked}
          onSaveAsNewTask={onSaveAsNewTask}
          onChangeCompletionDate={updateCompletionDate}
          task={task}
          orgTask={orgTask}
          onSave={onSave}
          onRemove={onRemove}
          onCancel={onCancel}
          onCreateNewTaskTemplate={onCreateNewTaskTemplate}
          onUpdateTask={onUpdate}
          onUpdateOrgTask={setOrgTask}
          optionalLinks={optionalLinks}
          setOptionalLinks={setOptionalLinks}
          copyFromTemplate={copyFromTemplate}
          templateTasks={templateTasks}
          templateTaskLoading={templateTaskLoading}
          getTemplates={getTemplates()}
          loadTemplates={loadTemplates}
          isActionPending={isActionPending || uploadAttachments}
          isLoading={isLoading || uploadAttachments}
          setIsActionPending={setIsActionPending}
          createInOutlook={createInOutlook}
          removeFromOutlook={removeFromOutlook}
          loadRescheduleRange={loadRescheduleRange}
          rescheduleDateRange={rescheduleDateRange}
          tags={taskTags}
          addTagToTaskState={addTagToTaskState}
          removeTagFromTaskState={removeTagFromTaskState}
          createTagState={createTagState}
          navigateToEventURL={navigateToEventURL}
        ></OutlookNormalTask>
      );
    case TaskTypes.Event:
      return (
        <OutlookEventTask
          isAttachmentEnable={isAttachmentEnable}
          logout={props.logout}
          isAttachmentError={isAttachmentError}
          taskUrl={taskUrl}
          onChangeIsAttachment={setIsAttachmentChecked}
          onSaveAsNewTask={onSaveAsNewTask}
          onChangeCompletionDate={updateCompletionDate}
          task={task}
          orgTask={orgTask}
          onSave={onSave}
          onRemove={onRemove}
          onCancel={onCancel}
          onCreateNewTaskTemplate={onCreateNewTaskTemplate}
          onUpdateTask={onUpdate}
          onUpdateOrgTask={setOrgTask}
          optionalLinks={optionalLinks}
          setOptionalLinks={setOptionalLinks}
          copyFromTemplate={copyFromTemplate}
          templateTasks={templateTasks}
          templateTaskLoading={templateTaskLoading}
          getTemplates={getTemplates()}
          loadTemplates={loadTemplates}
          isActionPending={isActionPending || uploadAttachments}
          isLoading={isLoading || uploadAttachments}
          setIsActionPending={setIsActionPending}
          createInOutlook={createInOutlook}
          removeFromOutlook={removeFromOutlook}
          loadRescheduleRange={loadRescheduleRange}
          rescheduleDateRange={rescheduleDateRange}
          tags={taskTags}
          addTagToTaskState={addTagToTaskState}
          removeTagFromTaskState={removeTagFromTaskState}
          createTagState={createTagState}
          navigateToEventURL={navigateToEventURL}
        ></OutlookEventTask>
      );
    default:
      throw new AppError('Unknown task type');
  }
};

export default OutlookSingleTask;
